import toast from "react-hot-toast";
import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import apolloLogger from "apollo-link-logger";
import { createUploadLink } from "apollo-upload-client";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

import { deleteTokens, getTokens, saveTokens } from "helpers/functions";
import { REFRESH_TOKEN_MUTATION } from "helpers/mutations";

// https://www.apollographql.com/docs/react/api/link/apollo-link-context/
const authMiddleware = setContext(() => {
  const tokens = getTokens();

  if (tokens) {
    const { exp: tokenExpiry } = jwt_decode(tokens.token);
    const { exp: refreshExpiry } = jwt_decode(tokens.refreshToken);

    const hasTokenExpired = dayjs().isAfter(dayjs.unix(tokenExpiry));
    const hasRefreshTokenExpired = dayjs().isAfter(dayjs.unix(refreshExpiry));

    if (hasRefreshTokenExpired) {
      deleteTokens();
      window.location.reload();
      return;
    }

    if (!hasTokenExpired) {
      return {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "X-Agency-User-Token": tokens.token,
        },
      };
    }

    if (hasTokenExpired) {
      const temporaryClient = new ApolloClient({
        link: from([apolloLogger, uploadLink]),
        cache: new InMemoryCache(),
      });

      return temporaryClient
        .mutate({
          mutation: REFRESH_TOKEN_MUTATION,
          variables: { refreshToken: tokens.refreshToken },
        })
        .then((res) => {
          const doesSessionExist = JSON.parse(
            sessionStorage.getItem(process.env.REACT_APP_TOKENS)
          );

          const saveTokensResult = doesSessionExist // eslint-disable-line
            ? saveTokens(res.data.agencyUserRefreshToken, "session")
            : saveTokens(res.data.agencyUserRefreshToken, "local");

          return {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "X-Agency-User-Token": res.data.agencyUserRefreshToken.token,
            },
          };
        });
    }
  }
});

const toastOptions = {
  duration: 4000,
  position: "top-right",
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      toast.error(graphQLErrors[0].message, toastOptions);
    }

    if (networkError) {
      toast.error(networkError, toastOptions);
    }
    return forward(operation);
  }
);

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
});
const middleWares = [authMiddleware, retryLink, errorLink, uploadLink];

export const client = new ApolloClient({
  link:
    process.env.NODE_ENV === "production"
      ? from([apolloLogger, ...middleWares])
      : from([apolloLogger, ...middleWares]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
