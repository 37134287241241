// @ts-nocheck
import { useEffect, useState } from "react";

import {
  DefaultButton,
  FormGridContainer,
} from "@patchworkhealth/web-components";

import { ModalCustomFooter } from "components/styles";
import { createFormFunctions } from "helpers/functions";

import BlockRates from "./BlockRates";
import BlockWorkerCard from "./BlockWorkerCard";

const BlockViewBooking = ({
  block,
  setShowWorker,
  handleCloseAction,
}: BlockModalBookingProps) => {
  const [inputs, setInputs] = useState(emptyInputs);
  const { handleFilter } = createFormFunctions(setInputs);

  useEffect(() => {
    if (block.agencyWorkerRates) {
      let rates = block.agencyWorkerRates.map((cc) => ({
        ...cc,
        agencyMarginDiff: +(
          cc.agencyMargin - cc.initialRates.agencyMargin
        ).toFixed(2),
        workerPayeRateDiff: +(
          cc.workerPayeRate - cc.initialRates.workerPayeRate
        ).toFixed(2),
        ltdUmbrellaRateDiff: +(
          cc.ltdUmbrellaRate - cc.initialRates.ltdUmbrellaRate
        ).toFixed(2),
        comparatorRateDiff: +(
          cc.comparatorRate - cc.initialRates.comparatorRate
        ).toFixed(2),
        workerBaseRateDiff: +(
          cc.workerBaseRate - cc.initialRates.workerBaseRate
        ).toFixed(2),
      }));

      setInputs({
        ...inputs,
        rates,
        viewProposal: {
          agencyWorker: {
            paymentType: block.bookedBy.paymentType,
            employmentType: block.bookedBy.employmentType,
          },
        },
      });
    }
  }, [block]);

  return (
    <FormGridContainer>
      <BlockWorkerCard
        canUserEdit={false}
        setShowWorker={setShowWorker}
        worker={block.bookedBy}
      />

      {inputs.rates.length > 0 && (
        <BlockRates handleFilter={handleFilter} inputs={inputs} />
      )}

      <ModalCustomFooter>
        <div />
        <DefaultButton action={handleCloseAction} text="Close" type="white" />
      </ModalCustomFooter>
    </FormGridContainer>
  );
};

export default BlockViewBooking;

const emptyInputs = {
  rates: [],
  rateTab: 1,
  showPopup: false,
  showMath: false,
  viewProposal: null,
  totalComparitor: 0,
  totalMargin: 0,
};

type BlockModalBookingProps = {
  block: any;
  setShowWorker: Function;
  handleCloseAction: Function;
};
