import dayjs from "dayjs";
import styled from "styled-components";

import {
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormToggleButtons,
  TableContainer,
} from "@patchworkhealth/web-components";

import { ShiftModalTitle } from "../../AgencyShifts/ShiftModalComponents/ShiftModalComponents";

const ShiftModalEmployment = ({
  handleToggleButtons,
  handleInputChange,
  error,
  inputs,
}) => {
  return (
    <FormGridContainer>
      <ShiftModalTitle>Employment Details</ShiftModalTitle>
      {inputs.employmentType === 1 && (
        <FormGrid columns={2} style={{ marginBottom: 22 }}>
          <FormGroup>
            <FormLabel>Payroll ID</FormLabel>
            <FormInput
              defaultValue={inputs.payrollId}
              name="payrollId"
              onChange={handleInputChange}
              placeholder="eg. 123456"
              type="text"
            />
          </FormGroup>
        </FormGrid>
      )}
      <FormGrid columns={2} style={{ marginBottom: 22 }}>
        <FormGroupDisable disable={true}>
          <FormLabel>
            Employment Type<span>*</span>
          </FormLabel>
          <FormToggleButtons
            action={handleToggleButtons}
            disabledIndices={[0, 1]}
            input={inputs.employmentType}
            name="employmentType"
            text={["DE", "Non-DE"]}
          />
          {error?.employmentType && (
            <FormErrorLabel>{error.employmentType}</FormErrorLabel>
          )}
        </FormGroupDisable>
      </FormGrid>
      <FormGrid columns={1} style={{ marginBottom: 22 }}>
        {/* <FormGroup gridColumn="1/5">
          <FormLabel>
            Payment Type<span>*</span>
          </FormLabel>
          <FormToggleButtons
            action={handleToggleButtons}
            disabledIndices={inputs.employmentType === 0 ? [0, 1, 2] : []}
            input={inputs.paymentType}
            name="paymentType"
            text={
              inputs.employmentType === 0
                ? ["PAYE", "", "LTD"]
                : ["PAYE", "Umbrella Company", "LTD"]
            }
          />
          {console.log(inputs)}
          {error?.paymentType && (
            <FormErrorLabel>{error.paymentType}</FormErrorLabel>
          )}
        </FormGroup> */}
      </FormGrid>
      {inputs.employmentType === 1 &&
        (inputs.paymentType === 1 || inputs.paymentType === 2) && (
          <FormGridContainer>
            <FormGrid columns={1} style={{ marginBottom: 22 }}>
              <FormGroup>
                <FormLabel>
                  Company Name<span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyName"
                  defaultValue={inputs.companyName}
                  name="companyName"
                  onChange={handleInputChange}
                  placeholder="Company Name..."
                  type="text"
                />
                {error?.companyName && (
                  <FormErrorLabel>{error.companyName}</FormErrorLabel>
                )}
              </FormGroup>
            </FormGrid>
          </FormGridContainer>
        )}
      {/* DE & LTD only */}
      {inputs.employmentType === 0 && inputs.paymentType === 2 && (
        <FormGridContainer>
          <FormGrid columns={2} style={{ marginBottom: 22 }}>
            <FormGroup>
              <FormLabel>
                Company Name<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyName"
                defaultValue={inputs.companyName}
                name="companyName"
                onChange={handleInputChange}
                placeholder="Company Name..."
                type="text"
              />
              {error?.companyName && (
                <FormErrorLabel>{error.companyName}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Company Number<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyNumber"
                defaultValue={inputs.companyNumber}
                name="companyNumber"
                onChange={handleInputChange}
                placeholder="Company Number..."
                type="text"
              />
              {error?.companyNumber && (
                <FormErrorLabel>{error.companyNumber}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Address Line 1<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressLine1"
                defaultValue={inputs.companyAddressLine1}
                name="companyAddressLine1"
                onChange={handleInputChange}
                placeholder="Company Address Line 1..."
                type="text"
              />
              {error?.companyAddressLine1 && (
                <FormErrorLabel>{error.companyAddressLine1}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>Address Line 2</FormLabel>
              <FormInput
                data-cy="companyAddressLine2"
                defaultValue={inputs.companyAddressLine2}
                name="companyAddressLine2"
                onChange={handleInputChange}
                placeholder="Company Address Line 2..."
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Town/ City<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressTown"
                defaultValue={inputs.companyAddressTown}
                name="companyAddressTown"
                onChange={handleInputChange}
                placeholder="Company City/ Town..."
                type="text"
              />
              {error?.companyAddressTown && (
                <FormErrorLabel>{error.companyAddressTown}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>
                Postcode<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressPostcode"
                defaultValue={inputs.companyAddressPostcode}
                name="companyAddressPostcode"
                onChange={handleInputChange}
                placeholder="Company Postcode..."
                type="text"
              />
              {error?.companyAddressPostcode && (
                <FormErrorLabel>{error.companyAddressPostcode}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>
                VAT Required?<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="isVatRegistered"
                defaultValue={inputs.isVatRegistered}
                name="isVatRegistered"
                onChange={handleInputChange}
                placeholder="Company VAT Registered..."
                type="text"
              />
              {error?.isVatRegistered && (
                <FormErrorLabel>{error.isVatRegistered}</FormErrorLabel>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>
                VAT Number<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyVatNumber"
                defaultValue={inputs.companyVatNumber}
                name="companyVatNumber"
                onChange={handleInputChange}
                placeholder="VAT Number..."
                type="text"
              />
              {error?.companyVatNumber && (
                <FormErrorLabel>{error.companyVatNumber}</FormErrorLabel>
              )}
            </FormGroup>
          </FormGrid>
        </FormGridContainer>
      )}
      {inputs.employmentType === 0 && inputs.esrNumbers && (
        <>
          <FormLabel>Employment History</FormLabel>
          <TableContainer>
            <thead>
              <tr>
                <th>Org Name</th>
                <th>Latest ESR / Payroll</th>
                <th>Effective From</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody data-testid="workers">
              {inputs.esrNumbers &&
                inputs.esrNumbers.map((org) => (
                  <tr>
                    <td style={{ minWidth: 140 }}>
                      <strong>{org.organisation.name}</strong>
                    </td>
                    <td>{org.esrNumber}</td>
                    <td>{dayjs(org.validFrom).format("DD/MM/YYYY")}</td>
                    <td>{dayjs(org.validTo).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
            </tbody>
          </TableContainer>
        </>
      )}
    </FormGridContainer>
  );
};

export default ShiftModalEmployment;

export const FormGroupDisable = styled(FormGroup)<{ disable: boolean }>`
  * {
    pointer-events: ${({ disable }) => disable && "none !important"};
  }
`;
