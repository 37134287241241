import Pagination from "react-bootstrap/Pagination";

import {
  TableContainer,
  TableEditButton,
} from "@patchworkhealth/web-components";

import { GetAgencyUsersQuery } from "./__generated__/AgencyUsersHelpers.generated";

type AgencyUsersTableProps = {
  agencyUsers: GetAgencyUsersQuery;
  loading: Boolean;
  setShowAgencyUser: Function;
  setPage: Function;
};

const AgencyUsersTable = ({
  agencyUsers,
  loading,
  setShowAgencyUser,
  setPage,
}: AgencyUsersTableProps) => {
  return (
    <>
      <TableContainer loading={loading}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Linked Orgs</th>
            <th></th>
          </tr>
        </thead>
        <tbody data-testid="users">
          {agencyUsers?.vmsAgencyUsers.list.map((agencyUser) => (
            <tr key={agencyUser.id}>
              <th data-testid="users__name">
                <button
                  onClick={() => setShowAgencyUser(agencyUser)}
                  style={{ maxWidth: 400 }}
                >
                  <strong>
                    {agencyUser.firstName} {agencyUser.lastName}
                  </strong>
                </button>
              </th>
              <td data-testid="users__email">{agencyUser.email}</td>
              <td data-testid="users__email">
                {agencyUser?.agencyUserOrganisations?.length}
              </td>
              <td style={{ minWidth: 160 }}>
                <TableEditButton onClick={() => setShowAgencyUser(agencyUser)}>
                  View
                </TableEditButton>
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>

      <Pagy
        pagination={agencyUsers.vmsAgencyUsers.pagination}
        setPage={setPage}
      />
    </>
  );
};

export default AgencyUsersTable;

function Pagy({ pagination, setPage }) {
  const items = [];
  for (let number = 1; number <= pagination.last; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === pagination.page}
        onClick={() => setPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination
      style={{
        marginTop: 30,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pagination.Prev
        disabled={!pagination.prev}
        onClick={() => setPage(pagination.prev)}
      >
        ‹&nbsp;Prev
      </Pagination.Prev>
      {items}
      <Pagination.Next
        disabled={!pagination.next}
        onClick={() => setPage(pagination.next)}
      >
        Next&nbsp;›
      </Pagination.Next>
    </Pagination>
  );
}
