import styled from "styled-components";

import {
  FormBreak,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormToggleButtons,
} from "@patchworkhealth/web-components";

import { ShiftModalTitle } from "../../AgencyShifts/ShiftModalComponents/ShiftModalComponents";

const ShiftModalProfile = ({
  inputs,
  invitation,
  handleInputChange,
  handleToggleButtons,
  error,
}) => {
  return (
    <div>
      <FormGridContainer>
        {invitation && (
          <InvitationAlert>
            Please double check the information is correct before sending
            activation email.
          </InvitationAlert>
        )}

        <ShiftModalTitle>Personal Info</ShiftModalTitle>
        <FormGrid columns={2} style={{ marginBottom: 22 }}>
          <FormGroup>
            <FormLabel>
              First Name<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="firstName"
              defaultValue={inputs.firstName}
              name="firstName"
              onChange={handleInputChange}
              placeholder="Enter First Name..."
              type="text"
            />
            {error?.firstName && (
              <FormErrorLabel>{error.firstName}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Last Name<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="lastName"
              defaultValue={inputs.lastName}
              name="lastName"
              onChange={handleInputChange}
              placeholder="Enter Last Name..."
              type="text"
            />
            {error?.lastName && (
              <FormErrorLabel>{error.lastName}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Gender<span>*</span>
            </FormLabel>
            <FormToggleButtons
              action={handleToggleButtons}
              input={inputs.gender}
              name="gender"
              text={["Male", "Female"]}
            />
            {error?.gender && <FormErrorLabel>{error.gender}</FormErrorLabel>}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Date of Birth<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="dateOfBirth"
              defaultValue={inputs.dateOfBirth}
              name="dateOfBirth"
              onChange={handleInputChange}
              placeholder="DD/MM/YYYY"
              type="date"
            />
            {error?.dateOfBirth && (
              <FormErrorLabel>{error.dateOfBirth}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <ShiftModalTitle>Contact Information</ShiftModalTitle>
        <FormGrid columns={2} style={{ marginBottom: 30 }}>
          <FormGroup>
            <FormLabel>
              Email Address<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="email"
              defaultValue={inputs.email}
              disabled={false}
              name="email"
              onChange={handleInputChange}
              placeholder="Enter Email Address..."
              type="email"
            />
            {error?.email && <FormErrorLabel>{error.email}</FormErrorLabel>}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Phone Number<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="phoneNumber"
              defaultValue={inputs.phoneNumber}
              name="phoneNumber"
              onChange={handleInputChange}
              placeholder="Enter Phone Number..."
              type="text"
            />
            {error?.phoneNumber && (
              <FormErrorLabel>{error.phoneNumber}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Post Code<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="postCode"
              defaultValue={inputs.postcode}
              name="postcode"
              onChange={handleInputChange}
              placeholder="Enter Post Code..."
              type="text"
            />
            {error?.postcode && (
              <FormErrorLabel>{error.postcode}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
    </div>
  );
};

export default ShiftModalProfile;

const InvitationAlert = styled.div`
  background: #d1f2f7;
  border-left: 17px solid #17bfd6;
  border-radius: 8px;
  color: #002033;
  font-size: 13px;
  font-weight: 600;
  height: 38px;
  margin-bottom: 18px;
  padding: 9px 8px;
  width: 547px;
`;
