export const OrgIconPicker = () => (
  <svg
    fill="none"
    height="8"
    viewBox="0 0 12 8"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.627 0.910093C11.9525 1.23553 11.9525 1.76317 11.627 2.0886L6.62701 7.08861C6.30158 7.41404 5.77394 7.41404 5.4485 7.08861L0.448503 2.0886C0.123066 1.76317 0.123066 1.23553 0.448503 0.910093C0.77394 0.584656 1.30158 0.584656 1.62702 0.910093L6.03776 5.32084L10.4485 0.910093C10.7739 0.584656 11.3016 0.584656 11.627 0.910093Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);
