import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

const ShiftModalSidebar = ({ tab, setTab }) => {
  return (
    <ShiftModalSidebarContainer>
      <ShiftModalSidebarNav>
        <li>
          <ShiftModalSidebarNavOption
            active={tab === "profile"}
            data-cy="shiftsModalSidebar-profile"
            onClick={() => setTab("profile")}
          >
            Basic Profile
          </ShiftModalSidebarNavOption>
        </li>

        <li>
          <ShiftModalSidebarNavOption
            active={tab === "experience"}
            data-cy="shiftsModalSidebar-experience"
            onClick={() => setTab("experience")}
          >
            Experience
          </ShiftModalSidebarNavOption>
        </li>

        <li>
          <ShiftModalSidebarNavOption
            active={tab === "employment"}
            data-cy="shiftsModalSidebar-employment"
            onClick={() => setTab("employment")}
          >
            Employment
          </ShiftModalSidebarNavOption>
        </li>
      </ShiftModalSidebarNav>
    </ShiftModalSidebarContainer>
  );
};

export default ShiftModalSidebar;

const ShiftModalSidebarContainer = styled.div`
  background: #e4e7eb;
  border-bottom-left-radius: 16px;
  flex-shrink: 0;
  padding: 12px 8px;
  width: 185px;
`;

const ShiftModalSidebarNav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ShiftModalSidebarNavOption = styled.button<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) => active && GREY.three};
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: ${({ active }) => active && "600"};
  height: 32px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
`;
