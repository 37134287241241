import dayjs from "dayjs";
import styled from "styled-components";

import {
  FormBreak,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelectMulti,
} from "@patchworkhealth/web-components";

import { AgencyShiftFragment } from "../__generated__/AgencyShiftsHelpers.generated";
import { ShiftModalTitle } from "./ShiftModalComponents";

type ShiftModalDetailsProps = {
  shift?: AgencyShiftFragment;
  readonly?: boolean;
};

const ShiftModalDetails = ({ shift, readonly }: ShiftModalDetailsProps) => {
  return (
    <div>
      <FormGridContainer>
        <ShiftModalTitle style={{ position: "relative" }}>
          Date &amp; Title
          {shift.onCall && <OnCallBadge>On-Call Shift</OnCallBadge>}
        </ShiftModalTitle>
        <FormGrid columns={3} style={{ marginBottom: 16 }}>
          <FormGroup>
            <FormLabel>Start Date</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={dayjs(shift?.startTime).format("DD MMM YYYY")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>End Date</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={dayjs(shift?.endTime).format("DD MMM YYYY")}
            />
          </FormGroup>
        </FormGrid>
        <FormGrid columns={3} style={{ marginBottom: 16 }}>
          <FormGroup>
            <FormLabel>Start Time</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={dayjs(shift?.startTime).format("HH:mm")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>End Time</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={dayjs(shift?.endTime).format("HH:mm")}
            />
          </FormGroup>
        </FormGrid>
        <FormGrid columns={3}>
          {!shift.onCall && (
            <FormGroup>
              <FormLabel>Length of Break (Minutes)</FormLabel>
              <FormInput
                disabled={true}
                type="text"
                value={shift?.breakMinutes}
              />
            </FormGroup>
          )}
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <ShiftModalTitle>Shift Info</ShiftModalTitle>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>Site</FormLabel>
            <FormInput disabled={true} type="text" value={shift?.site?.name} />
          </FormGroup>
          <FormGroup>
            <FormLabel>Department</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={shift?.department?.name}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Reason</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={shift?.reasonForRequest}
            />
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <ShiftModalTitle>Clinical Info</ShiftModalTitle>
        <FormGrid columns={3} style={{ marginBottom: 16 }}>
          <FormGroup>
            <FormLabel>Staff Type</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={shift?.staffGroup?.title}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Grade</FormLabel>
            <FormInput
              disabled={true}
              type="text"
              value={shift?.grade?.title}
            />
          </FormGroup>

          {!readonly && (
            <RemoveButton>
              <FormGroup>
                <FormLabel>Skills</FormLabel>
                <FormSelectMulti
                  onChange={() => null}
                  options={shift?.skills.map((cc) => ({
                    label: cc.name,
                    value: cc.id,
                  }))}
                  placeholder="Skills"
                  value={shift?.skills.map((cc) => ({
                    label: cc.name,
                    value: cc.id,
                  }))}
                />
              </FormGroup>
            </RemoveButton>
          )}
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <ShiftModalTitle>Optional Info</ShiftModalTitle>
        <FormGrid columns={2}>
          <FormGroup>
            <FormLabel>Worker/Agency Notes</FormLabel>
            <FormInput
              as="textarea"
              disabled={true}
              rows={5}
              value={shift?.note}
            />
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
    </div>
  );
};

export default ShiftModalDetails;

const RemoveButton = styled.div`
  button {
    display: none !important;
  }
`;

const OnCallBadge = styled.div`
  background: #d1f5e3;
  border: 1px solid #31695975;
  border-radius: 4px;
  display: inline-block;
  margin-left: 10px;
  padding: 4px 22px;
`;
