import { useState } from "react";
import toast from "react-hot-toast";

import {
  DefaultButton,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormLabel,
  FormSelect,
} from "@patchworkhealth/web-components";

import { ModalCustomFooter } from "components/styles";
import { useReasonsForCancellationQuery } from "helpers/__generated__/queries.generated";
import { formatForReactSelect } from "helpers/functions";

import {
  AgencyShiftFragment,
  useVmsShiftCancelAgencyWorkerMutation,
} from "../__generated__/AgencyShiftsHelpers.generated";
import ShiftModalProposeWorker from "./ShiftModalProposeWorkers/ShiftModalProposeWorker";

type ShiftModalCancelBookingProps = {
  shift?: AgencyShiftFragment;
  setShowWorker: any;
  handleCloseAction: Function;
  refetchWorkers: Function;
};

const ShiftModalCancelBooking = ({
  shift,
  setShowWorker,
  handleCloseAction,
  refetchWorkers,
}: ShiftModalCancelBookingProps) => {
  const [reason, setReason] = useState(null);

  const { data: reasonsForCancellation } = useReasonsForCancellationQuery({
    variables: {
      organisationId: Number(shift?.organisation?.id),
    },
  });

  const [vmsShiftCancelAgencyWorker] = useVmsShiftCancelAgencyWorkerMutation();
  const vmsShiftCancelAgencyWorkerAction = async () => {
    const toastId = toast.loading("Cancelling booking...");
    if (reason === null) {
      toast.error("Please select a reason.", { id: toastId });
    } else {
      try {
        const { data: result } = await vmsShiftCancelAgencyWorker({
          variables: {
            shiftId: Number(shift.id),
            reason: reason.label,
          },
        });

        if (result?.vmsShiftCancelAgencyWorker?.errors?.length) {
          toast.error("An error occurred.", { id: toastId });
        } else {
          handleCloseAction();
          refetchWorkers();
          toast.success("Booking cancelled.", { id: toastId });
        }
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  return (
    <FormGridContainer>
      <FormGrid columns={3} style={{ marginBottom: 24 }}>
        <FormGroup>
          <ShiftModalProposeWorker
            agencyWorker={shift?.agencyBooking?.agencyWorker}
            setShowWorker={setShowWorker}
            shift={shift}
          />
        </FormGroup>
      </FormGrid>

      <FormGrid columns={3} style={{ marginBottom: 16 }}>
        <FormGroup>
          <FormLabel>Cancellation Reason</FormLabel>
          <FormSelect
            onChange={(event) => setReason(event)}
            options={formatForReactSelect(
              reasonsForCancellation?.reasonsForCancellation
            )}
            placeholder="Please select a cancellation reason"
          />
        </FormGroup>
      </FormGrid>
      <ModalCustomFooter>
        <div />

        <DefaultButton
          action={() => vmsShiftCancelAgencyWorkerAction()}
          color="red"
          text="Cancel Booking"
        />
      </ModalCustomFooter>
    </FormGridContainer>
  );
};

export default ShiftModalCancelBooking;
