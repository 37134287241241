// @ts-nocheck
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import {
  AgencyRegistrationFragment,
  useGetAgencyWorkersQuery,
} from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";
import AgencyWorkersModal from "components/AgencyWorkers/AgencyWorkersModal";
import AgencyWorkersTable from "components/AgencyWorkers/AgencyWorkersTable";
import TableHeader from "components/TableHeader/TableHeader";
import { createFormFunctions } from "helpers/functions";
import { usePagination } from "helpers/usePagination";
//
import { RootState } from "models/store";

const PendingWorkersPage = () => {
  const org = useSelector((s: RootState) => s.organisation);

  const [pagination, setPagination, resetPagination] = usePagination(50);
  const [agencyWorker, setAgencyWorker] =
    useState<AgencyRegistrationFragment>(null);
  const [show, setShow] = useState(false);

  const [invitation, setInvitation] = useState(false);

  const [trashed, setTrashed] = useState(false);

  const [inputs, setInputs] = useState({
    searchText: "",
  });
  const { handleFilter } = createFormFunctions(setInputs);

  const {
    data: agencyWorkers,
    refetch: refetchWorkers,
    loading,
  } = useGetAgencyWorkersQuery({
    fetchPolicy: "no-cache",
    variables: {
      ...pagination,
      confirmed: false,
      searchTerm: inputs.searchText,
      trashed: trashed,
    },
  });
  const pageInfo = agencyWorkers
    ? agencyWorkers.vmsAgencyRegistrations.pageInfo
    : "";

  // functions
  const setShowAgencyWorker = (data) => {
    if (org.length > 1) {
      toast.error("Select an Organisation from Toggle to continue");
      return;
    }

    setAgencyWorker(data ?? null);
    setShow(true);
  };

  const handleCloseAction = () => {
    setInvitation(false);
    setAgencyWorker(null);
    setShow(false);
  };

  useEffect(() => {
    if (agencyWorker) {
      agencyWorkers?.vmsAgencyRegistrations?.edges?.forEach((element) => {
        if (element.node.id === agencyWorker.id) {
          setShowAgencyWorker(element.node);
        }
      });
    }
  }, [agencyWorkers]);

  return (
    <>
      <TableHeader
        buttonText="Create Worker"
        dataCount={agencyWorkers?.vmsAgencyRegistrations?.totalCount}
        handleShow={setShowAgencyWorker}
        inputPlaceholder="Search Workers"
        resetPagination={resetPagination}
        setSearchText={(e) => handleFilter(e, "searchText")}
        setTrashed={setTrashed}
        title="Pending Workers"
        trashed={trashed}
      />

      <AgencyWorkersTable
        activeWorker={false}
        agencyWorkers={agencyWorkers}
        loading={loading}
        pageInfo={pageInfo}
        pagination={pagination}
        refetch={refetchWorkers}
        setPagination={setPagination}
        setShowAgencyWorker={setShowAgencyWorker}
        trashed={trashed}
        // setView={handleInvitation}
      />
      {show && (
        <AgencyWorkersModal
          agencyWorker={agencyWorker}
          handleCloseAction={handleCloseAction}
          invitation={invitation}
          refetchWorkers={refetchWorkers}
          show={show}
        />
      )}
    </>
  );
};

export default PendingWorkersPage;
