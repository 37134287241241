// @ts-nocheck
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


import { IdleTimer, IdleTimerContext } from "@patchworkhealth/web-components";

import {
  useCurrentAgencyUserQuery,
  useStaffGroupsQuery,
} from "helpers/__generated__/queries.generated";
import { deleteTokens, getTokens } from "helpers/functions";
import { RootState } from "models/store";

import PrivateRoutes from "./components/Routes/PrivateRoutes";
import PublicRoutes from "./components/Routes/PublicRoutes";

// Modal Component
const UpgradeBanner = ({ onClose }) => (
  <div
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    }}
  >
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
        textAlign: "center",
        maxWidth: "500px",
        width: "100%",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        &times;
      </button>
      <h2 style={{ marginBottom: 14 }}>Patchwork Agency Manager</h2>
      <p style={{ lineHeight: 1.4 }}>
        We are excited to announce an upgrade to the Patchwork Agency Manager
        portal on
        <span style={{ fontWeight: "bold" }}> Friday, June 28th.</span> If
        you have any questions or feedback, please do not hesitate to contact us
        at <span style={{ fontWeight: "bold" }}>help@patchwork.health</span>
      </p>

      <button
        onClick={onClose}
        style={{
          marginTop: "10px",
          padding: "10px 20px",
          backgroundColor: "#05405C",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Dismiss
      </button>
      {/* 1: "#B8C4C9", 2: "#8BA3AE", 3: "#5F8293", 4: "#326178", 5: "#05405C", 6:
      "#082939", */}
    </div>
  </div>
);

export default function App() {
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [showBanner, setShowBanner] = useState(
    !localStorage.getItem("bannerDismissed")
  );
  const store = useSelector((s: RootState) => s);

  console.log("store",store);
  

  const IdleTimerContextValue = useMemo(
    () => ({ hasTimedOut, setHasTimedOut }),
    [hasTimedOut]
  );

  const {
    organisations: { storeOrganisations },
    organisation: { storeOrganisation },
    staffGroups: { storeStaffGroups, deleteStaffGroups },
    user: { storeUser },
  } = useDispatch();


  const history = useHistory();
  const token = getTokens();

  const { data: currentAgencyUser } = useCurrentAgencyUserQuery({
   
    skip: !token || !!store.user,
    onCompleted: () => {
    

      storeUser(currentAgencyUser.currentAgencyUser);

      const filteredOrgs = [
        ...new Map(
          currentAgencyUser?.currentAgencyUser?.agencyUserPermissions.map(
            (item) => [item.organisationStaffGroup?.organisation.id, item]
          )
        ).values(),
      ];

      storeOrganisation(filteredOrgs);
      storeOrganisations(filteredOrgs);
    },
    onError: () => {
      deleteTokens();
      // window.location.reload();
    },
  });

  let variables = {
    ...(store.organisation?.length > 1 && {
      organisationIds: store.organisation.map(
        (cc) => cc.organisationStaffGroup.organisation.id
      ),
    }),

    ...(store.organisation?.length === 1 && {
      organisationId:
        store.organisation[0].organisationStaffGroup.organisation.id,
    }),
  };

  const { data: staffGroups } = useStaffGroupsQuery({
    variables,
    skip: !store.organisation,
    fetchPolicy: "no-cache",
    onCompleted: () => {
      deleteStaffGroups();
      storeStaffGroups(staffGroups?.organisationStaffGroups);
    },
  });

  const logout = () => {
   
    deleteTokens();
    storeUser(null);
    history.push("/");
  };

  const onTimeout = () => {
    logout();
    setHasTimedOut(true);
  };

  const handleDismissBanner = () => {
    localStorage.setItem("bannerDismissed", "true");
    setShowBanner(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("bannerDismissed")) {
      setShowBanner(true);
    }
  }, []);

  if (!token)
    return (
      <IdleTimerContext.Provider value={IdleTimerContextValue}>
        <PublicRoutes />
      </IdleTimerContext.Provider>
    );

  return store.user ? (
    <>
      {showBanner && <UpgradeBanner onClose={handleDismissBanner} />}
      <PrivateRoutes />
      <IdleTimer logout={logout} onTimeout={onTimeout} timeoutMs={3600000} />
    </>
  ) : null;
}
