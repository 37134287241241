// @ts-nocheck
import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { AgencyRegistrationFragment } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";

import { AgencyShiftFragment } from "../../__generated__/AgencyShiftsHelpers.generated";
import ShiftModalProposeWorker from "./ShiftModalProposeWorker";

type ShiftModalProposeWorkerSimpleProps = {
  proposedWorker: AgencyRegistrationFragment;
  shift: AgencyShiftFragment;
  setShowWorker: any;
};

const ShiftModalProposeWorkerSimple = ({
  proposedWorker,
  shift,
  setShowWorker,
}: ShiftModalProposeWorkerSimpleProps) => {
  return (
    <ShiftModalProposeWorkerRatesWorkerWrapper>
      <ShiftModalProposeWorker
        agencyWorker={proposedWorker}
        setShowWorker={setShowWorker}
        shift={shift}
      />
    </ShiftModalProposeWorkerRatesWorkerWrapper>
  );
};

export default ShiftModalProposeWorkerSimple;

const ShiftModalProposeWorkerRatesWorkerWrapper = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;

  h2 {
    color: ${GREY.six};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
`;
