// @ts-nocheck
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  CloseButton,
  DefaultButton,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@patchworkhealth/web-components";

//
import {
  createFormFunctions,
  FormatError,
  formatToIds,
} from "helpers/functions";

import {
  useVmsAgencyInvitationMutation,
  useVmsAgencyRegistrationRegisterAgencyWorkerMutation,
  useVmsAgencyRegistrationUpdateAgencyWorkerMutation,
} from "./__generated__/AgencyWorkersHelpers.generated";
import AgencyWorkersModalDetailsPage, {
  formatError,
} from "./AgencyWorkersModalDetailsPage";

type AgencyUsersModalProps = {
  agencyWorker: any;
  handleCloseAction: Function;
  show: boolean;
  refetchWorkers: Function;
  invitation?: boolean;
};

type EmptyInputsType = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: number; // null
  email: string;
  phoneNumber: string;
  postcode: string;
  id: any;
  // Worker Info
  dbsNumber: string;
  niNumber: string;
  staffGroup: {
    label: string;
    value: number;
    masterStaffGroupId: number;
  };
  regBodyNumber: string;
  grade: {
    label: string;
    value: number;
  };
  specialityIds: [];
  skillIds: [];
  // Employment Info
  employmentType: number; // null
  paymentType: number; // null
  compliancePackUrl: string;
  complianceExpirationDate: string;
  cvUrl: string;
  //company
  companyName: undefined | string;
  companyNumber: string;
  companyAddressLine1: string;
  companyAddressLine2: string;
  companyAddressTown: string;
  companyAddressPostcode: string;
  companyVatNumber: string;
  isVatRegistered: any;
  isSoleTrader: boolean;
};

export const emptyInputs: EmptyInputsType = {
  // Personal Info
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: 3, // null
  email: "",
  phoneNumber: "",
  postcode: "",
  id: null,
  // Worker Info
  dbsNumber: "",
  niNumber: "",
  staffGroup: {
    label: "",
    value: null,
    masterStaffGroupId: null,
  },
  regBodyNumber: "",
  grade: {
    label: "",
    value: null,
  },
  specialityIds: [],
  skillIds: [],
  // Employment Info
  employmentType: 5, // null
  paymentType: 5, // null
  compliancePackUrl: null,
  complianceExpirationDate: null,
  cvUrl: null,
  //company
  companyName: "",
  companyNumber: "",
  companyAddressLine1: "",
  companyAddressLine2: "",
  companyAddressTown: "",
  companyAddressPostcode: "",
  companyVatNumber: "",
  isVatRegistered: false,
  isSoleTrader: false,
};

const AgencyWorkersModal = ({
  agencyWorker,
  handleCloseAction,
  show,
  refetchWorkers,
  invitation,
}: AgencyUsersModalProps) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState(emptyInputs);

  console.log("inputs", inputs);

  console.log("err", error);

  const [invitationMutation] = useVmsAgencyInvitationMutation({
    refetchQueries: ["VmsAgencyWorkerProposals"],
  });

  const { handleFilter, handleInputChange } = createFormFunctions(
    setInputs,
    setError
  );

  const handleToggleButtons = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    enumForState: number
  ) => {
    // Update soleTrader to false if event.target.name === 'employmentType'

    // @ts-ignore
    if (event.target.name === "employmentType") {
      setError(null);
      setInputs((inputsParam) => ({
        ...inputsParam,
        [(event.target as HTMLButtonElement).name]: enumForState,
        isSoleTrader: false,
      }));

      return;
    }

    setError(null);
    setInputs((inputsParam) => ({
      ...inputsParam,
      [(event.target as HTMLButtonElement).name]: enumForState,
    }));
  };

  useEffect(() => {
    if (agencyWorker) {
      setInputs({
        id: agencyWorker?.id,
        firstName: agencyWorker?.user?.firstName
          ? agencyWorker.user.firstName
          : agencyWorker?.firstName,
        lastName: agencyWorker?.user?.lastName
          ? agencyWorker.user.lastName
          : agencyWorker?.lastName,
        dateOfBirth: agencyWorker.user?.dateOfBirth ?? "",
        gender:
          agencyWorker.user?.gender === "MALE"
            ? 0
            : agencyWorker.user?.gender === "FEMALE"
            ? 1
            : 3, // null
        email: agencyWorker.user?.email ?? "",
        phoneNumber: agencyWorker.user?.phoneNumber ?? "",
        postcode: agencyWorker.user?.postcode ?? "",
        // Worker Info
        dbsNumber: agencyWorker.user?.dbsNumber ?? "",
        niNumber: agencyWorker.user?.niNumber ?? "",
        staffGroup: {
          label: agencyWorker.staffGroup?.title ?? "",
          value: agencyWorker.staffGroup?.idValue ?? null,
          masterStaffGroupId: agencyWorker.staffGroup?.idValue ?? null,
        },
        regBodyNumber: agencyWorker.user?.regBodyNumber
          ? agencyWorker.user.regBodyNumber
          : agencyWorker.regBodyNumber
          ? agencyWorker.regBodyNumber
          : "",
        grade: {
          label: agencyWorker.grade?.title ?? "",
          value: agencyWorker.grade?.id ?? null,
        },
        specialityIds: [],
        skillIds: agencyWorker.skills.map((cc) => ({
          label: cc.name,
          value: cc.id,
        })),

        // Employment Info
        employmentType:
          agencyWorker.employmentType === "direct_engagement"
            ? 0
            : agencyWorker.employmentType === "non_direct_engagement"
            ? 1
            : 5, // null
        paymentType:
          agencyWorker.paymentType === "paye"
            ? 0
            : agencyWorker.paymentType === "ltd"
            ? 1
            : agencyWorker.paymentType === "umbrella_company" ||
              agencyWorker.paymentType === "soleTrader"
            ? 2
            : 5, // null
        companyName: agencyWorker.companyName ?? "",
        compliancePackUrl: agencyWorker.compliancePackUrl,
        complianceExpirationDate: agencyWorker.complianceExpirationDate,
        cvUrl: agencyWorker.cvUrl,
        companyAddressLine1: agencyWorker.companyAddressLine1 ?? "",
        companyAddressLine2: agencyWorker.companyAddressLine2 ?? "",
        companyAddressTown: agencyWorker.companyAddressTown ?? "",
        companyAddressPostcode: agencyWorker.companyAddressPostcode ?? "",
        companyNumber: agencyWorker.companyNumber ?? "",
        companyVatNumber: agencyWorker.companyVatNumber ?? "",
        isVatRegistered: agencyWorker.isVatRegistered === true ? 0 : 1,
        isSoleTrader: agencyWorker.isSoleTrader,
      });
    }
  }, [agencyWorker]);

  // TOOD - IF user id DirectEngagement, force paymentType to PAYE

  const validateRequiredInputs = () => {
    if (inputs.firstName === "") {
      setError({
        ...error,
        firstName: "Please input a First Name.",
      });
    } else if (inputs.isVatRegistered === 0 && inputs.companyVatNumber === "") {
      setError({
        ...error,
        companyVatNumber: "Please input a Company VAT Number.",
      });
    }

    //     companyAddressLine1
    // companyAddressLine2

    // companyAddressPostcode
    // companyAddressTown
    // companyName
    // companyNumber
    // complianceExpirationDate
    // compliancePackUrl

    // IF DE & LTD show company name
    // IF Non-DE && not PAYE

    // Company Validations ----------------
    else if (
      (inputs.isSoleTrader ||
        (inputs.employmentType === 0 && inputs.paymentType === 1) ||
        (inputs.employmentType === 1 && inputs.paymentType !== 0)) &&
      inputs.companyName === ""
    ) {
      setError({
        ...error,
        companyName: "Please enter company Name.",
      });
    }

    // Comapny end Validation ----------------
    else if (inputs.lastName === "") {
      setError({
        ...error,
        lastName: "Please input a Last Name.",
      });
    } else if (inputs.dateOfBirth === "") {
      setError({
        ...error,
        dateOfBirth: "Please input a Date of Birth.",
      });
    } else if (inputs.gender === 3) {
      setError({
        ...error,
        gender: "Please select a Gender.",
      });
    } else if (inputs.email === "") {
      setError({
        ...error,
        email: "Please input a Email.",
      });
    } else if (inputs.phoneNumber === "") {
      setError({
        ...error,
        phoneNumber: "Please input a Phone No.",
      });
    } else if (/^((\+44)|(0))?\d{10}$/i.test(inputs.phoneNumber) === false) {
      setError({
        ...error,
        phoneNumber: "Incorrect Phone No. format.",
      });
    } else if (inputs.postcode === "") {
      setError({
        ...error,
        postcode: "Please input a Postcode.",
      });
    } else if (inputs.dbsNumber === "") {
      setError({
        ...error,
        dbsNumber: "Please input a DBS Number.",
      });
    } else if (inputs.niNumber === "") {
      setError({
        ...error,
        niNumber: "Please input a National Insurance No.",
      });
    } else if (
      /^[A-Z]{2}\d{6}[A-Z]$/i.test(inputs.niNumber.replaceAll(/\s/g, "")) ===
      false
    ) {
      setError({
        ...error,
        niNumber: "Incorrect National Insurance No. format.",
      });
    } else if (inputs.staffGroup.value === null) {
      setError({
        ...error,
        staffGroup: "Please select a Staff Group.",
      });
    } else if (inputs.regBodyNumber === "") {
      setError({
        ...error,
        regBodyNumber: "Please input a Registration Body No.",
      });
    } else if (inputs.grade.value === null) {
      setError({
        ...error,
        grade: "Please select a Grade.",
      });
    } else if (inputs.employmentType === 5) {
      setError({
        ...error,
        employmentType: "Please select a Employment Type.",
      });
    } else if (inputs.employmentType === 0 && inputs.staffGroup.value === 2) {
      setError({
        ...error,
        employmentType:
          "Employment type can't be Direct Engagement if the Staff Group is Nursing.",
      });
    } else if (inputs.paymentType === 5 && !inputs.isSoleTrader) {
      setError({
        ...error,
        paymentType: "Please select a Payment Type.",
      });
      // if (DE && paymentType === 'umbrella_company)
    }

    // else if (
    //   inputs.employmentType === 0 &&
    //   inputs.paymentType !== 0 &&
    //   inputs.paymentType !== 1
    // ) {
    //   setError({
    //     ...error,
    //     paymentType:
    //       "Payment type should be PAYE or LTD if employment type is Direct Engagement.",
    //   });
    // }

    // Validation failed: Payment type should be PAYE or LTD if employment type is Direct Engagement
    else if (
      (inputs.paymentType === 1 && inputs.companyName === undefined) ||
      (inputs.paymentType === 2 && inputs.companyName === undefined)
    ) {
      setError({
        ...error,
        companyName:
          "Company Name is required if payment type is Umbrella Company or Ltd.",
      });
    } else {
      return true;
    }
  };

  // create function
  const [agencyWorkerCreate] =
    useVmsAgencyRegistrationRegisterAgencyWorkerMutation({
      refetchQueries: ["GetAgencyWorkersQuery"],
    });

  const agencyWorkerCreateAction = async () => {
    setError(null);
    if (validateRequiredInputs()) {
      const toastId = toast.loading("Creating Agency Worker...");
      try {
        setLoading(true);

        const { data: result } = await agencyWorkerCreate({
          variables: {
            ...inputs,
            niNumber: inputs.niNumber.toUpperCase().replaceAll(/\s/g, ""),
            gender: Number(inputs.gender + 1),
            staffGroupId: Number(inputs.staffGroup.value),
            gradeId: Number(inputs.grade.value),
            specialityIds: formatToIds(inputs.specialityIds),
            skillIds: formatToIds(inputs.skillIds),
            isVatRegistered: inputs.isVatRegistered === 0 ? true : false,
            companyVatNumber:
              inputs.isVatRegistered === 0 ? inputs.companyVatNumber : "",
            employmentType:
              inputs.employmentType === 0
                ? "direct_engagement"
                : "non_direct_engagement",
            paymentType:
              inputs.isSoleTrader === true
                ? "ltd"
                : inputs.paymentType === 0
                ? "paye"
                : inputs.paymentType === 1
                ? "ltd"
                : "umbrella_company",
          },
        });
        setLoading(false);
        if (result?.vmsAgencyRegistrationRegisterAgencyWorker?.errors?.length) {
          setError({
            message: FormatError(
              result.vmsAgencyRegistrationRegisterAgencyWorker.errors
            ),
          });
          toast.error(
            result?.vmsAgencyRegistrationRegisterAgencyWorker.errors[0].message,
            { id: toastId }
          );
        } else {
          toast.success("Agency Worker created.", { id: toastId });
          refetchWorkers();
          handleCloseAction();
        }
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  // update function
  const [agencyWorkerUpdate] =
    useVmsAgencyRegistrationUpdateAgencyWorkerMutation({
      refetchQueries: ["GetAgencyWorkersQuery"],
    });
  const agencyWorkerUpdateAction = async () => {
    setError(null);
    if (validateRequiredInputs()) {
      const toastId = toast.loading("Updating Agency Worker...");
      try {
        setLoading(true);
        const { data: result } = await agencyWorkerUpdate({
          variables: {
            id: agencyWorker.id ?? "",
            ...inputs,
            companyVatNumber:
              inputs.isVatRegistered === 0 ? inputs.companyVatNumber : "",
            niNumber: inputs.niNumber.toUpperCase().replaceAll(/\s/g, ""),
            gender: Number(inputs.gender + 1),
            gradeId: Number(inputs.grade.value),
            specialityIds: formatToIds(inputs.specialityIds),
            skillIds: formatToIds(inputs.skillIds),
            employmentType:
              inputs.employmentType === 0
                ? "direct_engagement"
                : "non_direct_engagement",
            paymentType:
              inputs.paymentType === 0
                ? "paye"
                : inputs.paymentType === 1
                ? "ltd"
                : "umbrella_company",
          },
        });
        setLoading(false);
        const errors = result?.vmsAgencyRegistrationUpdateAgencyWorker?.errors;
        if (errors.length) {
          setError({
            message: FormatError(errors),
          });

          toast.error(formatError(errors), { id: toastId });
        } else {
          toast.success("Agency Worker updated.", { id: toastId });
          refetchWorkers();
          handleCloseAction();
        }
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  return (
    <Modal
      backdrop="static"
      centered
      data-testid="workers__modal"
      onHide={() => handleCloseAction}
      show={show}
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {agencyWorker
            ? agencyWorker?.user?.firstName
              ? agencyWorker.user.firstName
              : agencyWorker.firstName
            : `Register Agency Worker`}{" "}
          {agencyWorker
            ? agencyWorker?.user?.lastName
              ? agencyWorker.user.lastName
              : agencyWorker.lastName
            : ""}
        </ModalTitle>
        <CloseButton onClick={handleCloseAction} />
      </ModalHeader>
      {loading ? (
        <Loading modal />
      ) : (
        <>
          <ModalBody>
            <AgencyWorkersModalDetailsPage
              agencyWorker={agencyWorker}
              agencyWorkerPresent={agencyWorker ? true : false}
              error={error}
              handleFilter={handleFilter}
              handleInputChange={handleInputChange}
              handleToggleButtons={handleToggleButtons}
              inputs={inputs}
              invitation={invitation}
              refetchWorkers={refetchWorkers}
            />
          </ModalBody>
          <ModalFooter>
            <div />
            <div>
              <DefaultButton
                action={() => handleCloseAction()}
                data-cy="cancelButton"
                text="Cancel"
                type="white"
              />

              {agencyWorker ? (
                <DefaultButton
                  action={() => agencyWorkerUpdateAction()}
                  color="blue"
                  data-cy="confirmWorker"
                  text="Update Worker"
                />
              ) : (
                <DefaultButton
                  action={() => {
                    if (validateRequiredInputs()) {
                      agencyWorkerCreateAction();
                    }
                  }}
                  color="blue"
                  data-cy="confirmWorker"
                  text="Confirm & Create"
                />
              )}

              {agencyWorker && (
                <DefaultButton
                  action={() => {
                    invitationMutation({
                      variables: {
                        agencyRegistrationId: Number(agencyWorker.id),
                      },
                    })
                      .then((res) => {
                        const errors = res.data.vmsAgencyInvitation.errors;

                        if (errors.length > 0) {
                          toast.error(`${errors[0].message}`);
                          refetchWorkers();
                          return;
                        }
                        toast.success("Email Sent");
                        refetchWorkers();
                      })
                      .catch(() => {
                        toast.error("An error occurred.");
                      });
                  }}
                  color="blue"
                  data-cy="send_activation_email"
                  text="Send Activation Email"
                />
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default AgencyWorkersModal;
