import { Switch } from "react-router-dom";

import Layout from "components/Layout";
import BlocksPage from "containers/blocks";
import OrganisationsPage from "containers/organisations";
import ShiftsPage from "containers/shifts";
import UnauthorisedPage from "containers/unauthorised";
import UsersPage from "containers/users";
import WorkersPage from "containers/workers";
import PendingWorkersPage from "containers/workers-pending";

import Route from "./RouteWrapper";

export default function PrivateRoutes() {
  return (
    <Layout>
      <Switch>
        <Route component={ShiftsPage} exact isPrivate path="/shifts" />
        <Route
          component={OrganisationsPage}
          exact
          isPrivate
          path="/organisations"
        />
        <Route component={BlocksPage} exact isPrivate path="/blocks" />
        <Route component={WorkersPage} exact isPrivate path="/workers" />
        <Route
          component={PendingWorkersPage}
          exact
          isPrivate
          path="/workers/pending"
        />
        <Route
          canManageUsers
          component={UsersPage}
          exact
          isPrivate
          path="/users"
        />
        {/* Errors / 404 / Fallback --------------------------------------------------*/}
        <Route
          component={UnauthorisedPage}
          exact
          isPrivate
          path="/unauthorised"
        />

        <Route component={UnauthorisedPage} exact path="*" />
        {/* If URL does not match anything ^ */}
      </Switch>
    </Layout>
  );
}
