import { useState } from "react";
import styled from "styled-components";

import {
  DefaultButton,
  GREY,
  TableHeaderContainer,
  TableHeaderInner,
  TableHeaderInnerOption,
  TableSearchInput,
} from "@patchworkhealth/web-components";
import { ButtonAdd } from "@patchworkhealth/web-components/icons";

type TableHeaderProps = {
  buttonColor?: string;
  buttonIcon?: any;
  buttonText?: string;
  buttonType?: string;
  dataCount: number;
  handleShow?: Function;
  // handleCalendar?: Function;
  inputPlaceholder?: string;
  resetPagination?: Function;
  setSearchText?: Function;
  title: string;
  trashed?: boolean;
  setTrashed?: Function;
  // calendar?: boolean;
};

const TableHeader = ({
  buttonColor,
  buttonIcon,
  buttonText,
  buttonType,
  dataCount,
  handleShow,
  inputPlaceholder,
  resetPagination,
  setSearchText,
  // handleCalendar,
  title,
  //
  trashed,
  setTrashed,
}: // calendar,
TableHeaderProps) => {
  const [inputString, setInputString] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    setSearchText(inputString);
    resetPagination?.();
  };

  return (
    <TableHeaderContainer>
      <TableHeaderInner>
        <h2
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          {title} <span data-testid="test__count">{dataCount ?? 0}</span>
        </h2>
        {setSearchText && (
          <TableHeaderInnerOption
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <form onSubmit={submitForm}>
              <TableSearchInput
                data-testid="input__filter"
                isClearable={true}
                onChange={(e) => {
                  setInputString(e.target.value);
                }}
                placeholder={inputPlaceholder || `Search ${title}`}
                type="text"
                value={inputString}
              />

              {inputString && (
                <ClearButton
                  onClick={() => {
                    setSearchText("");
                    setInputString("");
                  }}
                >
                  X
                </ClearButton>
              )}
            </form>

            {setTrashed && (
              <>
                <span style={{ marginLeft: 20, marginRight: 8 }}>
                  Show Deleted
                </span>

                <ToggleButton
                  aria-checked={true}
                  aria-labelledby={`${123} labelId`}
                  checked={trashed}
                  disabled={false}
                  onClick={() => setTrashed(!trashed)}
                  role="switch"
                >
                  <ToggleButtonHandle checked={trashed} />
                </ToggleButton>
              </>
            )}
          </TableHeaderInnerOption>
        )}
      </TableHeaderInner>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* {calendar && (
      <DefaultButton
        action={handleCalendar}
        color="white"
        icon={ShiftsFilter}
        style={{ marginRight: 10 }}
        text="Calendar"
        type={buttonType}
      />
    )} */}
        {handleShow && title !== "Active Workers" && (
          <DefaultButton
            action={handleShow}
            color={buttonColor ?? "blue"}
            data-cy="tableHeaderBtn"
            icon={buttonIcon ?? ButtonAdd}
            text={buttonText}
            type={buttonType}
          />
        )}
      </div>
    </TableHeaderContainer>
  );
};

export default TableHeader;

const ClearButton = styled.div`
  border-radius: 50%;
  color: ${GREY.five};
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 11px;
`;

// const ShiftsFilter = () => (
//   <svg
//     fill="none"
//     height="17"
//     viewBox="0 0 17 17"
//     width="17"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M13.0091 16.2241H1.99058C1.12124 16.2241 0.416504 15.5295 0.416504 14.6725V3.81143C0.416504 2.95451 1.12124 2.25984 1.99058 2.25984H3.56465V0.708252H5.13873V2.25984H9.86095V0.708252H11.435V2.25984H13.0091C13.8784 2.25984 14.5832 2.95451 14.5832 3.81143V14.6725C14.5832 15.5295 13.8784 16.2241 13.0091 16.2241ZM1.99058 6.9146V14.6725H13.0091V6.9146H1.99058ZM1.99058 3.81143V5.36301H13.0091V3.81143H1.99058ZM8.28687 12.821C8.28687 12.9866 8.15256 13.121 7.98687 13.121H7.0128C6.84712 13.121 6.7128 12.9866 6.7128 12.821V11.8694C6.7128 11.7037 6.84712 11.5694 7.0128 11.5694H7.98687C8.15256 11.5694 8.28687 11.7037 8.28687 11.8694V12.821ZM5.13873 12.821C5.13873 12.9866 5.00441 13.121 4.83873 13.121H3.86465C3.69897 13.121 3.56465 12.9866 3.56465 12.821V11.8694C3.56465 11.7037 3.69897 11.5694 3.86465 11.5694H4.83873C5.00441 11.5694 5.13873 11.7037 5.13873 11.8694V12.821ZM11.435 9.71778C11.435 9.88346 11.3007 10.0178 11.135 10.0178H10.1609C9.99526 10.0178 9.86095 9.88346 9.86095 9.71778V8.76619C9.86095 8.6005 9.99526 8.46619 10.1609 8.46619H11.135C11.3007 8.46619 11.435 8.6005 11.435 8.76619V9.71778ZM8.28687 9.71778C8.28687 9.88346 8.15256 10.0178 7.98687 10.0178H7.0128C6.84712 10.0178 6.7128 9.88346 6.7128 9.71778V8.76619C6.7128 8.6005 6.84712 8.46619 7.0128 8.46619H7.98687C8.15256 8.46619 8.28687 8.6005 8.28687 8.76619V9.71778ZM5.13873 9.71778C5.13873 9.88346 5.00441 10.0178 4.83873 10.0178H3.86465C3.69897 10.0178 3.56465 9.88346 3.56465 9.71778V8.76619C3.56465 8.6005 3.69897 8.46619 3.86465 8.46619H4.83873C5.00441 8.46619 5.13873 8.6005 5.13873 8.76619V9.71778Z"
//       fill="#7D8793"
//     />
//   </svg>
// );

const ToggleButton = styled.button<{ checked: boolean; disabled: boolean }>`
  background: ${(props) => (props.checked ? "#05405C" : "#fff")};
  border: 1px solid ${(props) => (props.checked ? "transparent" : "#c7c7c7")};
  border-radius: 24px;
  box-shadow: 0px 1px 2px rgba(215, 215, 215, 0.05);
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 48px;
`;
const ToggleButtonHandle = styled.div<{ checked: boolean }>`
  background: ${(props) => (props.checked ? "#fff" : "#9FA0A2")};
  border-radius: 25px;
  box-shadow: 0px 1px 2px rgba(215, 215, 215, 0.05);
  height: 16px;
  left: ${(props) => (props.checked ? "28px" : "4px")};
  pointer-events: none;
  position: absolute;
  top: 3px;
  transition: all 0.25s ease-in-out;
  width: 16px;
`;
