import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import { ErrorFragmentDoc } from '../../../helpers/__generated__/fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AgencyUsersFragment = { __typename?: 'AgencyUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null, jobTitle?: string | null, canManageUsers?: boolean | null, canCreateAgencyWorkers?: boolean | null, suspended?: boolean | null, agencyUserOrganisations?: Array<{ __typename?: 'AgencyUserOrganisation', organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null }> | null, agency?: { __typename?: 'Agency', id: string, canManageUsers?: boolean | null } | null };

export type AgencyUserFragment = { __typename?: 'AgencyUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null, jobTitle?: string | null, canManageUsers?: boolean | null, canCreateAgencyWorkers?: boolean | null, suspended?: boolean | null, agencyUserPermissions?: Array<{ __typename?: 'AgencyUserPermission', id: string, canProposeWorker: boolean, canAssignWorker: boolean, organisationStaffGroup: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null } }> | null, agency?: { __typename?: 'Agency', id: string, title?: string | null, canManageUsers?: boolean | null, organisations?: Array<{ __typename?: 'Organisation', id: number, name?: string | null }> | null, organisationAgencies?: Array<{ __typename?: 'OrganisationAgency', canAssignWorker: boolean, canProposeWorker: boolean, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null, organisationStaffGroup?: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null } | null }> | null } | null };

export type GetAgencyUsersQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  items?: Types.InputMaybe<Types.Scalars['Int']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAgencyUsersQuery = { __typename?: 'Query', vmsAgencyUsers: { __typename?: 'AgencyUsersPaginatedList', list?: Array<{ __typename?: 'AgencyUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null, jobTitle?: string | null, canManageUsers?: boolean | null, canCreateAgencyWorkers?: boolean | null, suspended?: boolean | null, agencyUserOrganisations?: Array<{ __typename?: 'AgencyUserOrganisation', organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null }> | null, agency?: { __typename?: 'Agency', id: string, canManageUsers?: boolean | null } | null }> | null, pagination?: { __typename?: 'Pagination', last?: number | null, next?: number | null, page?: number | null, count?: number | null, pages?: number | null, prev?: number | null } | null } };

export type GetAgencyUserQueryVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAgencyUserQuery = { __typename?: 'Query', vmsAgencyUsers: { __typename?: 'AgencyUsersPaginatedList', list?: Array<{ __typename?: 'AgencyUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null, jobTitle?: string | null, canManageUsers?: boolean | null, canCreateAgencyWorkers?: boolean | null, suspended?: boolean | null, agencyUserPermissions?: Array<{ __typename?: 'AgencyUserPermission', id: string, canProposeWorker: boolean, canAssignWorker: boolean, organisationStaffGroup: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null } }> | null, agency?: { __typename?: 'Agency', id: string, title?: string | null, canManageUsers?: boolean | null, organisations?: Array<{ __typename?: 'Organisation', id: number, name?: string | null }> | null, organisationAgencies?: Array<{ __typename?: 'OrganisationAgency', canAssignWorker: boolean, canProposeWorker: boolean, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null, organisationStaffGroup?: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null } | null }> | null } | null }> | null } };

export type AgencyUserCreateMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
  jobTitle: Types.Scalars['String'];
  canManageUsers: Types.Scalars['Boolean'];
}>;


export type AgencyUserCreateMutation = { __typename?: 'Mutation', vmsAgencyUserCreate?: { __typename?: 'VmsAgencyUserCreatePayload', agencyUser?: { __typename?: 'AgencyUser', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phoneNumber?: string | null, jobTitle?: string | null, canManageUsers?: boolean | null, canCreateAgencyWorkers?: boolean | null, suspended?: boolean | null, agencyUserPermissions?: Array<{ __typename?: 'AgencyUserPermission', id: string, canProposeWorker: boolean, canAssignWorker: boolean, organisationStaffGroup: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null } }> | null, agency?: { __typename?: 'Agency', id: string, title?: string | null, canManageUsers?: boolean | null, organisations?: Array<{ __typename?: 'Organisation', id: number, name?: string | null }> | null, organisationAgencies?: Array<{ __typename?: 'OrganisationAgency', canAssignWorker: boolean, canProposeWorker: boolean, organisation?: { __typename?: 'Organisation', id: number, name?: string | null } | null, organisationStaffGroup?: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null } | null }> | null } | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type AgencyUserUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
  jobTitle: Types.Scalars['String'];
  canManageUsers: Types.Scalars['Boolean'];
  organisations?: Types.InputMaybe<Array<Types.OrganisationInput> | Types.OrganisationInput>;
}>;


export type AgencyUserUpdateMutation = { __typename?: 'Mutation', vmsAgencyUserUpdate?: { __typename?: 'VmsAgencyUserUpdatePayload', agencyUser?: { __typename?: 'AgencyUser', id: string } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type AgencyUserPermissionCreateMutationVariables = Types.Exact<{
  agencyUserId: Types.Scalars['Int'];
  organisationStaffGroupId: Types.Scalars['Int'];
  canAssignWorker: Types.Scalars['Boolean'];
  canProposeWorker: Types.Scalars['Boolean'];
}>;


export type AgencyUserPermissionCreateMutation = { __typename?: 'Mutation', vmsAgencyUserPermissionCreate: boolean };

export type AgencyUserPermissionUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  organisationStaffGroupId: Types.Scalars['Int'];
  canAssignWorker: Types.Scalars['Boolean'];
  canProposeWorker: Types.Scalars['Boolean'];
}>;


export type AgencyUserPermissionUpdateMutation = { __typename?: 'Mutation', vmsAgencyUserPermissionUpdate: boolean };

export type AgencyUserPermissionDestroyMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type AgencyUserPermissionDestroyMutation = { __typename?: 'Mutation', vmsAgencyUserPermissionDestroy: boolean };

export type SuspendAgencyUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type SuspendAgencyUserMutation = { __typename?: 'Mutation', vmsAgencyUserSuspend: boolean };

export type UnsuspendAgencyUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type UnsuspendAgencyUserMutation = { __typename?: 'Mutation', vmsAgencyUserUnsuspend: boolean };

export const AgencyUsersFragmentDoc = gql`
    fragment AgencyUsers on AgencyUser {
  id
  firstName
  lastName
  email
  phoneNumber
  jobTitle
  canManageUsers
  canCreateAgencyWorkers
  suspended
  agencyUserOrganisations {
    organisation {
      id
      name
    }
  }
  agency {
    id
    canManageUsers
  }
}
    `;
export const AgencyUserFragmentDoc = gql`
    fragment AgencyUser on AgencyUser {
  id
  firstName
  lastName
  email
  agencyUserPermissions {
    id
    canProposeWorker
    canAssignWorker
    organisationStaffGroup {
      id
      title
      organisation {
        id
        name
      }
    }
  }
  agency {
    id
    title
    organisations {
      id
      name
    }
    organisationAgencies {
      organisation {
        id
        name
      }
      organisationStaffGroup {
        id
        title
      }
      canAssignWorker
      canProposeWorker
    }
    canManageUsers
  }
  phoneNumber
  jobTitle
  canManageUsers
  canCreateAgencyWorkers
  suspended
}
    `;
export const GetAgencyUsersDocument = gql`
    query GetAgencyUsers($page: Int, $items: Int, $name: String, $email: String) {
  vmsAgencyUsers(page: $page, items: $items, name: $name, email: $email) {
    list {
      ...AgencyUsers
    }
    pagination {
      last
      next
      page
      count
      pages
      prev
    }
  }
}
    ${AgencyUsersFragmentDoc}`;

/**
 * __useGetAgencyUsersQuery__
 *
 * To run a query within a React component, call `useGetAgencyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      items: // value for 'items'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAgencyUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAgencyUsersQuery, GetAgencyUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgencyUsersQuery, GetAgencyUsersQueryVariables>(GetAgencyUsersDocument, options);
      }
export function useGetAgencyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgencyUsersQuery, GetAgencyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgencyUsersQuery, GetAgencyUsersQueryVariables>(GetAgencyUsersDocument, options);
        }
export type GetAgencyUsersQueryHookResult = ReturnType<typeof useGetAgencyUsersQuery>;
export type GetAgencyUsersLazyQueryHookResult = ReturnType<typeof useGetAgencyUsersLazyQuery>;
export type GetAgencyUsersQueryResult = Apollo.QueryResult<GetAgencyUsersQuery, GetAgencyUsersQueryVariables>;
export const GetAgencyUserDocument = gql`
    query GetAgencyUser($email: String) {
  vmsAgencyUsers(email: $email) {
    list {
      ...AgencyUser
    }
  }
}
    ${AgencyUserFragmentDoc}`;

/**
 * __useGetAgencyUserQuery__
 *
 * To run a query within a React component, call `useGetAgencyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAgencyUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAgencyUserQuery, GetAgencyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgencyUserQuery, GetAgencyUserQueryVariables>(GetAgencyUserDocument, options);
      }
export function useGetAgencyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgencyUserQuery, GetAgencyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgencyUserQuery, GetAgencyUserQueryVariables>(GetAgencyUserDocument, options);
        }
export type GetAgencyUserQueryHookResult = ReturnType<typeof useGetAgencyUserQuery>;
export type GetAgencyUserLazyQueryHookResult = ReturnType<typeof useGetAgencyUserLazyQuery>;
export type GetAgencyUserQueryResult = Apollo.QueryResult<GetAgencyUserQuery, GetAgencyUserQueryVariables>;
export const AgencyUserCreateDocument = gql`
    mutation AgencyUserCreate($firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $jobTitle: String!, $canManageUsers: Boolean!) {
  vmsAgencyUserCreate(
    firstName: $firstName
    lastName: $lastName
    email: $email
    phoneNumber: $phoneNumber
    jobTitle: $jobTitle
    canManageUsers: $canManageUsers
  ) {
    agencyUser {
      ...AgencyUser
    }
    errors {
      ...Error
    }
  }
}
    ${AgencyUserFragmentDoc}
${ErrorFragmentDoc}`;
export type AgencyUserCreateMutationFn = Apollo.MutationFunction<AgencyUserCreateMutation, AgencyUserCreateMutationVariables>;

/**
 * __useAgencyUserCreateMutation__
 *
 * To run a mutation, you first call `useAgencyUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserCreateMutation, { data, loading, error }] = useAgencyUserCreateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      jobTitle: // value for 'jobTitle'
 *      canManageUsers: // value for 'canManageUsers'
 *   },
 * });
 */
export function useAgencyUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserCreateMutation, AgencyUserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserCreateMutation, AgencyUserCreateMutationVariables>(AgencyUserCreateDocument, options);
      }
export type AgencyUserCreateMutationHookResult = ReturnType<typeof useAgencyUserCreateMutation>;
export type AgencyUserCreateMutationResult = Apollo.MutationResult<AgencyUserCreateMutation>;
export type AgencyUserCreateMutationOptions = Apollo.BaseMutationOptions<AgencyUserCreateMutation, AgencyUserCreateMutationVariables>;
export const AgencyUserUpdateDocument = gql`
    mutation AgencyUserUpdate($id: Int!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $jobTitle: String!, $canManageUsers: Boolean!, $organisations: [OrganisationInput!]) {
  vmsAgencyUserUpdate(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    phoneNumber: $phoneNumber
    jobTitle: $jobTitle
    canManageUsers: $canManageUsers
    organisations: $organisations
  ) {
    agencyUser {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type AgencyUserUpdateMutationFn = Apollo.MutationFunction<AgencyUserUpdateMutation, AgencyUserUpdateMutationVariables>;

/**
 * __useAgencyUserUpdateMutation__
 *
 * To run a mutation, you first call `useAgencyUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserUpdateMutation, { data, loading, error }] = useAgencyUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      jobTitle: // value for 'jobTitle'
 *      canManageUsers: // value for 'canManageUsers'
 *      organisations: // value for 'organisations'
 *   },
 * });
 */
export function useAgencyUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserUpdateMutation, AgencyUserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserUpdateMutation, AgencyUserUpdateMutationVariables>(AgencyUserUpdateDocument, options);
      }
export type AgencyUserUpdateMutationHookResult = ReturnType<typeof useAgencyUserUpdateMutation>;
export type AgencyUserUpdateMutationResult = Apollo.MutationResult<AgencyUserUpdateMutation>;
export type AgencyUserUpdateMutationOptions = Apollo.BaseMutationOptions<AgencyUserUpdateMutation, AgencyUserUpdateMutationVariables>;
export const AgencyUserPermissionCreateDocument = gql`
    mutation AgencyUserPermissionCreate($agencyUserId: Int!, $organisationStaffGroupId: Int!, $canAssignWorker: Boolean!, $canProposeWorker: Boolean!) {
  vmsAgencyUserPermissionCreate(
    agencyUserId: $agencyUserId
    organisationStaffGroupId: $organisationStaffGroupId
    canAssignWorker: $canAssignWorker
    canProposeWorker: $canProposeWorker
  )
}
    `;
export type AgencyUserPermissionCreateMutationFn = Apollo.MutationFunction<AgencyUserPermissionCreateMutation, AgencyUserPermissionCreateMutationVariables>;

/**
 * __useAgencyUserPermissionCreateMutation__
 *
 * To run a mutation, you first call `useAgencyUserPermissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserPermissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserPermissionCreateMutation, { data, loading, error }] = useAgencyUserPermissionCreateMutation({
 *   variables: {
 *      agencyUserId: // value for 'agencyUserId'
 *      organisationStaffGroupId: // value for 'organisationStaffGroupId'
 *      canAssignWorker: // value for 'canAssignWorker'
 *      canProposeWorker: // value for 'canProposeWorker'
 *   },
 * });
 */
export function useAgencyUserPermissionCreateMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserPermissionCreateMutation, AgencyUserPermissionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserPermissionCreateMutation, AgencyUserPermissionCreateMutationVariables>(AgencyUserPermissionCreateDocument, options);
      }
export type AgencyUserPermissionCreateMutationHookResult = ReturnType<typeof useAgencyUserPermissionCreateMutation>;
export type AgencyUserPermissionCreateMutationResult = Apollo.MutationResult<AgencyUserPermissionCreateMutation>;
export type AgencyUserPermissionCreateMutationOptions = Apollo.BaseMutationOptions<AgencyUserPermissionCreateMutation, AgencyUserPermissionCreateMutationVariables>;
export const AgencyUserPermissionUpdateDocument = gql`
    mutation AgencyUserPermissionUpdate($id: Int!, $organisationStaffGroupId: Int!, $canAssignWorker: Boolean!, $canProposeWorker: Boolean!) {
  vmsAgencyUserPermissionUpdate(
    id: $id
    organisationStaffGroupId: $organisationStaffGroupId
    canAssignWorker: $canAssignWorker
    canProposeWorker: $canProposeWorker
  )
}
    `;
export type AgencyUserPermissionUpdateMutationFn = Apollo.MutationFunction<AgencyUserPermissionUpdateMutation, AgencyUserPermissionUpdateMutationVariables>;

/**
 * __useAgencyUserPermissionUpdateMutation__
 *
 * To run a mutation, you first call `useAgencyUserPermissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserPermissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserPermissionUpdateMutation, { data, loading, error }] = useAgencyUserPermissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationStaffGroupId: // value for 'organisationStaffGroupId'
 *      canAssignWorker: // value for 'canAssignWorker'
 *      canProposeWorker: // value for 'canProposeWorker'
 *   },
 * });
 */
export function useAgencyUserPermissionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserPermissionUpdateMutation, AgencyUserPermissionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserPermissionUpdateMutation, AgencyUserPermissionUpdateMutationVariables>(AgencyUserPermissionUpdateDocument, options);
      }
export type AgencyUserPermissionUpdateMutationHookResult = ReturnType<typeof useAgencyUserPermissionUpdateMutation>;
export type AgencyUserPermissionUpdateMutationResult = Apollo.MutationResult<AgencyUserPermissionUpdateMutation>;
export type AgencyUserPermissionUpdateMutationOptions = Apollo.BaseMutationOptions<AgencyUserPermissionUpdateMutation, AgencyUserPermissionUpdateMutationVariables>;
export const AgencyUserPermissionDestroyDocument = gql`
    mutation AgencyUserPermissionDestroy($id: Int!) {
  vmsAgencyUserPermissionDestroy(id: $id)
}
    `;
export type AgencyUserPermissionDestroyMutationFn = Apollo.MutationFunction<AgencyUserPermissionDestroyMutation, AgencyUserPermissionDestroyMutationVariables>;

/**
 * __useAgencyUserPermissionDestroyMutation__
 *
 * To run a mutation, you first call `useAgencyUserPermissionDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserPermissionDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserPermissionDestroyMutation, { data, loading, error }] = useAgencyUserPermissionDestroyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgencyUserPermissionDestroyMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserPermissionDestroyMutation, AgencyUserPermissionDestroyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserPermissionDestroyMutation, AgencyUserPermissionDestroyMutationVariables>(AgencyUserPermissionDestroyDocument, options);
      }
export type AgencyUserPermissionDestroyMutationHookResult = ReturnType<typeof useAgencyUserPermissionDestroyMutation>;
export type AgencyUserPermissionDestroyMutationResult = Apollo.MutationResult<AgencyUserPermissionDestroyMutation>;
export type AgencyUserPermissionDestroyMutationOptions = Apollo.BaseMutationOptions<AgencyUserPermissionDestroyMutation, AgencyUserPermissionDestroyMutationVariables>;
export const SuspendAgencyUserDocument = gql`
    mutation SuspendAgencyUser($id: Int!) {
  vmsAgencyUserSuspend(id: $id)
}
    `;
export type SuspendAgencyUserMutationFn = Apollo.MutationFunction<SuspendAgencyUserMutation, SuspendAgencyUserMutationVariables>;

/**
 * __useSuspendAgencyUserMutation__
 *
 * To run a mutation, you first call `useSuspendAgencyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendAgencyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendAgencyUserMutation, { data, loading, error }] = useSuspendAgencyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSuspendAgencyUserMutation(baseOptions?: Apollo.MutationHookOptions<SuspendAgencyUserMutation, SuspendAgencyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspendAgencyUserMutation, SuspendAgencyUserMutationVariables>(SuspendAgencyUserDocument, options);
      }
export type SuspendAgencyUserMutationHookResult = ReturnType<typeof useSuspendAgencyUserMutation>;
export type SuspendAgencyUserMutationResult = Apollo.MutationResult<SuspendAgencyUserMutation>;
export type SuspendAgencyUserMutationOptions = Apollo.BaseMutationOptions<SuspendAgencyUserMutation, SuspendAgencyUserMutationVariables>;
export const UnsuspendAgencyUserDocument = gql`
    mutation UnsuspendAgencyUser($id: Int!) {
  vmsAgencyUserUnsuspend(id: $id)
}
    `;
export type UnsuspendAgencyUserMutationFn = Apollo.MutationFunction<UnsuspendAgencyUserMutation, UnsuspendAgencyUserMutationVariables>;

/**
 * __useUnsuspendAgencyUserMutation__
 *
 * To run a mutation, you first call `useUnsuspendAgencyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsuspendAgencyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsuspendAgencyUserMutation, { data, loading, error }] = useUnsuspendAgencyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsuspendAgencyUserMutation(baseOptions?: Apollo.MutationHookOptions<UnsuspendAgencyUserMutation, UnsuspendAgencyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsuspendAgencyUserMutation, UnsuspendAgencyUserMutationVariables>(UnsuspendAgencyUserDocument, options);
      }
export type UnsuspendAgencyUserMutationHookResult = ReturnType<typeof useUnsuspendAgencyUserMutation>;
export type UnsuspendAgencyUserMutationResult = Apollo.MutationResult<UnsuspendAgencyUserMutation>;
export type UnsuspendAgencyUserMutationOptions = Apollo.BaseMutationOptions<UnsuspendAgencyUserMutation, UnsuspendAgencyUserMutationVariables>;