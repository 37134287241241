import { gql } from "@apollo/client";

// ALL SHIFT BLOCKS *************************************

export const SHIFT_BLOCKS = gql`
  query shiftBlocks(
    $gradeIds: [Int!]
    $departmentIds: [Int!]
    $startDateTo: DateTime
    $startDateFrom: DateTime
    $shiftBlockIds: [Int!]
    $statuses: [ShiftBlockStatusEnum!]
    $siteIds: [Int!]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $organisationIds: [Int!]
  ) {
    shiftBlocks(
      gradeIds: $gradeIds
      departmentIds: $departmentIds
      startDateTo: $startDateTo
      startDateFrom: $startDateFrom
      statuses: $statuses
      shiftBlockIds: $shiftBlockIds
      siteIds: $siteIds
      first: $first
      last: $last
      after: $after
      before: $before
      organisationIds: $organisationIds
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      nodes {
        endDate
        id
        uid
        name
        status
        startDate
        department
        site
        sentToAgencyDate
        grade {
          label: title
          colour
        }

        bookedBy {
          ... on AgencyRegistration {
            id
            paymentId
            paymentType
            cvUrl
            compliancePackUrl
            complianceExpirationDate
            employmentType
            confirmedAt
            invitedAt
            companyName
            grade {
              id
              colour
              title
            }
            esrNumbers {
              esrNumber
              id
              validFrom
              validTo
              organisation {
                id
                name
              }
            }
            staffGroup {
              idValue
              title
            }
            skills {
              id
              name
            }
            user {
              id
              fullName
              dateOfBirth
              dbsNumber
              email
              firstName
              gender
              lastName
              niNumber
              phoneNumber
              postcode
              profilePictureUrl
              regBodyNumber
              specialities {
                id
                title
              }
            }
          }
        }

        agencyWorkerBlockProposals {
          id
          status
        }
      }
    }
  }
`;

// SINGLE BLOCK *************************************

export const SHIFT_BLOCK = gql`
  query ShiftBlock($shiftBlockIds: [Int!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds) {
      nodes {
        endDate
        id
        name
        status
        startDate
        modApprovalBudgetValue
        shiftCount
        workerNotes
        totalHours
        totalRate
        agencyRateCardAttached
        #
        modAccommodationExpensesBudget
        modApprovalBudgetValue
        modApprovedWeeklyHours
        modFinancialApprovalCode
        modOutOfHoursBudget
        modOutOfHoursEstimatedWeeklyHours
        modPositionNumber
        modSecurityClearance
        modSubsistenceExpensesBudget
        modTravelExpensesBudget
        staffGroup {
          idValue
          title
        }
        reasonForBooking {
          value: id
          label: reason
        }
        organisation {
          name
          id
          usesDeSoleTrader
          hideDeLtdAgencyWorkers
        }
        grade {
          colour
          label: title
        }
        staffGroup {
          idValue
          title
        }

        shifts {
          id
          startTime
          endTime
          status
        }

        agencyWorkerRates {
          agencyMargin
          agencyMarginBreakdown
          agencyMarginLocked
          comparatorRate
          comparatorRateBreakdown
          hours
          ltdUmbrellaRate
          ltdUmbrellaRateBreakdown
          name
          niContribution
          workerBaseRate
          workerPayeRate
          workerPayeRateBreakdown
          workerRate
          workerRateBreakdown
          errors {
            attribute
            message
          }
          initialRates {
            agencyMargin
            comparatorRate
            ltdUmbrellaRate
            workerBaseRate
            workerPayeRate
            workerRate
          }
        }

        #

        bookedBy {
          ... on AgencyRegistration {
            cvUrl
            compliancePackUrl
            complianceExpirationDate
            employmentType
            confirmedAt
            invitedAt
            companyName
            id
            paymentId
            paymentType
            grade {
              id
              colour
              title
            }
            esrNumbers {
              esrNumber
              id
              validFrom
              validTo
              organisation {
                id
                name
              }
            }
            staffGroup {
              idValue
              title
            }
            skills {
              id
              name
            }
            user {
              id
              fullName
              dateOfBirth
              dbsNumber
              email
              firstName
              gender
              lastName
              niNumber
              phoneNumber
              postcode
              profilePictureUrl
              regBodyNumber
              specialities {
                id
                title
              }
            }
          }
        }

        agencyWorkerBlockProposals {
          id
          status
          rates {
            agencyMargin
            agencyMarginBreakdown
            agencyMarginLocked
            comparatorRate
            comparatorRateBreakdown
            hours
            ltdUmbrellaRate
            ltdUmbrellaRateBreakdown
            name
            niContribution
            workerBaseRate
            workerPayeRate
            workerPayeRateBreakdown
            workerRate
            workerRateBreakdown
            errors {
              attribute
              message
            }
            initialRates {
              agencyMargin
              comparatorRate
              ltdUmbrellaRate
              workerBaseRate
              workerPayeRate
              workerRate
            }
          }

          agencyWorker {
            id
            firstName
            email
            isSoleTrader
            lastName
            email
            employmentType
            paymentType
            grade {
              title
              colour
            }
          }
        }
      }
    }
  }
`;

// CREATE PROPOSAL ****************************

export const VMS_SHIFT_BLOCK_PROPOSAL_CREATE = gql`
  mutation vmsShiftBlockProposalCreate(
    $agencyWorkerProposalAttributes: ProposalCreate!
  ) {
    vmsShiftBlockProposalCreate(
      agencyWorkerProposalAttributes: $agencyWorkerProposalAttributes
    ) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;

// Revoke Proposal **************************

export const VMS_SHIFT_BLOCK_PROPOSAL_REVOKE = gql`
  mutation vmsShiftBlockProposalRevoke($agencyWorkerBlockProposalId: Int!) {
    vmsShiftBlockProposalRevoke(
      agencyWorkerBlockProposalId: $agencyWorkerBlockProposalId
    ) {
      errors {
        attribute
        message
      }
    }
  }
`;

// Cancel Booking **************************

export const VMS_SHIFT_BLOCK_CANCEL_AGENCY_BOOKING = gql`
  mutation vmsShiftBlockCancelAgencyBooking(
    $cancelAgencyBookingAttributes: CancelAgencyBookingAttributes!
  ) {
    vmsShiftBlockCancelAgencyBooking(
      cancelAgencyBookingAttributes: $cancelAgencyBookingAttributes
    ) {
      errors {
        attribute
        message
      }
    }
  }
`;

// Helpers **************************************

export const securityClearance = [
  {
    label: "Baseline Personnel Security Standard (BPSS)",
    value: "BASELINE_PERSONNEL_SECURITY_STANDARD",
  },

  {
    label: "Counter Terrorism Check (CTC)",
    value: "COUNTER_TERRORISM_CHECK",
  },
  {
    label: "Security Check (SC)",
    value: "SECURITY_CHECK",
  },
  {
    label: "Enhanced Security Check (eSC)",
    value: "ENHANCED_SECURITY_CHECK",
  },
  {
    label: "Developed Vetting (DV)",
    value: "DEVELOPED_VETTING",
  },

  {
    label: "Enhanced Developed Vetting (eDV)",
    value: "ENHANCED_DEVELOPED_VETTING",
  },
];

export const checkProposals = (row) => {
  let proposals = 0;
  row.agencyWorkerBlockProposals?.forEach((element) => {
    if (element.status === "proposed") {
      proposals += 1;
    }
  });
  return proposals;
};
