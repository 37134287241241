import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { getTokens } from "helpers/functions";
import { RootState } from "models/store";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  canManageUsers,
  ...rest
}: Props) {
  const userCanManageUsers = useSelector(
    (state: RootState) => state.user?.canManageUsers
  );

  const signed = getTokens();

  if ((rest.path === "*" || isPrivate) && !signed) return <Redirect to="/" />;

  /**
   * Redirect user to SignIn page if he tries to access a aprivate route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Redirect to="/" />;
  }

  if (!userCanManageUsers && canManageUsers && signed)
    return <Redirect to="/unauthorised" />;

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && signed) {
    return <Redirect to="/shifts" />;
  }

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={Component} />;
}

type Props = {
  component: ({ match }) => JSX.Element;
  exact?: boolean;
  path: string;
  isPrivate?: boolean;
  canManageUsers?: boolean;
};
