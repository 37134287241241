export const SlideLeftIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.70711 0.292893C7.31658 -0.0976312 6.68342 -0.0976313 6.29289 0.292893L0.292892 6.29289C-0.0976326 6.68342 -0.0976327 7.31658 0.292892 7.70711L6.29289 13.7071C6.68341 14.0976 7.31658 14.0976 7.7071 13.7071C8.09763 13.3166 8.09763 12.6834 7.7071 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683418 7.70711 0.292893Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);

export const SlideRightIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292894C1.31658 -0.0976313 0.683419 -0.0976314 0.292894 0.292893C-0.0976299 0.683417 -0.09763 1.31658 0.292894 1.70711L5.58579 7L0.292893 12.2929C-0.097631 12.6834 -0.097631 13.3166 0.292893 13.7071Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);

export const AgencyLockIcon = () => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49992 9.20829C8.31206 9.20829 8.13189 9.28292 7.99905 9.41576C7.86621 9.5486 7.79158 9.72876 7.79158 9.91663V12.0416C7.79158 12.2295 7.86621 12.4097 7.99905 12.5425C8.13189 12.6753 8.31206 12.75 8.49992 12.75C8.68778 12.75 8.86795 12.6753 9.00079 12.5425C9.13362 12.4097 9.20825 12.2295 9.20825 12.0416V9.91663C9.20825 9.72876 9.13362 9.5486 9.00079 9.41576C8.86795 9.28292 8.68778 9.20829 8.49992 9.20829ZM12.0416 6.37496V4.95829C12.0416 4.01898 11.6684 3.11815 11.0043 2.45396C10.3401 1.78976 9.43923 1.41663 8.49992 1.41663C7.56061 1.41663 6.65977 1.78976 5.99558 2.45396C5.33139 3.11815 4.95825 4.01898 4.95825 4.95829V6.37496C4.39467 6.37496 3.85416 6.59884 3.45565 6.99736C3.05714 7.39587 2.83325 7.93637 2.83325 8.49996V13.4583C2.83325 14.0219 3.05714 14.5624 3.45565 14.9609C3.85416 15.3594 4.39467 15.5833 4.95825 15.5833H12.0416C12.6052 15.5833 13.1457 15.3594 13.5442 14.9609C13.9427 14.5624 14.1666 14.0219 14.1666 13.4583V8.49996C14.1666 7.93637 13.9427 7.39587 13.5442 6.99736C13.1457 6.59884 12.6052 6.37496 12.0416 6.37496ZM6.37492 4.95829C6.37492 4.39471 6.5988 3.85421 6.99732 3.45569C7.39583 3.05718 7.93633 2.83329 8.49992 2.83329C9.0635 2.83329 9.60401 3.05718 10.0025 3.45569C10.401 3.85421 10.6249 4.39471 10.6249 4.95829V6.37496H6.37492V4.95829ZM12.7499 13.4583C12.7499 13.6462 12.6753 13.8263 12.5425 13.9592C12.4096 14.092 12.2294 14.1666 12.0416 14.1666H4.95825C4.77039 14.1666 4.59022 14.092 4.45738 13.9592C4.32455 13.8263 4.24992 13.6462 4.24992 13.4583V8.49996C4.24992 8.3121 4.32455 8.13193 4.45738 7.99909C4.59022 7.86625 4.77039 7.79163 4.95825 7.79163H12.0416C12.2294 7.79163 12.4096 7.86625 12.5425 7.99909C12.6753 8.13193 12.7499 8.3121 12.7499 8.49996V13.4583Z"
      fill="#7D8793"
    />
  </svg>
);

export const AgencyInfoIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875ZM0.625 7.5C0.625 3.70304 3.70304 0.625 7.5 0.625C11.297 0.625 14.375 3.70304 14.375 7.5C14.375 11.297 11.297 14.375 7.5 14.375C3.70304 14.375 0.625 11.297 0.625 7.5ZM7.5 6.875C7.84518 6.875 8.125 7.15482 8.125 7.5V10C8.125 10.3452 7.84518 10.625 7.5 10.625C7.15482 10.625 6.875 10.3452 6.875 10V7.5C6.875 7.15482 7.15482 6.875 7.5 6.875ZM7.5 4.375C7.15482 4.375 6.875 4.65482 6.875 5C6.875 5.34518 7.15482 5.625 7.5 5.625H7.50625C7.85143 5.625 8.13125 5.34518 8.13125 5C8.13125 4.65482 7.85143 4.375 7.50625 4.375H7.5Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);
