import * as Types from '../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AgencyUserOrganisationFragment = { __typename?: 'Organisation', id: number, name?: string | null };

export type LoginAgencyUserFragment = { __typename?: 'User', email?: string | null, firstName?: string | null, id: string, lastName?: string | null };

export type AgencyUserLoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type AgencyUserLoginMutation = { __typename?: 'Mutation', agencyUserLogin?: { __typename?: 'NonAuthorisedUser', refreshToken?: string | null, token?: string | null, user?: { __typename?: 'User', email?: string | null, firstName?: string | null, id: string, lastName?: string | null } | null } | null };

export type AgencyUserRefreshTokenMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String'];
}>;


export type AgencyUserRefreshTokenMutation = { __typename?: 'Mutation', agencyUserRefreshToken?: { __typename?: 'NonAuthorisedAgencyUser', refreshToken?: string | null, token?: string | null } | null };

export const AgencyUserOrganisationFragmentDoc = gql`
    fragment AgencyUserOrganisation on Organisation {
  id
  name
}
    `;
export const LoginAgencyUserFragmentDoc = gql`
    fragment LoginAgencyUser on User {
  email
  firstName
  id
  lastName
}
    `;
export const AgencyUserLoginDocument = gql`
    mutation AgencyUserLogin($email: String!, $password: String!) {
  agencyUserLogin(email: $email, password: $password) {
    refreshToken
    token
    user {
      ...LoginAgencyUser
    }
  }
}
    ${LoginAgencyUserFragmentDoc}`;
export type AgencyUserLoginMutationFn = Apollo.MutationFunction<AgencyUserLoginMutation, AgencyUserLoginMutationVariables>;

/**
 * __useAgencyUserLoginMutation__
 *
 * To run a mutation, you first call `useAgencyUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserLoginMutation, { data, loading, error }] = useAgencyUserLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAgencyUserLoginMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserLoginMutation, AgencyUserLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserLoginMutation, AgencyUserLoginMutationVariables>(AgencyUserLoginDocument, options);
      }
export type AgencyUserLoginMutationHookResult = ReturnType<typeof useAgencyUserLoginMutation>;
export type AgencyUserLoginMutationResult = Apollo.MutationResult<AgencyUserLoginMutation>;
export type AgencyUserLoginMutationOptions = Apollo.BaseMutationOptions<AgencyUserLoginMutation, AgencyUserLoginMutationVariables>;
export const AgencyUserRefreshTokenDocument = gql`
    mutation AgencyUserRefreshToken($refreshToken: String!) {
  agencyUserRefreshToken(refreshToken: $refreshToken) {
    refreshToken
    token
  }
}
    `;
export type AgencyUserRefreshTokenMutationFn = Apollo.MutationFunction<AgencyUserRefreshTokenMutation, AgencyUserRefreshTokenMutationVariables>;

/**
 * __useAgencyUserRefreshTokenMutation__
 *
 * To run a mutation, you first call `useAgencyUserRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyUserRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyUserRefreshTokenMutation, { data, loading, error }] = useAgencyUserRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAgencyUserRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<AgencyUserRefreshTokenMutation, AgencyUserRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AgencyUserRefreshTokenMutation, AgencyUserRefreshTokenMutationVariables>(AgencyUserRefreshTokenDocument, options);
      }
export type AgencyUserRefreshTokenMutationHookResult = ReturnType<typeof useAgencyUserRefreshTokenMutation>;
export type AgencyUserRefreshTokenMutationResult = Apollo.MutationResult<AgencyUserRefreshTokenMutation>;
export type AgencyUserRefreshTokenMutationOptions = Apollo.BaseMutationOptions<AgencyUserRefreshTokenMutation, AgencyUserRefreshTokenMutationVariables>;