import { Fragment, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BLUE, GLOBAL, GREY } from "@patchworkhealth/web-components";
import { SelectChevron } from "@patchworkhealth/web-components/icons";

import { RootState } from "models/store";

import NavLogo from "./NavLogo";
import { agencyUserMenuItems } from "./NavOptions";

const Navigation = () => {
  const user = useSelector((state: RootState) => state.user);

  const [dropdown, setDropdown] = useState({
    Workers: window.location.pathname.includes("workers"),
  });

  const onDropDownClick = (event) => {
    setDropdown((state) => ({
      ...state,
      [event.target.textContent]: !state[event.target.textContent],
    }));
  };

  const onLinkClick = (event) => {
    const subMenuName = event?.target?.pathname;
    setDropdown(() => ({
      Workers: subMenuName?.includes("/workers"),
    }));
  };

  return (
    <NavigationWrapper>
      <NavLogoContainer>
        <Link to="/">
          <NavLogo />
        </Link>
      </NavLogoContainer>
      <Navbar>
        <NavContainer>
          {agencyUserMenuItems.map((navItem) => {
            if (navItem.canManageUsers && user.canManageUsers === false) {
              return null;
            }

            return (
              <Fragment key={navItem.id}>
                <NavBtn
                  $dropdown={dropdown[navItem.name]}
                  $isDropdown={navItem.dropdown}
                  $isMainBtn={navItem.main}
                  as={!navItem.subMenu && Link}
                  className={
                    window.location.pathname.includes(navItem.link)
                      ? "active"
                      : navItem.link === "/shifts" &&
                        window.location.pathname === "/"
                      ? "active"
                      : ""
                  }
                  onClick={navItem.subMenu ? onDropDownClick : onLinkClick}
                  to={navItem.link ?? window.location.pathname}
                >
                  <>
                    {navItem.icon}
                    <span style={{ paddingLeft: "10px" }}>{navItem.name}</span>
                  </>
                  {navItem.subMenu && (
                    <div className="selectChevron">
                      <SelectChevron size={10} />
                    </div>
                  )}
                </NavBtn>
                {navItem.subMenu && (
                  <DropdownContainer
                    $dropdown={dropdown[navItem.name]}
                    $isActive={
                      window.location.pathname.includes(navItem.link) &&
                      "active"
                    }
                  >
                    {navItem.subMenu.map((subMenuItem) => {
                      return (
                        <NavBtn
                          key={subMenuItem.id}
                          as={Link}
                          className={
                            window.location.pathname === subMenuItem.link &&
                            "active"
                          }
                          onClick={onLinkClick}
                          to={subMenuItem.link}
                        >
                          <span>{subMenuItem.name}</span>
                        </NavBtn>
                      );
                    })}
                  </DropdownContainer>
                )}
              </Fragment>
            );
          })}



<p style={{
            border: "1px solid #D1F2F7",
            backgroundColor: "lightgreen",
            padding: 12,
            fontSize: "9px",
            textAlign: "center",
            fontWeight: 500,
            marginTop: 20
          }}> Scheduled Upgrade to the Patchwork Agency Manager
          portal,  Friday 28th June.</p>
  
        </NavContainer>
    
      </Navbar>
   
    </NavigationWrapper>
  );
};

export default Navigation;

const NavigationWrapper = styled.div`
  background: white;
  bottom: 0;
  box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
  width: 180px;
  z-index: 5;

  .navbar {
    flex-flow: wrap;
    padding: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;

const NavLogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding-left: 16px;

  svg {
    height: 36px;
    width: auto;
  }
`;

const NavContainer = styled(Nav)`
  display: flex;
  flex-direction: column !important;
  /* margin-left: -8px; */
  /* margin-right: -8px; */
  /* width: calc(100% + 16px); */
  width: 100%;
`;

const NavBtn = styled.button<{
  $isMainBtn?: boolean;
  $dropdown?: boolean;
  $isDropdown?: boolean;
}>`
  align-items: center;
  border-bottom-right-radius: ${({ $isDropdown }) =>
    $isDropdown ? "0" : "16px"};
  border-top-right-radius: ${({ $isDropdown }) => ($isDropdown ? "0" : "16px")};
  color: ${GREY.seven};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  justify-content: flex-start;
  line-height: 1;
  margin-bottom: 10px;
  margin-right: ${({ $isDropdown }) => ($isDropdown ? "0" : "12px")};
  padding-left: 16px;
  position: relative;

  &:hover {
    color: ${GREY.seven};
    text-decoration: none;
  }

  span {
    padding: 4px 0;
    pointer-events: none;
  }

  &.active {
    background: ${({ $isMainBtn }) =>
      $isMainBtn === true ? BLUE.one : "transparent"};
    color: ${GLOBAL.darkBlue};
  }

  .selectChevron {
    position: absolute;
    right: 30px;
    top: 8px;
    svg {
      fill: none !important;
      pointer-events: none;
      transform: ${({ $dropdown }) =>
        $dropdown ? "rotate(180deg)" : "rotate(0deg)"};
      transition: all 0.35s;
    }
  }

  img {
    display: flex;
    pointer-events: none;
    position: absolute;
    right: 16px;
    transform: ${({ $dropdown }) =>
      $dropdown ? "rotate(180deg)" : "rotate(0deg)"};
    transition: all 0.35s;
    width: 10px;
  }
`;

const DropdownContainer = styled.div<{
  $isActive: string;
  $isMainBtn?: boolean;
  $dropdown?: boolean;
}>`
  background: ${({ $isActive }) =>
    $isActive ? "rgba(209, 242, 247, 0.5)" : "transparent"};
  margin-bottom: 10px;
  margin-top: -10px;
  max-height: ${({ $dropdown }) => ($dropdown ? "200px" : "0")};
  overflow: hidden;
  transition: all 0.35s;
  width: 100%;

  ${NavBtn} {
    margin: 0;
    margin-bottom: 5px;
    margin-top: 5px;

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }
`;
