import * as Types from '../../baseCODEGEN';

import { gql } from '@apollo/client';
export type ErrorFragment = { __typename?: 'ValidationError', attribute?: string | null, message: string };

export type PageInfoFragment = { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null };

export type LoggedInAgencyUserFragment = { __typename?: 'AgencyUser', canCreateAgencyWorkers?: boolean | null, canManageUsers?: boolean | null, email?: string | null, firstName?: string | null, id: string, jobTitle?: string | null, lastName?: string | null, suspended?: boolean | null, uuid?: string | null, agency?: { __typename?: 'Agency', id: string, shortTitle?: string | null, title?: string | null, organisations?: Array<{ __typename?: 'Organisation', logoUrl?: string | null, usesDeSoleTrader: boolean, hideDeLtdAgencyWorkers: boolean, label?: string | null, value: number }> | null } | null, agencyUserOrganisations?: Array<{ __typename?: 'AgencyUserOrganisation', id: string, organisation?: { __typename?: 'Organisation', logoUrl?: string | null, label?: string | null, value: number, departments: Array<{ __typename?: 'Department', id: number, name?: string | null }>, grades?: Array<{ __typename?: 'Grade', id: string, title: string }> | null } | null }> | null, agencyUserPermissions?: Array<{ __typename?: 'AgencyUserPermission', organisationStaffGroup: { __typename?: 'OrganisationStaffGroup', organisation?: { __typename?: 'Organisation', id: number, logoUrl?: string | null, name?: string | null, usesDeSoleTrader: boolean, hideDeLtdAgencyWorkers: boolean, departments: Array<{ __typename?: 'Department', id: number, name?: string | null }>, grades?: Array<{ __typename?: 'Grade', id: string, title: string }> | null } | null } }> | null };

export type DepartmentFragment = { __typename?: 'Department', id: number, name?: string | null };

export type OrganisationAgencyFragment = { __typename?: 'OrganisationAgency', id: string, canAssignWorker: boolean, canProposeWorker: boolean, organisationStaffGroup?: { __typename?: 'OrganisationStaffGroup', id: string, title?: string | null, vms?: boolean | null } | null };

export const ErrorFragmentDoc = gql`
    fragment Error on ValidationError {
  attribute
  message
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;
export const LoggedInAgencyUserFragmentDoc = gql`
    fragment LoggedInAgencyUser on AgencyUser {
  canCreateAgencyWorkers
  canManageUsers
  email
  firstName
  id
  jobTitle
  lastName
  suspended
  uuid
  agency {
    id
    shortTitle
    title
    organisations {
      label: name
      logoUrl
      value: id
      usesDeSoleTrader
      hideDeLtdAgencyWorkers
    }
  }
  agencyUserOrganisations {
    id
    organisation {
      label: name
      logoUrl
      value: id
      departments {
        id
        name
      }
      grades {
        id
        title
      }
    }
  }
  agencyUserPermissions {
    organisationStaffGroup {
      organisation {
        id
        logoUrl
        name
        usesDeSoleTrader
        hideDeLtdAgencyWorkers
        departments {
          id
          name
        }
        grades {
          id
          title
        }
      }
    }
  }
}
    `;
export const DepartmentFragmentDoc = gql`
    fragment Department on Department {
  id
  name
}
    `;
export const OrganisationAgencyFragmentDoc = gql`
    fragment OrganisationAgency on OrganisationAgency {
  id
  canAssignWorker
  canProposeWorker
  organisationStaffGroup {
    id
    title
    vms
  }
}
    `;