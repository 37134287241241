/* eslint-disable no-alert */
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

import {
  Pagination,
  TableContainer,
  TableDeleteButton,
  TableEditButton,
} from "@patchworkhealth/web-components";

import StatusLabel from "components/StatusLabel";
import { PageInfoFragment } from "helpers/__generated__/fragments.generated";
import { FormatError } from "helpers/functions";
import { PaginationType } from "helpers/types";

import {
  GetAgencyWorkersQuery,
  VmsAgencyRegistrationDeleteAgencyRegistrationDocument,
  VmsAgencyRegistrationRecoverAgencyRegistrationDocument,
} from "./__generated__/AgencyWorkersHelpers.generated";

type AgencyWorkersTableProps = {
  agencyWorkers: GetAgencyWorkersQuery;
  loading: Boolean;
  pageInfo: PageInfoFragment | "";
  pagination: PaginationType;
  setPagination: Function;
  // setView: Function;
  setShowAgencyWorker: Function;
  activeWorker: Boolean;
  trashed: Boolean;
  refetch: Function;
};

const AgencyWorkersTable = ({
  agencyWorkers,
  loading,
  pageInfo,
  pagination,
  setPagination,
  setShowAgencyWorker,
  // setView,
  activeWorker,
  refetch,
  trashed,
}: AgencyWorkersTableProps) => {
  const [agencyWorkerDelete] = useMutation(
    VmsAgencyRegistrationDeleteAgencyRegistrationDocument
  );
  const [agencyWorkerRestore] = useMutation(
    VmsAgencyRegistrationRecoverAgencyRegistrationDocument
  );

  const deleteWorkerAction = async (worker) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this worker?"
    );
    if (shouldDelete) {
      const toastId = toast.loading("Deleting Agency Worker...");
      try {
        const { data: result } = await agencyWorkerDelete({
          variables: {
            agencyRegistrationId: Number(worker.id),
          },
        });

        const errors =
          result?.vmsAgencyRegistrationDeleteAgencyRegistration?.errors;

        if (errors.length) {
          toast.error(FormatError(errors), { id: toastId });
          return;
        }

        toast.success("Agency Worker deleted.", { id: toastId });
        refetch();
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  const restoreWorkerAction = async (worker) => {
    const toastId = toast.loading("Restoring Agency Worker...");
    try {
      const { data: result } = await agencyWorkerRestore({
        variables: {
          agencyRegistrationId: Number(worker.id),
        },
      });

      const errors =
        result?.vmsAgencyRegistrationRecoverAgencyRegistration?.errors;

      if (errors.length) {
        toast.error(FormatError(errors), { id: toastId });
        return;
      }

      toast.success("Agency Worker restored.", { id: toastId });
      refetch();
    } catch (err) {
      toast.error("An error occurred." + err, { id: toastId });
    }
  };

  return (
    <>
      <TableContainer loading={loading}>
        <thead>
          <tr>
            {/* <th>Id</th> */}

            <th>Name</th>
            {/* <th>Last Name</th> */}
            {!activeWorker && <th>Worker Status</th>}
            <th>Staff Group</th>
            <th>Employment Type</th>
            <th>Payment Method</th>
            <th>Grade</th>
            <th>Reg. Body No.</th>
            {!activeWorker && <th>Email</th>}
            <th></th>
          </tr>
        </thead>

        {/* 
  
    Pending: 
    -  worker has not accepted invite - not active
    -  worker has accepted invite, but has not joined agency on WW - not active
    -

    Active
    - Worker has accepted Invite + joined Agency on WW
    3123113 
  
  */}

        <tbody data-testid="workers">
          {agencyWorkers?.vmsAgencyRegistrations.edges
            .filter((row) => row.node.user)
            .map(({ node: agencyWorker }) => (
              <tr key={agencyWorker.id}>
                <th data-testid="users__name">
                  <button
                    onClick={() => setShowAgencyWorker(agencyWorker)}
                    style={{ maxWidth: 250 }}
                  >
                    {agencyWorker.user?.firstName} {agencyWorker.user?.lastName}
                  </button>
                </th>

                {!activeWorker && (
                  <th style={{ minWidth: 220 }}>
                    {agencyWorker.user.active ? (
                      <div>
                        Worker has created account, but not joined Agency{" "}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "red",
                          padding: 1,
                        }}
                      >
                        Worker needs to accept email invite
                      </div>
                    )}
                  </th>
                )}

                <td data-cy="tableStaffGroup">
                  {agencyWorker.staffGroup?.title}
                </td>

                {/* Employment Method ---------------------------------------- */}
                <td data-cy="tableEmploymentType">
                  {agencyWorker.employmentType === "direct_engagement"
                    ? "Direct Engagement"
                    : "Non-Direct Engagement"}
                </td>

                {/* PAYMENT Method ---------------------------------------- */}
                <td data-cy="tablePaymentType">
                  {agencyWorker.paymentType === "paye"
                    ? "PAYE"
                    : agencyWorker.paymentType === "umbrella_company"
                    ? "Umbrella Company"
                    : agencyWorker.isSoleTrader
                    ? "Sole Trader"
                    : "Ltd"}
                </td>

                <td
                  className="no-wrap"
                  data-cy="tableGrade"
                  style={{
                    fontWeight: 600,
                    color: agencyWorker?.grade?.colour,
                    width: 40,
                    maxWidth: 300,
                  }}
                >
                  {agencyWorker?.grade?.title}
                </td>

                <td data-cy="tableRegBodyNumber">
                  {agencyWorker.user?.regBodyNumber}
                </td>

                {!activeWorker && (
                  <td data-cy="emailSent">
                    {agencyWorker.invitedAt ? (
                      <StatusLabel status="sent" />
                    ) : (
                      <StatusLabel status="not sent" />
                    )}
                  </td>
                )}

                {trashed ? (
                  <td>
                    <TableEditButton
                      onClick={() => restoreWorkerAction(agencyWorker)}
                    >
                      Restore
                    </TableEditButton>
                  </td>
                ) : (
                  <td>
                    <TableDeleteButton
                      onClick={() => deleteWorkerAction(agencyWorker)}
                    >
                      Delete
                    </TableDeleteButton>
                  </td>
                )}
                {/* <td style={{ minWidth: !activeWorker ? 130 : 60 }}>
                  {!activeWorker ? (
                    <>
                      <TableEditButton
                        onClick={() => {
                          setView(true);
                          setShowAgencyWorker(agencyWorker);
                        }}
                      >
                        <EmailIcon />
                      </TableEditButton>
                      <TableEditButton
                        onClick={() => setShowAgencyWorker(agencyWorker)}
                        style={{ marginLeft: activeWorker && 5 }}
                      >
                        <EditIcon />
                      </TableEditButton>
                    </>
                  ) : (
                    <button
                      onClick={() => setShowAgencyWorker(agencyWorker)}
                      style={{ maxWidth: 400, textDecoration: "underline" }}
                    >
                      View
                    </button>
                  )}
                </td> */}
              </tr>
            ))}
        </tbody>
      </TableContainer>
      <Pagination
        pageInfo={pageInfo}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default AgencyWorkersTable;

// const EmailIcon = () => (
//   <svg
//     data-cy="sendEmail"
//     fill="none"
//     height="24"
//     viewBox="0 0 24 24"
//     width="24"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       clipRule="evenodd"
//       d="M3.10661 5.55395C3.27199 5.22693 3.61207 5 4.00003 5H20C20.388 5 20.7281 5.22692 20.8935 5.55395L12 11.7793L3.10661 5.55395ZM1.00008 5.98213C0.99991 5.99232 0.999893 6.0025 1.00003 6.01268V18C1.00003 19.6523 2.34775 21 4.00003 21H20C21.6523 21 23 19.6523 23 18V6.01178M21 7.92065V18C21 18.5477 20.5477 19 20 19H4.00003C3.45232 19 3.00003 18.5477 3.00003 18V7.92066L11.4266 13.8192C11.7709 14.0603 12.2292 14.0603 12.5735 13.8192L21 7.92065ZM23 5.98323C22.9909 4.33861 21.6467 3 20 3H4.00003C2.35371 3 1.00976 4.33801 1.00008 5.98213"
//       fill="#7D8793"
//       fillRule="evenodd"
//     />
//   </svg>
// );

// const EditIcon = () => (
//   <svg
//     data-cy="editWorker"
//     fill="none"
//     height="20"
//     viewBox="0 0 20 20"
//     width="20"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M10 16.6667H17.5"
//       stroke="#7D8793"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       strokeWidth="2"
//     />
//     <path
//       d="M13.75 2.91666C14.0815 2.58514 14.5312 2.3989 15 2.3989C15.2321 2.3989 15.462 2.44462 15.6765 2.53346C15.891 2.6223 16.0858 2.75251 16.25 2.91666C16.4142 3.08081 16.5444 3.27569 16.6332 3.49017C16.722 3.70464 16.7678 3.93452 16.7678 4.16666C16.7678 4.39881 16.722 4.62868 16.6332 4.84316C16.5444 5.05763 16.4142 5.25251 16.25 5.41666L5.83333 15.8333L2.5 16.6667L3.33333 13.3333L13.75 2.91666Z"
//       stroke="#7D8793"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       strokeWidth="2"
//     />
//   </svg>
// );
