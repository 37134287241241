// @ts-nocheck
import { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import {
  DefaultButton,
  FormGrid,
  FormGroup,
  FormInput,
  GLOBAL,
  Loading,
  ModalFooter,
  ORANGE,
} from "@patchworkhealth/web-components";

import { CrossIcon } from "assets/icons/red-cross-icon";
import { useGetAgencyWorkersQuery } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";

import { AgencyShiftFragment } from "../../__generated__/AgencyShiftsHelpers.generated";
import { ShiftModalTitle } from "../ShiftModalComponents";
import {
  useVmsAgencyWorkerProposalCreateMutation,
  useVmsAgencyWorkerProposalDeleteMutation,
  useVmsAgencyWorkerProposalRatesQuery,
  useVmsAgencyWorkerProposalsQuery,
} from "./__generated__/ShiftModalProposeWorkersHelpers.generated";
import ShiftModalProposeLoadWorkers from "./ShiftModalProposeLoadWorkers";
import ShiftModalProposeWorker from "./ShiftModalProposeWorker";
import ShiftModalProposeWorkerSimple from "./ShiftModalProposeWorkerSimple";
import ShiftRates from "./ShiftModalRates";

type ShiftModalProposeWorkersProps = {
  handleCloseAction: Function;
  shift?: AgencyShiftFragment;
  setShowWorker: any;
  refetchWorkers: Function;
  readonly?: Boolean;
};

const ShiftModalProposeWorkers = ({
  handleCloseAction,
  shift,
  setShowWorker,
  refetchWorkers,
  readonly,
}: ShiftModalProposeWorkersProps) => {
  /* 1. State **************************************/
  const [proposedWorker, setProposedWorker] = useState<any>();
  const [workerName, setWorkerName] = useState<string>(undefined);

  const [rates, setRates] = useState<any>(
    shift?.agencyWorkerRates?.map((cc) => ({
      ...cc,
      agencyMarginDiff: 0,
      workerPayeRateDiff: 0,
      ltdUmbrellaRateDiff: 0,
      comparatorRateDiff: 0,
      workerBaseRateDiff: 0,
    })) || []
  );
  const [startingRates] = useState<any>(
    shift?.agencyWorkerRates?.map((cc) => ({
      agencyBreakdownOriginal: cc.agencyMarginBreakdown,
      comparatorBreakdownOriginal: cc.comparatorRateBreakdown,
      ltdBreakdownOriginal: cc.ltdUmbrellaRateBreakdown,
      workerPayeBreakdownOriginal: cc.workerPayeRateBreakdown,
    })) || []
  );

  const [skip, setSkip] = useState<boolean>(true);

  const [view, setView] = useState<number>(0);
  const [rateTab, setRateTab] = useState<number>(1);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCalculations, setShowCalculations] = useState<boolean>(false);

  /* GQL Scenarios --------------------------------
  
      we want to filter out sole trader IF organisatin does NOT use sole trader toggle
      if organisation USES toggle, then show non traders + traders.
  */

  const { data: vmsAgencyRegistrations, loading } = useGetAgencyWorkersQuery({
    skip: !shift,
    fetchPolicy: "cache-and-network",
    variables: {
      staffGroupId: Number(shift?.staffGroup?.idValue),
      searchTerm: workerName,
      hideSoleTraders: !shift.organisation.usesDeSoleTrader,
      hideLtdWorkers: shift.organisation.hideDeLtdAgencyWorkers,
    },
  });

  /* Call for revised worker rates ********** */
  const { data: getAgencyProposalRates } = useVmsAgencyWorkerProposalRatesQuery(
    {
      skip,
      variables: {
        shiftId: Number(shift.id),
        agencyRegistrationId: Number(proposedWorker?.id),
        revisedRates: rates.map((rate) => ({
          id: rate.id,
          agencyMargin: Number(rate.agencyMargin),
          comparatorRate:
            proposedWorker?.employmentType !== "direct_engagement" ||
            (proposedWorker?.employmentType === "direct_engagement" &&
              proposedWorker?.paymentType === "ltd")
              ? Number(rate.comparatorRate)
              : null,
          workerRate: Number(
            proposedWorker?.paymentType === "paye"
              ? rate.workerPayeRate
              : rate.ltdUmbrellaRate
          ),
        })),
      },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: async () => {
        setSkip(true);

        let newArray = getAgencyProposalRates.vmsAgencyWorkerProposalRates.map(
          (cc) => ({
            ...cc,
            agencyMarginDiff: +(
              cc.agencyMargin - cc.initialRates.agencyMargin
            ).toFixed(2),
            workerPayeRateDiff: +(
              cc.workerPayeRate - cc.initialRates.workerPayeRate
            ).toFixed(2),
            ltdUmbrellaRateDiff: +(
              cc.ltdUmbrellaRate - cc.initialRates.ltdUmbrellaRate
            ).toFixed(2),
            comparatorRateDiff: +(
              cc.comparatorRate - cc.initialRates.comparatorRate
            ).toFixed(2),
            workerBaseRateDiff: +(
              cc.workerBaseRate - cc.initialRates.workerBaseRate
            ).toFixed(2),
          })
        );

        setRates(newArray);
      },
      onError: () => {
        setSkip(true);
      },
    }
  );

  /* 3. Functions **************************************/
  const SubmitProposeWorker = async () => {
    let submit = [];

    rates.forEach((cc) => {
      if (cc.errors.length > 0) {
        submit.push(1);
      }
    });

    if (submit.length > 0) {
      toast.error("You still have errors in the rate cards.");
      return;
    }

    if (rates.length > 1) {
      if (
        !window.confirm(
          "Shift has multiple Rate tabs, are you sure you want to submit?"
        )
      ) {
        return;
      }
    }
    const toastId = toast.loading("Proposing Worker...");

    try {
      const { data: result } = await vmsAgencyWorkerProposalCreate({
        variables: {
          shiftId: shift.id,
          agencyWorkerId: Number(proposedWorker?.id),
          shiftPayElements: rates.map((cc) => ({
            workerBaseRate: cc.workerBaseRate,
            workerRate:
              paymentType === "paye" ? cc.workerPayeRate : cc.ltdUmbrellaRate,
            comparatorRate: cc.comparatorRate,
            agencyMargin: cc.agencyMargin,
            id: cc.id,
          })),
        },
      });

      const errors = result?.vmsAgencyWorkerProposalCreate?.errors;

      if (errors.length > 0) {
        if (errors[0].message === "is already working at this time") {
          toast.error(
            `${proposedWorker.user.firstName} ${proposedWorker.user.lastName} is already working at this time`,
            { id: toastId }
          );
        } else {
          toast.error(`${errors[0].message}`, { id: toastId });
        }
        return;
      } else {
        toast.success("Agency Worker Proposed.", { id: toastId });
        setView(0);
        setRates([]);
        setRateTab(1);
        setShowCalculations(false);
        refetchWorkers();
      }
    } catch (err) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const setProposeWorkerAction = (agencyWorker, ratesArray) => {
    setProposedWorker(agencyWorker);
    /*
        When viewing existing proposal we pass 'ratesArray' param
        we will loop through and compare against initial rates to create '+£' cards
    */

    if (ratesArray) {
      let newArray = ratesArray.map((cc) => ({
        ...cc,
        agencyMarginDiff: +(
          cc.agencyMargin - cc.initialRates.agencyMargin
        ).toFixed(2),
        workerPayeRateDiff: +(
          cc.workerPayeRate - cc.initialRates.workerPayeRate
        ).toFixed(2),
        ltdUmbrellaRateDiff: +(
          cc.ltdUmbrellaRate - cc.initialRates.ltdUmbrellaRate
        ).toFixed(2),
        comparatorRateDiff: +(
          cc.comparatorRate - cc.initialRates.comparatorRate
        ).toFixed(2),
        workerBaseRateDiff: +(
          cc.workerBaseRate - cc.initialRates.workerBaseRate
        ).toFixed(2),
      }));

      setRates(newArray);
    } else {
      setSkip(false);
    }

    setView(1);
  };

  const [vmsAgencyWorkerProposalCreate] =
    useVmsAgencyWorkerProposalCreateMutation({
      refetchQueries: ["VmsAgencyWorkerProposals"],
    });

  const [vmsAgencyWorkerProposalDelete] =
    useVmsAgencyWorkerProposalDeleteMutation({
      refetchQueries: ["VmsAgencyWorkerProposals"],
    });

  // Revoke ************
  const proposalDeleteAction = async (proposal) => {
    const toastId = toast.loading("Revoking proposal...");
    try {
      const { data: result } = await vmsAgencyWorkerProposalDelete({
        variables: {
          agencyWorkerProposalId: Number(proposal?.id),
        },
      });

      if (result?.vmsAgencyWorkerProposalDelete?.errors?.length) {
        toast.error("An error occurred.", { id: toastId });
      } else {
        toast.success("Proposal revoked.", { id: toastId });
        refetchWorkers();
      }
    } catch (err) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const { data: vmsAgencyWorkerProposals } = useVmsAgencyWorkerProposalsQuery({
    variables: {
      shiftId: Number(shift.id),
    },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const filteredAgencyRegistrations =
    vmsAgencyRegistrations?.vmsAgencyRegistrations?.edges.filter(function ({
      node: registration,
    }) {
      return !vmsAgencyWorkerProposals?.vmsAgencyWorkerProposals.find(function (
        proposal
      ) {
        return registration.id === proposal.agencyWorker.id;
      });
    });

  // Loop through all the rates and work out Totals - Potentially swap this out for BE later
  const paymentType = proposedWorker?.paymentType;
  const employmentType = proposedWorker?.employmentType;

  let totalComparitor = 0;
  let totalMargin = 0;

  rates.forEach((total) => {
    totalComparitor += +(total.comparatorRate * total.hours).toFixed(2);
    totalMargin += +(total.agencyMargin * total.hours).toFixed(2);
  });

  const rateCardCalculator = (rate) => {
    return (
      <RateCardChange data-cy="rate_change">
        {rate > 0 ? `+ £${rate.toFixed(2)}` : `£ ${rate.toFixed(2)}`}
      </RateCardChange>
    );
  };

  let canUserSubmit =
    rateTab === rates.length + 1 && rates.length > 0 ? true : false;

  const isProduction = window.location.hostname.match(
    /agency.patchwork.health/
  );

  console.log("isProduction", isProduction);

  const canBeProposed = shift.agencyRateCardAttached;

  return (
    <>
      {canBeProposed && (
        <div>
          {readonly && <ShiftModalTitle>Booking Confirmed</ShiftModalTitle>}

          {!readonly && (
            <ShiftModalTitle>Propose Agency Worker for Shift</ShiftModalTitle>
          )}

          {vmsAgencyWorkerProposals?.vmsAgencyWorkerProposals.length > 0 &&
            view === 0 && (
              <ProposeWorkersProposalsContainer>
                {vmsAgencyWorkerProposals?.vmsAgencyWorkerProposals.map(
                  (proposal) => (
                    <ShiftModalProposeWorker
                      key={proposal.id}
                      proposal={proposal}
                      proposeWorkerAction={setProposeWorkerAction}
                      proposeWorkerDeleteAction={proposalDeleteAction}
                      readonly={readonly}
                      setShowWorker={setShowWorker}
                      shift={shift}
                    />
                  )
                )}
              </ProposeWorkersProposalsContainer>
            )}

          {!vmsAgencyWorkerProposals?.vmsAgencyWorkerProposals.length &&
            shift?.agencyBooking?.agencyWorker &&
            view === 0 && (
              <ShiftModalProposeWorker
                key={shift.agencyBooking.agencyWorker.id}
                proposal={{ agencyWorker: shift.agencyBooking.agencyWorker }}
                proposeWorkerAction={setProposeWorkerAction}
                proposeWorkerDeleteAction={proposalDeleteAction}
                readonly={readonly}
                setShowWorker={setShowWorker}
                shift={shift}
              />
            )}

          {!readonly && (
            <p style={{ fontSize: 13 }}>
              <strong>
                To propose another worker, please search or select them below:
              </strong>
            </p>
          )}
          {view === 0 && !readonly && (
            <FormGrid columns={2} style={{ marginBottom: 16 }}>
              <FormGroup>
                <FormInput
                  defaultValue={workerName}
                  name="workerName"
                  onChange={(e) => setWorkerName(e.target.value)}
                  placeholder="Type a name..."
                  type="text"
                />
              </FormGroup>
            </FormGrid>
          )}
          <ProposeWorkersContainer>
            {view === 0 && !readonly && (
              <ShiftModalProposeLoadWorkers
                proposeWorkerAction={setProposeWorkerAction}
                setShowWorker={setShowWorker}
                shift={shift}
                vmsAgencyRegistrations={filteredAgencyRegistrations}
              />
            )}

            {loading && <Loading />}

            {view === 1 && !readonly && (
              <ShiftModalProposeWorkerSimple
                proposedWorker={proposedWorker}
                setShowWorker={setShowWorker}
                shift={shift}
              />
            )}
          </ProposeWorkersContainer>
          {view === 1 && (
            <ShiftRates
              employmentType={employmentType}
              paymentType={paymentType}
              proposedWorker={proposedWorker}
              rateCardCalculator={rateCardCalculator}
              rateTab={rateTab}
              rates={rates}
              readOnly={false}
              setRateTab={setRateTab}
              setRates={setRates}
              setShowCalculations={setShowCalculations}
              setShowPopup={setShowPopup}
              setSkip={setSkip}
              showCalculations={showCalculations}
              showPopup={showPopup}
              totalComparitor={totalComparitor}
              totalMargin={totalMargin}
            />
          )}
        </div>
      )}
      {!canBeProposed && (
        <div>
          {!readonly && (
            <ShiftModalTitle>Propose Agency Worker for Shift</ShiftModalTitle>
          )}
          <NoRateCardContainer>
            <CrossIcon />
            <NoRateCardTextContainer>
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                No rate card assigned
              </span>
              This shift cannot accept proposals as there is currently no rate
              card assigned to the {shift.grade.title} grade.
              <br />
              <br />
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Please contact an admin at {shift.organisation.name} to resolve
                the issue.
              </span>
            </NoRateCardTextContainer>
          </NoRateCardContainer>
        </div>
      )}
      <ModalFooter
        style={{
          margin: 0,
          marginTop: 32,
          minHeight: 76,
          paddingBottom: 0,
          position: "absolute",
          left: 32,
          right: 32,
          bottom: 24,
        }}
      >
        <div>
          {view === 1 && !proposedWorker?.readonly && (
            <DefaultButton
              action={() => {
                let updatedRates = rates.map((cc, index) => {
                  return index + 1 === rateTab
                    ? {
                        ...cc,
                        errors: [],
                        workerBaseRateDiff: 0,
                        workerPayeRateDiff: 0,
                        comparatorRateDiff: 0,
                        agencyMarginDiff: 0,
                        ltdUmbrellaRateDiff: 0,
                        workerBaseRate: cc.initialRates.workerBaseRate,
                        workerPayeRate: cc.initialRates.workerPayeRate,
                        comparatorRate: cc.initialRates.comparatorRate,
                        agencyMargin: cc.initialRates.agencyMargin,
                        ltdUmbrellaRate: cc.initialRates.ltdUmbrellaRate,
                        comparatorRateBreakdown:
                          startingRates[index]?.comparatorBreakdownOriginal,
                        agencyMarginBreakdown:
                          startingRates[index]?.agencyBreakdownOriginal,
                        workerPayeRateBreakdown:
                          startingRates[index]?.workerPayeBreakdownOriginal,
                        ltdUmbrellaRateBreakdown:
                          startingRates[index]?.ltdBreakdownOriginal,
                      }
                    : {
                        ...cc,
                      };
                });
                setRates(updatedRates);
              }}
              text="Reset Rate Card Values"
              type="grey"
            />
          )}
        </div>
        <div>
          <DefaultButton
            action={
              view === 0
                ? () => {
                    setRateTab(1);
                    handleCloseAction();
                  }
                : () => {
                    setView(0);
                    setRateTab(1);
                  }
            }
            text="Back"
            type="white"
          />
          {/* {rates.length > 0 &&  */}

          {isProduction &&
            rates.length > 0 &&
            view === 1 &&
            !proposedWorker?.readonly && (
              <DefaultButton
                action={SubmitProposeWorker}
                color="blue"
                data-cy="confirm_propose_worker"
                disabled={!canUserSubmit}
                style={{ opacity: canUserSubmit ? 1 : 0.2 }}
                text="Confirm Propose Worker"
              />
            )}

          {!isProduction && view === 1 && !proposedWorker?.readonly && (
            <DefaultButton
              action={SubmitProposeWorker}
              color="blue"
              data-cy="confirm_propose_worker"
              disabled={!canUserSubmit}
              style={{ opacity: canUserSubmit ? 1 : 0.2 }}
              text="Confirm Propose Worker"
            />
          )}
        </div>
      </ModalFooter>
    </>
  );
};

export default ShiftModalProposeWorkers;

const ProposeWorkersContainer = styled.div`
  max-height: 438px;
  overflow: auto;
`;

const ProposeWorkersProposalsContainer = styled.div`
  border-bottom: 1px solid ${GLOBAL.borderGreyLight};
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

const RateCardChange = styled.h6`
  align-items: center;
  background: ${ORANGE.one};
  border-radius: 4px;
  color: #c54714;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  justify-content: center;
  min-width: 41px;
  padding: 2px 9px;
  position: absolute;
  right: 12px;
  top: 32px;
`;

const NoRateCardContainer = styled.div`
  align-content: start;
  background-color: #f5dde2;
  border: 1px solid #e6515d;
  border-radius: 6px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  height: 100%;
  line-height: 20px;
  padding: 12px 24px 12px 24px;
`;

const NoRateCardTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
`;
