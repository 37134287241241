import { useState } from "react";
import toast from "react-hot-toast";

import {
  DefaultButton,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormLabel,
  FormSelect,
} from "@patchworkhealth/web-components";

import { ModalCustomFooter } from "components/styles";
import { useReasonsForCancellationQuery } from "helpers/__generated__/queries.generated";
import { formatForReactSelect } from "helpers/functions";

import {
  ShiftBlockQuery,
  useVmsShiftBlockCancelAgencyBookingMutation,
} from "../__generated__/BlocksHelpers.generated";

const BlockModalBooking = ({
  block,
  refetchBlocks,
  setTab,
  handleClose,
}: BlockModalBookingProps) => {
  const [reason, setReason] = useState(null);

  const { data: reasonsForCancellation } = useReasonsForCancellationQuery({
    variables: {
      organisationId: Number(block?.organisation?.id),
    },
  });

  const [cancelAgencyBooking] = useVmsShiftBlockCancelAgencyBookingMutation();

  const cancelBooking = async () => {
    const toastId = toast.loading("Cancelling booking...");
    if (reason === null) {
      toast.error("Please select a reason.", { id: toastId });
      return;
    }

    try {
      const { data: result } = await cancelAgencyBooking({
        variables: {
          cancelAgencyBookingAttributes: {
            reasonForCancellationId: reason.value,
            shiftBlockId: Number(block.id),
          },
        },
      });

      if (result?.vmsShiftBlockCancelAgencyBooking?.errors?.length > 0) {
        toast.error("An error occurred.", { id: toastId });
        return;
      }
      toast.success("Booking cancelled.", { id: toastId });
      refetchBlocks();
      handleClose();
      setTab("details");
    } catch (err) {
      toast.error("An error occurred...", { id: toastId });
    }
  };

  return (
    <div>
      <FormGridContainer>
        <div
          style={{
            padding: 15,
            background: "#039ac6",
            border: 0,
            marginBottom: 25,
            textAlign: "center",
            fontWeight: 500,
            color: "white",
          }}
        >
          Only Visible To Patchworkers, If you cancel booking via the portal,
          please allow up to 90seconds & check block status via the hub. This
          will be until we implement web-socket support for portal.
        </div>
        <FormGrid columns={3} style={{ marginBottom: 16 }}>
          <FormGroup>
            <FormLabel>Cancellation Reason</FormLabel>
            <FormSelect
              onChange={(event) => setReason(event)}
              options={formatForReactSelect(
                reasonsForCancellation?.reasonsForCancellation
              )}
              placeholder="Please select a cancellation reason"
            />
          </FormGroup>
        </FormGrid>

        <ModalCustomFooter>
          <div />
          <div>
            <DefaultButton
              action={cancelBooking}
              color="red"
              text="Cancel Booking"
            />
          </div>
        </ModalCustomFooter>
      </FormGridContainer>
    </div>
  );
};

export default BlockModalBooking;

type BlockModalBookingProps = {
  block: ShiftBlockQuery["shiftBlocks"]["nodes"][number];
  setTab: Function;
  refetchBlocks: Function;
  handleClose: Function;
};
