import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

import {
  AuthContainer,
  AuthCopyrightText,
  AuthFormBackground,
  AuthLogoWrapper,
  AuthSocials,
  AuthWrapper,
  DefaultButton,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  GLOBAL,
} from "@patchworkhealth/web-components";

import { Logo } from "./login";

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(email: $email)
  }
`;

const ForgotPasswordPage = () => {
  useEffect(() => {
    document.title = "Forgotten Password - Patchwork";
  });

  const [forgotPasswordMutation, { loading }] = useMutation(
    FORGOT_PASSWORD_MUTATION
  );
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const forgot = (e) => {
    e.preventDefault();

    if (email === "") {
      setError("Please enter a valid email address.");
    } else {
      forgotPasswordMutation({
        variables: {
          email: email,
        },
      }).then((response) => {
        if (response.data === null || response.data === undefined) {
          // notify("danger", "Something went wrong, try again.");
        } else {
          // notify("success", "Password has been sent");
        }
      });
    }
  };

  return (
    <AuthWrapper background="linear-gradient(180deg, #002033 0%, #021520 100%), #002033">
      <Container>
        <AuthContainer>
          <AuthFormBackground>
            <AuthLogoWrapper>
              <Logo />
            </AuthLogoWrapper>
            <Form onSubmit={forgot}>
              <FormGridContainer style={{ marginBottom: 24 }}>
                <FormGrid columns={1} style={{ marginBottom: 8 }}>
                  <FormGroup>
                    <FormLabel style={{ color: GLOBAL.white }}>
                      Email Address
                    </FormLabel>
                    <FormInput
                      name="email"
                      onChange={(e) => {
                        setError(null);
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter email..."
                      required
                      type="email"
                    />
                    {error && <FormErrorLabel>{error}</FormErrorLabel>}
                  </FormGroup>
                </FormGrid>
              </FormGridContainer>
              <FormGridContainer style={{ marginBottom: 24 }}>
                <FormGroup>
                  <DefaultButton
                    action={() => null}
                    fontSize="14px"
                    text={loading ? "Loading..." : "Send Reset Link"}
                    type="green"
                    width="full"
                  />
                </FormGroup>
              </FormGridContainer>
              <FormGroup className="text-right">
                <Link style={{ color: GLOBAL.white }} to="/">
                  ← Return Home
                </Link>
              </FormGroup>
            </Form>
          </AuthFormBackground>
        </AuthContainer>
      </Container>
      <AuthSocials />
      <AuthCopyrightText>© 2021 Patchwork Health</AuthCopyrightText>
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
