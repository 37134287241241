import { createModel } from "@rematch/core";

import { RootModel } from "./models";
// import { AgencyUserOrganisationFragment } from "helpers/__generated__/mutations.generated";

export const organisations = createModel<RootModel>()({
  state: null as any,
  reducers: {
    storeOrganisations: (state, payload: any) => payload,
  },
});
