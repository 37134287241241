import dayjs from "dayjs";

import { Pagination, TableContainer } from "@patchworkhealth/web-components/";

import StatusLabel from "components/StatusLabel";
import { PageInfoFragment } from "helpers/__generated__/fragments.generated";
import { PaginationType } from "helpers/types";

import { ProposalsBadge } from "../StatusLabel";
import { GetAgencyShiftsQuery } from "./__generated__/AgencyShiftsHelpers.generated";

type AgencyShiftsTableProps = {
  agencyShifts: GetAgencyShiftsQuery["vmsShifts"]["edges"][number] | any;
  loading: Boolean;
  pageInfo: PageInfoFragment | "";
  pagination: PaginationType;
  setPagination: Function;
  setShowShift: Function;
  setShowWorker: Function;
};

const AgencyShiftsTable = ({
  agencyShifts,
  loading,
  pageInfo,
  pagination,
  setPagination,
  setShowShift,
  setShowWorker,
}: AgencyShiftsTableProps) => {
  const calculateType = (type) => {
    if (type === "ONCALL") {
      return "On Call";
    }

    if (type === "HOURLY") {
      return "Hourly";
    }

    return type;
  };

  return (
    <>
      <TableContainer loading={loading}>
        <thead data-cy="shift-table-header">
          <tr>
            <th>Id</th>
            <th>Date &amp; Hours</th>
            <th>Department</th>
            <th>Shift Grade</th>
            <th>Status</th>
            <th>Org</th>
            <th style={{ minWidth: 100 }}>Type</th>

            <th>Worker</th>
            <th>Date Sent to Agency</th>
          </tr>
        </thead>
        <tbody data-testid="shifts">
          {agencyShifts?.map((agencyShift) => (
            <tr key={agencyShift?.id}>
              <th data-cy="table_shift_id">
                <button onClick={() => setShowShift(agencyShift?.id)}>
                  <strong>{agencyShift?.id}</strong>
                </button>
              </th>
              <td className="no-wrap">
                <strong>
                  {dayjs(agencyShift?.startTime).format("DD MMM YY")}
                </strong>
                <br />
                {dayjs(agencyShift?.startTime).format("HH:mm")} -{" "}
                {dayjs(agencyShift?.endTime).format("HH:mm")}
              </td>
              <td className="no-wrap">
                <strong>{agencyShift?.department?.name}</strong>
                <br />
                {agencyShift?.site?.name}
              </td>

              <td
                style={{
                  fontWeight: 500,
                  color: agencyShift?.grade?.colour,
                  width: "40px !important",
                }}
              >
                {agencyShift?.grade?.title}
              </td>

              <td data-cy="table_shift_status">
                {agencyShift?.status === "AVAILABLE" &&
                agencyShift?.agencyWorkerProposals?.nodes.length > 0 ? (
                  <ProposalsBadge onClick={() => setShowShift(agencyShift?.id)}>
                    {agencyShift.agencyWorkerProposals?.nodes.length} Proposal
                    {agencyShift.agencyWorkerProposals?.nodes.length > 1
                      ? "s"
                      : null}
                  </ProposalsBadge>
                ) : (
                  <StatusLabel status={agencyShift?.status} />
                )}
              </td>
              <td>{agencyShift?.organisation?.name.slice(0, 28)}</td>
              <td
                style={{
                  fontWeight: "bold",
                  color: agencyShift.grade.colour,
                }}
              >
                {calculateType(agencyShift?.shiftType)}
              </td>

              <td className="no-wrap" style={{ lineHeight: "1.2" }}>
                <button
                  onClick={() =>
                    setShowWorker(agencyShift?.agencyBooking?.agencyWorker)
                  }
                >
                  <strong style={{ textDecoration: "underline" }}>
                    {agencyShift?.agencyBooking?.agencyWorker?.firstName}{" "}
                    {agencyShift?.agencyBooking?.agencyWorker?.lastName}
                  </strong>
                </button>
                <span
                  style={{
                    color:
                      agencyShift?.agencyBooking?.agencyWorker?.grade?.colour,
                    display: "block",
                    fontSize: 12,
                  }}
                >
                  {agencyShift?.agencyBooking?.agencyWorker?.grade?.title}
                </span>
              </td>
              <td style={{ fontSize: 15 }}>
                {dayjs(agencyShift?.dateSentToAgency).format("DD MMM YY")}
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
      <Pagination
        pageInfo={pageInfo}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default AgencyShiftsTable;
