import { createModel } from "@rematch/core";

import { RootModel } from "./models";

export const staffGroups = createModel<RootModel>()({
  state: [],
  reducers: {
    deleteStaffGroups: () => {
      return [];
    },
    storeStaffGroups: (state, payload) => {
      return [...state, ...payload];
    },
  },
});
