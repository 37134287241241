import { DefaultButton } from "@patchworkhealth/web-components";

import {
  ProposeWorkerContainer,
  ProposeWorkerDetails,
  ProposeWorkerDetailsAvatar,
  ProposeWorkerDetailsContainer,
  ProposeWorkerDetailsEmail,
  ProposeWorkerDetailsGrade,
  ProposeWorkerDetailsName,
  ProposeWorkerPaymentDetailsContainer,
  ProposeWorkerPaymentDetailsEmploymentLabel,
} from "components/AgencyShifts/ShiftModalComponents/ShiftModalProposeWorkers/ShiftModalProposeWorker";
import { AgencyRegistrationFragment } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";
import { defaultImgOnError } from "helpers/functions";

const BlockWorkerCard = ({
  setShowWorker,
  canUserEdit,
  viewWorkerAction,
  worker,
}: BlockWorkerCardProps) => {
  return (
    <ProposeWorkerContainer>
      <ProposeWorkerDetailsContainer>
        <ProposeWorkerDetailsAvatar
          alt="Profile Picture"
          onError={defaultImgOnError}
          src={`${process.env.REACT_APP_API}${worker?.user?.profilePictureUrl}`}
        />

        <ProposeWorkerDetails>
          <ProposeWorkerDetailsName onClick={() => setShowWorker(worker)}>
            {worker?.user?.fullName}
          </ProposeWorkerDetailsName>
          <ProposeWorkerDetailsGrade color={worker?.grade?.colour}>
            {worker?.grade?.title}
          </ProposeWorkerDetailsGrade>
          <ProposeWorkerDetailsEmail>
            {worker?.user?.email}
          </ProposeWorkerDetailsEmail>
        </ProposeWorkerDetails>
      </ProposeWorkerDetailsContainer>

      <ProposeWorkerPaymentDetailsContainer>
        <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_employment_type">
          {worker?.employmentType === "direct_engagement" ? "DE" : "NonDE"}
        </ProposeWorkerPaymentDetailsEmploymentLabel>
        <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_payment_type">
          {worker.paymentType === "paye" && "Paye"}
          {worker.paymentType === "umbrella_company" && "Umbrella"}

          {worker.paymentType === "ltd" && worker.isSoleTrader && "Sole Trader"}
          {worker.paymentType === "ltd" && !worker.isSoleTrader && "Ltd"}
        </ProposeWorkerPaymentDetailsEmploymentLabel>
      </ProposeWorkerPaymentDetailsContainer>

      {canUserEdit && (
        <DefaultButton
          action={() => viewWorkerAction(worker)}
          color="blue"
          data-testid={`propose_worker_${worker?.id}`}
          style={{ maxWidth: 125, width: "100%" }}
          text="Propose"
        />
      )}

      {!canUserEdit && <div />}
    </ProposeWorkerContainer>
  );
};

export default BlockWorkerCard;

type BlockWorkerCardProps = {
  setShowWorker: Function;
  canUserEdit: boolean;
  viewWorkerAction?: (worker: AgencyRegistrationFragment) => void;
  worker: AgencyRegistrationFragment;
};
