import { useCallback, useState } from "react";
import queryString from "query-string";

export const usePagination = (numberOfItems?: number) => {
  const [pagination, setPagination] = useState(() =>
    initPagination(numberOfItems)
  );

  const resetPagination = useCallback(() => {
    setPagination((s) => {
      window.history.replaceState(null, null, s.url);
      // reset url as we reset pagination
      return {
        ...s,
        after: undefined,
        before: undefined,
      };
    });
  }, []);

  return [pagination, setPagination, resetPagination] as const;
  //https://fettblog.eu/typescript-react-typeing-custom-hooks/#option-2%3A-as-const
};

export const initPagination = (numberOfItems?: number) => {
  const items = numberOfItems ?? 25; // per page
  let pageParams = queryString.parse(window.location.search);
  return {
    after: pageParams.afterPage as string | undefined,
    before: pageParams.beforePage as string | undefined,
    first: pageParams.afterPage
      ? items
      : pageParams.beforePage
      ? undefined
      : items,
    last: pageParams.beforePage ? items : undefined,
    items: items,
    url: window.location.pathname,
  };
};
