import { AgencyRegistrationFragment } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";

import { AgencyShiftFragment } from "../../__generated__/AgencyShiftsHelpers.generated";
import ShiftModalProposeWorker from "./ShiftModalProposeWorker";

// Props
type ShiftModalProposeLoadWorkersProps = {
  proposeWorkerAction: Function;
  vmsAgencyRegistrations: { node?: AgencyRegistrationFragment }[];
  shift: AgencyShiftFragment;
  setShowWorker: any;
};

const ShiftModalProposeLoadWorkers = ({
  proposeWorkerAction,
  vmsAgencyRegistrations,
  shift,
  setShowWorker,
}: ShiftModalProposeLoadWorkersProps) => {
  return (
    <div>
      {vmsAgencyRegistrations?.map(({ node: agencyWorker }) => {
        return (
          <ShiftModalProposeWorker
            key={agencyWorker.id}
            agencyWorker={agencyWorker}
            proposeWorkerAction={proposeWorkerAction}
            setShowWorker={setShowWorker}
            shift={shift}
          />
        );
      })}
    </div>
  );
};

export default ShiftModalProposeLoadWorkers;
