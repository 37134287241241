import styled from "styled-components";

import { GREY } from "@patchworkhealth/web-components";

import { AgencyShiftFragment } from "../__generated__/AgencyShiftsHelpers.generated";
import { Tab } from "../AgencyShiftsModal";

type ShiftModalSidebarProps = {
  shift?: AgencyShiftFragment;
  tab: Tab;
  setTab: React.Dispatch<React.SetStateAction<Tab>>;
};

const ShiftModalSidebar = ({ shift, tab, setTab }: ShiftModalSidebarProps) => {
  return (
    <ShiftModalSidebarContainer>
      <ShiftModalSidebarNav>
        {shift?.status === "BOOKED" && (
          <>
            <li>
              <ShiftModalSidebarNavOption
                active={tab === "cancel"}
                onClick={() => setTab("cancel")}
              >
                Cancel Booking
              </ShiftModalSidebarNavOption>
            </li>
            <ShiftModalSidebarMenuDivider />
          </>
        )}

        {shift?.availableForAgencyUserActions.includes("cancel_worker") && (
          <li>
            <ShiftModalSidebarNavOption
              active={tab === "viewWorker"}
              data-cy="modal_view_worker"
              onClick={() => setTab("viewWorker")}
            >
              View Booking
            </ShiftModalSidebarNavOption>
          </li>
        )}

        <li>
          <ShiftModalSidebarNavOption
            active={tab === "details"}
            onClick={() => setTab("details")}
          >
            Shift Details
          </ShiftModalSidebarNavOption>
        </li>
        {shift?.availableForAgencyUserActions.includes("propose_worker") && (
          <li>
            <ShiftModalSidebarNavOption
              active={tab === "proposeWorker"}
              data-cy="modal_propose_worker"
              onClick={() => setTab("proposeWorker")}
            >
              Propose Worker
            </ShiftModalSidebarNavOption>
          </li>
        )}
      </ShiftModalSidebarNav>
    </ShiftModalSidebarContainer>
  );
};

export default ShiftModalSidebar;

export const ShiftModalSidebarContainer = styled.div`
  background: #e4e7eb;
  border-bottom-left-radius: 16px;
  flex-shrink: 0;
  padding: 12px 8px;
  width: 185px;
`;

export const ShiftModalSidebarNav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ShiftModalSidebarNavOption = styled.button<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) => active && GREY.three};
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: ${({ active }) => active && "600"};
  height: 32px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: start;
  width: 100%;
`;

export const ShiftModalSidebarMenuDivider = styled.hr`
  border-color: grey;
  width: calc(100% - 16px);
`;
