import styled from "styled-components";

import { CloseButton, ModalHeader } from "@patchworkhealth/web-components";

import StatusLabel from "components/StatusLabel";

const ShiftModalHeader = ({ handleCloseAction, agencyWorker, inputs }) => {
  return (
    <ModalHeader style={{ height: 80 }}>
      <ShiftModalHeaderDetailsContainer>
        <ProposeWorkerDetailsAvatar
          src={`${process.env.REACT_APP_API}/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg`}
        />
        <ShiftModalHeaderTitle>
          {inputs?.firstName} {inputs?.lastName}{" "}
        </ShiftModalHeaderTitle>
      </ShiftModalHeaderDetailsContainer>

      {agencyWorker && (
        <ShiftModalHeaderDetailsContainer>
          {agencyWorker?.employmentType === "non_direct_engagement" ? (
            <StatusLabel status="Non-DE" style={{ marginRight: 20 }} />
          ) : (
            <StatusLabel status="DE" style={{ marginRight: 20 }} />
          )}
          {agencyWorker?.paymentType === "paye"
            ? "PAYE"
            : agencyWorker?.paymentType === "umbrella_company"
            ? "Umbrella Company"
            : agencyWorker?.isSoleTrader
            ? "Sole Trader"
            : "Ltd"}

          <CloseButton onClick={handleCloseAction} style={{ marginLeft: 32 }} />
        </ShiftModalHeaderDetailsContainer>
      )}

      {!agencyWorker && (
        <ShiftModalHeaderDetailsContainer>
          <CloseButton onClick={handleCloseAction} style={{ marginLeft: 32 }} />
        </ShiftModalHeaderDetailsContainer>
      )}
    </ModalHeader>
  );
};

export default ShiftModalHeader;

const ShiftModalHeaderDetailsContainer = styled.div`
  align-items: center;
  display: flex;

  img {
  }
`;

const ShiftModalHeaderTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const ProposeWorkerDetailsAvatar = styled.img`
  border-radius: 25px;
  height: 43px;
  margin-right: 16px;
  width: 43px;
`;
