import { useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  DateInput,
  DefaultButton,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormLabel,
  FormSelect,
  FormSelectMulti,
} from "@patchworkhealth/web-components";

import { useGetAgencyWorkersQuery } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";
import { useShiftDetailssQuery } from "helpers/__generated__/queries.generated";
import { formatForReactSelect } from "helpers/functions";
import { RootState } from "models/store";

const Filters = ({
  setFiltersInputs,
  filtersHandleFilter,
  filtersInputs,
  resetPagination,
}) => {
  const store = useSelector((s: RootState) => s);
  const stateOrg = useSelector((s: RootState) => s.organisation);

  const sortedStaffGroups = store?.staffGroups?.map(function (staffGroup) {
    return {
      value: staffGroup.staffGroup.idValue,
      label: staffGroup.staffGroup.title,
    };
  });

  const { data: agencyWorkers, loading: workersLoading } =
    useGetAgencyWorkersQuery();

  const { data: details } = useShiftDetailssQuery({
    fetchPolicy: "no-cache",
    skip: !stateOrg,
    variables: {
      id: stateOrg && stateOrg[0]?.organisationStaffGroup?.organisation?.id,
    },
  });

  // remap staffGroups into an array react-select can read
  const sortedWorkers = agencyWorkers?.vmsAgencyRegistrations.edges
    .filter((row) => row.node.user)
    .map(function ({ node: worker }) {
      return {
        value: worker.id,
        label: `${worker.user.firstName} ${worker.user.lastName}`,
      };
    });

  if (!store?.organisation) {
    return <div />;
  }

  let shiftOptions = [];
  if (!filtersInputs.blocks) {
    shiftOptions = [
      {
        label: "Urgent",
        value: "URGENT",
      },

      {
        label: "Sign Off Requested",
        value: "SIGN_OFF_REQUESTED",
      },

      {
        label: "Signed Off",
        value: "SIGNED_OFF",
      },

      {
        label: "Approved",
        value: "APPROVED",
      },
    ];
  }

  let statusOptions = [
    {
      label: "Available",
      value: "AVAILABLE",
    },

    {
      label: "Applied",
      value: "APPLIED",
    },

    {
      label: "Booked",
      value: "BOOKED",
    },
    ...shiftOptions,
  ];

  return (
    <FormGridContainer>
      <FormGrid columns={3}>
        {!filtersInputs.blocks && (
          <>
            <FormGroup>
              <FormLabel>Staff Groups</FormLabel>
              <FormSelectMulti
                isClearable
                onChange={(e: any) => {
                  filtersHandleFilter(e, "staffGroupIds");
                  resetPagination();
                }}
                options={sortedStaffGroups}
                placeholder="Select"
                type="text"
                value={filtersInputs.staffGroupIds}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Departments</FormLabel>
              <FormSelectMulti
                isClearable
                onChange={(e) => {
                  filtersHandleFilter(e, "departmentIds");
                  resetPagination();
                }}
                options={formatForReactSelect(
                  store?.organisation[0]?.organisationStaffGroup.organisation
                    .departments
                )}
                placeholder="Select"
                type="text"
                value={filtersInputs.departmentIds}
              />
            </FormGroup>
          </>
        )}

        <FormGroup>
          <FormLabel>Grades</FormLabel>
          <FormSelectMulti
            isClearable
            onChange={(e) => {
              filtersHandleFilter(e, "gradeIds");
              resetPagination();
            }}
            options={formatForReactSelect(details?.organisation.grades)}
            placeholder="Select"
            type="text"
            value={filtersInputs.gradeIds}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Sites</FormLabel>
          <FormSelectMulti
            isClearable
            onChange={(e) => {
              filtersHandleFilter(e, "siteIds");
              resetPagination();
            }}
            options={formatForReactSelect(details?.organisation.sites)}
            placeholder="Select"
            type="text"
            value={filtersInputs.siteIds}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Status</FormLabel>
          <FormSelect
            isClearable
            onChange={(e) => {
              filtersHandleFilter(e, "status");
              resetPagination();
            }}
            options={statusOptions}
            placeholder="Select"
            type="text"
            value={filtersInputs.status}
          />
        </FormGroup>
        {!filtersInputs.blocks && (
          <FormGroup>
            <FormLabel>Workers</FormLabel>
            <FormSelectMulti
              isClearable
              isLoading={workersLoading}
              onChange={(e) => {
                filtersHandleFilter(e, "agencyWorkerIds");
                resetPagination();
              }}
              options={sortedWorkers}
              placeholder="Select"
              type="text"
              value={filtersInputs.agencyWorkerIds}
            />
          </FormGroup>
        )}
        <FormGroup>
          <FormLabel>From Start Date</FormLabel>
          <DateInput
            action={(day, name) => {
              filtersHandleFilter(dayjs(day).format("YYYY-MM-DD"), name);
              resetPagination();
            }}
            name="fromDateValue"
            value={filtersInputs.fromDateValue}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>To End Date</FormLabel>
          <DateInput
            action={(day, name) => {
              filtersHandleFilter(dayjs(day).format("YYYY-MM-DD"), name);
              resetPagination();
            }}
            clearable={true}
            name="toDateValue"
            value={filtersInputs.toDateValue}
          />
        </FormGroup>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <DefaultButton
            action={setFiltersInputs}
            style={{ width: "130px" }}
            text="Clear Filters"
            type="white"
          />
        </FormGroup>
      </FormGrid>
    </FormGridContainer>
  );
};

export default Filters;
