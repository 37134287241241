import { useSelector } from "react-redux";

import OrganisationsTable from "components/Organisations/OrganisationsTable";
import TableHeader from "components/TableHeader/TableHeader";
import { RootState } from "models/store";

const OrganisationsPage = () => {
  const organisations = useSelector((s: RootState) => s.organisations);

  return (
    <>
      <TableHeader
        dataCount={organisations.length}
        inputPlaceholder="Search Org"
        title="Organisations"
      />
      <OrganisationsTable organisations={organisations} />
    </>
  );
};

export default OrganisationsPage;
