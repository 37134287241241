import { KeyboardEvent } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import {
  DefaultButton,
  FormBreak,
  FormGroup,
  FormInput,
  FormLabel,
  GREY,
} from "@patchworkhealth/web-components";

const ShiftRates = ({
  rates,
  rateTab,
  setRateTab,
  showCalculations,
  rateCardCalculator,
  employmentType,
  proposedWorker,
  setSkip,
  setRates,
  setShowCalculations,
  paymentType,
  totalComparitor,
  setShowPopup,
  showPopup,
  totalMargin,
  readOnly,
  onCall,
}) => {
  const handleNegativeInput = (e: KeyboardEvent<HTMLInputElement>) =>
    e.key === "-" ? e.preventDefault() : null;

  const populateRatesToAllPayElements = () => {
    // Check if rates array is not empty
    if (rates.length > 0) {
      const sourceRate = rates[rateTab - 1]; // Get the rate at the current tab index

      // Loop through the rates array and update each rate from the current tab index
      const updatedRates = rates.map((rate) => ({
        ...rate,
        comparatorRate: sourceRate.comparatorRate,
        agencyMargin: sourceRate.agencyMargin,
        workerPayeRate: sourceRate.workerPayeRate,
        ltdUmbrellaRate: sourceRate.ltdUmbrellaRate,
      }));

      setRates(updatedRates);
      setSkip(false);

      toast.success("Rates applied to all pay elements");
    }
  };

  return (
    <ShiftModalRates>
      <FormBreak />

      {rates.length > 0 && (
        <>
          <SliderWrapper>
            {/* HEADER **************************************************/}

            <SliderHeader>
              {rates.map((cc, index) => (
                <SliderTab
                  active={rateTab > index}
                  data-cy="rate_tab"
                  onClick={() => setRateTab(index + 1)}
                  rates={rates.length}
                  start={index === 0}
                >
                  {cc.name}

                  <SliderArrow active={rateTab === index + 1} />
                </SliderTab>
              ))}

              <SliderTab
                active={rateTab === rates.length + 1}
                data-cy="summary_tab"
                end={true}
                onClick={() => setRateTab(rates.length + 1)}
                rates={rates.length}
              >
                Summary
              </SliderTab>
            </SliderHeader>

            {/* BODY **************************************************/}

            <SliderBody>
              <PreviousButton
                disabled={rateTab === 1}
                onClick={() => setRateTab((rateTab -= 1))}
              >
                <SlideLeftIcon />
              </PreviousButton>

              <NextButton
                disabled={rateTab === rates.length + 1}
                onClick={() => setRateTab((rateTab += 1))}
              >
                <SlideRightIcon />
              </NextButton>

              {/* RATES ***************************************************/}

              {rates.map((rate, index) => {
                if (index + 1 === rateTab) {
                  return (
                    <ShiftModalRatesGrid>
                      <ShiftCol>
                        {(employmentType === "direct_engagement" &&
                          paymentType !== "ltd") ||
                        rate.agencyMarginLocked ||
                        proposedWorker?.readOnly ||
                        readOnly ? (
                          <ShiftModalRateCard data-cy="charge_rate">
                            <span>
                              {employmentType === "direct_engagement"
                                ? "Comparator Rate / Hr"
                                : "Charge Rate / Hr"}
                            </span>
                            <p>£{rate.comparatorRate}</p>

                            {rate.comparatorRateDiff !== 0 &&
                              rateCardCalculator(rate.comparatorRateDiff)}
                          </ShiftModalRateCard>
                        ) : (
                          <FormGroup
                            data-cy="charge_rate"
                            style={{ marginBottom: 16 }}
                          >
                            <FormLabel>Charge Rate / Hr</FormLabel>
                            <FormInput
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) => {
                                  return cc.id === rate.id
                                    ? {
                                        ...cc,
                                        comparatorRate: Number(e.target.value),
                                      }
                                    : cc;
                                });
                                setSkip(false);
                                setRates(updatedRates);
                              }}
                              onKeyDown={handleNegativeInput}
                              type="number"
                              value={
                                rate.comparatorRate === 0
                                  ? null
                                  : rate.comparatorRate
                              }
                            />

                            {rate.comparatorRateDiff !== 0 &&
                              rateCardCalculator(rate.comparatorRateDiff)}
                          </FormGroup>
                        )}

                        <ShiftModalRateCard data-cy="worker_base_rate">
                          <div
                            className="agency__icon"
                            onFocus={() => setShowPopup(true)}
                            onMouseLeave={() => setShowPopup(false)}
                            onMouseOver={() => setShowPopup(true)}
                          >
                            <AgencyInfoIcon />

                            <div
                              className={
                                showPopup ? "agency_popup show" : "agency_popup"
                              }
                            >
                              For illustrative purposes only.
                            </div>
                          </div>
                          <span>Worker Base Rate / Hr</span>
                          <p>£{rate.workerBaseRate.toFixed(2)}</p>

                          {rate.workerBaseRateDiff !== 0 &&
                            rateCardCalculator(rate.workerBaseRateDiff)}
                        </ShiftModalRateCard>
                      </ShiftCol>

                      <ShiftCol>
                        {employmentType === "non_direct_engagement" ||
                        (employmentType === "direct_engagement" &&
                          paymentType === "ltd") ||
                        rate?.agencyMarginLocked ||
                        proposedWorker?.readonly ||
                        readOnly ? (
                          <>
                            <ShiftModalRateCard data-cy="agency_margin">
                              <span>
                                Agency Margin / Hr{" "}
                                {employmentType === "direct_engagement" &&
                                  "(Charge)"}
                              </span>
                              <p>£{rate?.agencyMargin}</p>

                              {rate?.agencyMarginLocked && <AgencyLockIcon />}

                              {rate?.agencyMarginDiff !== 0 &&
                                rateCardCalculator(rate?.agencyMarginDiff)}
                            </ShiftModalRateCard>

                            {rate?.errors.map(
                              (err) =>
                                err.attribute === "agency_margin" && (
                                  <ErrorMessage style={{ marginTop: "-15px" }}>
                                    {err.message}
                                  </ErrorMessage>
                                )
                            )}
                          </>
                        ) : (
                          <FormGroup
                            data-cy="agency_margin"
                            style={{ marginBottom: 16 }}
                          >
                            <FormLabel>Agency Margin / Hr (Charge)</FormLabel>

                            <FormInput
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) => {
                                  return cc.id === rate.id
                                    ? {
                                        ...cc,
                                        agencyMargin: Number(e.target.value),
                                      }
                                    : cc;
                                });
                                setSkip(false);
                                setRates(updatedRates);
                              }}
                              onKeyDown={handleNegativeInput}
                              type="number"
                              value={
                                rate.agencyMargin === 0
                                  ? null
                                  : rate.agencyMargin
                              }
                            />

                            {rate.agencyMarginDiff !== 0 &&
                              rateCardCalculator(rate.agencyMarginDiff)}

                            {rate.errors.map(
                              (err) =>
                                err.attribute === "agency_margin" && (
                                  <ErrorMessage style={{ marginTop: "0px" }}>
                                    {err.message}
                                  </ErrorMessage>
                                )
                            )}
                          </FormGroup>
                        )}

                        {proposedWorker?.readonly || readOnly ? (
                          <ShiftModalRateCard data-cy="worker_rate">
                            <span>
                              Worker{" "}
                              {paymentType === "paye"
                                ? "PAYE "
                                : paymentType === "ltd"
                                ? "LTD "
                                : "Umbrella "}
                              Rate / Hr
                            </span>
                            <p>
                              £
                              {paymentType === "paye"
                                ? rate.workerPayeRate === 0
                                  ? null
                                  : rate.workerPayeRate
                                : rate.ltdUmbrellaRate === 0
                                ? null
                                : rate.ltdUmbrellaRate}
                            </p>

                            {paymentType === "paye"
                              ? rate.workerPayeRateDiff !== 0 &&
                                rateCardCalculator(rate.workerPayeRateDiff)
                              : rate.ltdUmbrellaRateDiff !== 0 &&
                                rateCardCalculator(rate.ltdUmbrellaRateDiff)}
                          </ShiftModalRateCard>
                        ) : (
                          <FormGroup data-cy="worker_rate">
                            <FormLabel>
                              Worker{" "}
                              {paymentType === "paye"
                                ? "PAYE "
                                : paymentType === "ltd"
                                ? "LTD "
                                : "Umbrella "}
                              Rate / Hr
                            </FormLabel>
                            <FormInput
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) => {
                                  return cc.id === rates[index].id
                                    ? {
                                        ...cc,
                                        workerPayeRate: Number(e.target.value),
                                        ltdUmbrellaRate: Number(e.target.value),
                                      }
                                    : cc;
                                });

                                setRates(updatedRates);
                                setSkip(false);
                              }}
                              onKeyDown={handleNegativeInput}
                              type="number"
                              value={
                                paymentType === "paye"
                                  ? rate.workerPayeRate === 0
                                    ? null
                                    : rate.workerPayeRate
                                  : rate.ltdUmbrellaRate === 0
                                  ? null
                                  : rate.ltdUmbrellaRate
                              }
                            />

                            {rate.errors.map(
                              (err) =>
                                err.attribute === "worker_rate" && (
                                  <ErrorMessage>{err.message}</ErrorMessage>
                                )
                            )}

                            {paymentType === "paye"
                              ? rate.workerPayeRateDiff !== 0 &&
                                rateCardCalculator(rate.workerPayeRateDiff)
                              : rate.ltdUmbrellaRateDiff !== 0 &&
                                rateCardCalculator(rate.ltdUmbrellaRateDiff)}
                          </FormGroup>
                        )}
                      </ShiftCol>
                    </ShiftModalRatesGrid>
                  );
                } else {
                  return false;
                }
              })}

              {rateTab === rates.length + 1 && (
                <ShiftModalRatesGrid>
                  <ShiftCol style={{ paddingLeft: "25px", maxWidth: 260 }}>
                    <ShiftModalRateCard>
                      <FormLabel>
                        Total
                        {employmentType === "direct_engagement"
                          ? " Comparator "
                          : " Charge "}
                        Rate for Shift
                      </FormLabel>
                      <p>£{totalComparitor.toFixed(2)}</p>
                    </ShiftModalRateCard>
                    {onCall === true &&
                      "Total rates are calculated based on total On-Call hours"}
                  </ShiftCol>

                  <ShiftCol style={{ paddingLeft: "25px", maxWidth: 260 }}>
                    <ShiftModalRateCard>
                      <FormLabel>Total Margin for Shift:</FormLabel>
                      <p>£{totalMargin.toFixed(2)}</p>
                    </ShiftModalRateCard>
                  </ShiftCol>
                </ShiftModalRatesGrid>
              )}

              {/* Slider Dots ******************************* */}

              <SliderDots>
                {rates.map((_cc, index) => (
                  <SliderDot active={rateTab === index + 1} />
                ))}

                <SliderDot active={rateTab === rates.length + 1} />
              </SliderDots>
            </SliderBody>
          </SliderWrapper>

          {rateTab !== rates.length + 1 ? (
            <>
              <CalculatedRates
                className={showCalculations && "show"}
                onClick={() => setShowCalculations(!showCalculations)}
              >
                How are these rates calculated?
              </CalculatedRates>

              <RatesInfo className={showCalculations && "show"}>
                <>
                  <span>
                    {employmentType === "direct_engagement"
                      ? "Comparator Rate / Hr"
                      : "Charge Rate / Hr"}
                  </span>
                  <p>{rates[rateTab - 1]?.comparatorRateBreakdown} </p>

                  <span>
                    Agency Margin / Hr{" "}
                    {employmentType === "direct_engagement" && "(Charge)"}
                  </span>
                  <p>{rates[rateTab - 1]?.agencyMarginBreakdown}</p>

                  {paymentType === "paye" ? (
                    <>
                      <span>Worker PAYE Rate</span>
                      <p>{rates[rateTab - 1]?.workerPayeRateBreakdown}</p>
                    </>
                  ) : (
                    <>
                      <span>
                        {paymentType === "ltd"
                          ? "Worker LTD Rate"
                          : "Worker Umbrella Rate"}
                      </span>
                      <p>{rates[rateTab - 1]?.ltdUmbrellaRateBreakdown}</p>{" "}
                    </>
                  )}
                </>
              </RatesInfo>
              <ButtonWrapper>
                <DefaultButton
                  action={populateRatesToAllPayElements}
                  color="blue"
                  text="Apply rates to all pay elements"
                />
              </ButtonWrapper>
            </>
          ) : (
            <CalculatedRates></CalculatedRates>
          )}
        </>
      )}

      {rates.length === 0 && (
        <NoRatesForGrade grade={proposedWorker?.grade?.title} onCall={onCall} />
      )}
    </ShiftModalRates>
  );
};

const NoRatesForGrade = ({
  onCall,
  grade = "",
}: {
  onCall: boolean;
  grade: string;
}) => {
  if (onCall) {
    const gradeDescription = grade || "this grade";
    return (
      <p data-testid="no-rates-message">
        On call shifts are not available to {gradeDescription}
      </p>
    );
  }
  return (
    <p data-testid="no-rates-message">
      No Rate card currently uploaded for this shift
    </p>
  );
};

export default ShiftRates;

const ShiftModalRates = styled.div`
  margin-top: -20px;
  padding-bottom: 60px;
`;

/* Grid Section *********************** */

const ShiftModalRatesGrid = styled.div`
  display: flex;
  margin: auto;
  padding-top: 10px;
  width: 600px;

  span {
    color: ${GREY.ten};
    font-size: 13px;
    font-weight: 600;
    line-height: 5px;
  }

  p {
    color: ${GREY.ten};
    font-size: 13px;
    font-weight: normal;
  }
`;

const ShiftCol = styled.div`
  flex: 1;
  margin: 10px 10px 0px;
  position: relative;
`;

const ShiftModalRateCard = styled.div`
  background: ${GREY.one};
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  box-sizing: border-box;
  height: 62px;
  margin-bottom: 22px;
  padding: 11px 16px;
  position: relative;
  width: 100%;

  .agency_popup {
    align-items: center;
    background: #002033;
    border-radius: 8px;
    color: white;
    display: none;
    font-size: 13px;
    font-weight: normal;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -80px;
    top: -45px;
    width: 202px;
    z-index: 100;

    &.show {
      display: flex;
    }
  }

  .agency__icon {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 80px;
    width: 20px;

    svg {
      left: 0px;
      position: absolute;
      top: 3px;
    }
  }

  svg {
    bottom: 12px;
    left: 60px;
    position: absolute;
  }
`;

const CalculatedRates = styled.span`
  color: ${GREY.six};
  cursor: pointer;
  margin-left: 90px;
  text-decoration: underline;
  width: 220px;

  &:hover {
    color: ${GREY.ten};
  }
`;

const RatesInfo = styled.div`
  display: none;
  margin-left: 90px;
  margin-top: 10px;
  width: 449px;

  &.show {
    display: block;
  }

  span {
    color: ${GREY.eight};
    font-size: 12px;
    font-weight: bold;
    line-height: 7px;
  }

  p {
    font-size: 12px;
    font-weight: normal;
  }
`;

const ErrorMessage = styled.div`
  color: #dc5a54;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  margin-top: 0px;
`;

const SliderWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e4e7eb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px auto 50px;
  max-width: 700px;
  width: 100%;
`;

/* SLIDER HEADER **********************************/

const SliderHeader = styled.div`
  border-bottom: 1px solid #e4e7eb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  height: 62px;
`;

const SliderTab = styled.div<{
  active: boolean;
  rates: number;
  start?: boolean;
  end?: boolean;
}>`
  align-items: center;
  align-items: center;
  background: ${({ active }) => (active ? "#17BFD6" : "white")};

  border-top-left-radius: ${({ start }) => start && "8px"};
  border-top-right-radius: ${({ end }) => end && "8px"};
  color: ${({ active }) => (active ? "white" : "#002033")};
  cursor: pointer;
  display: flex;
  display: flex;
  flex: 1;
  font-size: ${({ rates }) => (rates < 3 ? "16px" : "12px")};
  font-weight: 500;
  justify-content: center;
  line-height: 1.25;
  padding: 0 10px;
  position: relative;
  text-align: center;
  transition: all 0.25s ease-in-out;

  &:not(:last-child) {
    border-right: 1px solid #e4e7eb;
  }
`;

const SliderArrow = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? "#17BFD6" : "transparent")};
  clip-path: polygon(72% 26%, 100% 50%, 72% 75%, 72% 48%);
  height: 60px;
  position: absolute;
  right: -14px;
  top: 0px;
  transition: all 0.25s ease-in-out;
  width: 60px;
  z-index: 20;
`;

/* SLIDER BODY **********************************/

const SliderBody = styled.div`
  height: 188px;
  position: relative;
`;

const PreviousButton = styled.button<{ disabled: boolean }>`
  background: ${({ disabled }) => (disabled ? "#CCD2D8" : "white")};
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  height: 32px;
  left: -61px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  position: absolute;
  top: 80px;
  width: 32px;
  &:hover {
    background: ${GREY.two};
  }
`;

const NextButton = styled.button<{ disabled: boolean }>`
  background: ${({ disabled }) => (disabled ? "#CCD2D8" : "white")};
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  height: 32px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  position: absolute;
  right: -61px;
  top: 80px;
  width: 32px;
  &:hover {
    background: ${GREY.two};
  }
`;

const SliderDots = styled.div`
  bottom: -30px;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
`;

const SliderDot = styled.div<{ active: boolean }>`
  background: #7d8793;
  border-radius: 50%;
  height: 10px;
  margin-right: 16px;
  mix-blend-mode: normal;
  opacity: ${({ active }) => (active ? "1" : "0.43")};
  transition: all 0.4s ease-in-out;
  width: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 35px;
`;

/* ICONS **************************************/

const SlideLeftIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.70711 0.292893C7.31658 -0.0976312 6.68342 -0.0976313 6.29289 0.292893L0.292892 6.29289C-0.0976326 6.68342 -0.0976327 7.31658 0.292892 7.70711L6.29289 13.7071C6.68341 14.0976 7.31658 14.0976 7.7071 13.7071C8.09763 13.3166 8.09763 12.6834 7.7071 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683418 7.70711 0.292893Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);

const SlideRightIcon = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 8 14"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292894C1.31658 -0.0976313 0.683419 -0.0976314 0.292894 0.292893C-0.0976299 0.683417 -0.09763 1.31658 0.292894 1.70711L5.58579 7L0.292893 12.2929C-0.097631 12.6834 -0.097631 13.3166 0.292893 13.7071Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);

const AgencyLockIcon = () => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49992 9.20829C8.31206 9.20829 8.13189 9.28292 7.99905 9.41576C7.86621 9.5486 7.79158 9.72876 7.79158 9.91663V12.0416C7.79158 12.2295 7.86621 12.4097 7.99905 12.5425C8.13189 12.6753 8.31206 12.75 8.49992 12.75C8.68778 12.75 8.86795 12.6753 9.00079 12.5425C9.13362 12.4097 9.20825 12.2295 9.20825 12.0416V9.91663C9.20825 9.72876 9.13362 9.5486 9.00079 9.41576C8.86795 9.28292 8.68778 9.20829 8.49992 9.20829ZM12.0416 6.37496V4.95829C12.0416 4.01898 11.6684 3.11815 11.0043 2.45396C10.3401 1.78976 9.43923 1.41663 8.49992 1.41663C7.56061 1.41663 6.65977 1.78976 5.99558 2.45396C5.33139 3.11815 4.95825 4.01898 4.95825 4.95829V6.37496C4.39467 6.37496 3.85416 6.59884 3.45565 6.99736C3.05714 7.39587 2.83325 7.93637 2.83325 8.49996V13.4583C2.83325 14.0219 3.05714 14.5624 3.45565 14.9609C3.85416 15.3594 4.39467 15.5833 4.95825 15.5833H12.0416C12.6052 15.5833 13.1457 15.3594 13.5442 14.9609C13.9427 14.5624 14.1666 14.0219 14.1666 13.4583V8.49996C14.1666 7.93637 13.9427 7.39587 13.5442 6.99736C13.1457 6.59884 12.6052 6.37496 12.0416 6.37496ZM6.37492 4.95829C6.37492 4.39471 6.5988 3.85421 6.99732 3.45569C7.39583 3.05718 7.93633 2.83329 8.49992 2.83329C9.0635 2.83329 9.60401 3.05718 10.0025 3.45569C10.401 3.85421 10.6249 4.39471 10.6249 4.95829V6.37496H6.37492V4.95829ZM12.7499 13.4583C12.7499 13.6462 12.6753 13.8263 12.5425 13.9592C12.4096 14.092 12.2294 14.1666 12.0416 14.1666H4.95825C4.77039 14.1666 4.59022 14.092 4.45738 13.9592C4.32455 13.8263 4.24992 13.6462 4.24992 13.4583V8.49996C4.24992 8.3121 4.32455 8.13193 4.45738 7.99909C4.59022 7.86625 4.77039 7.79163 4.95825 7.79163H12.0416C12.2294 7.79163 12.4096 7.86625 12.5425 7.99909C12.6753 8.13193 12.7499 8.3121 12.7499 8.49996V13.4583Z"
      fill="#7D8793"
    />
  </svg>
);

const AgencyInfoIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875ZM0.625 7.5C0.625 3.70304 3.70304 0.625 7.5 0.625C11.297 0.625 14.375 3.70304 14.375 7.5C14.375 11.297 11.297 14.375 7.5 14.375C3.70304 14.375 0.625 11.297 0.625 7.5ZM7.5 6.875C7.84518 6.875 8.125 7.15482 8.125 7.5V10C8.125 10.3452 7.84518 10.625 7.5 10.625C7.15482 10.625 6.875 10.3452 6.875 10V7.5C6.875 7.15482 7.15482 6.875 7.5 6.875ZM7.5 4.375C7.15482 4.375 6.875 4.65482 6.875 5C6.875 5.34518 7.15482 5.625 7.5 5.625H7.50625C7.85143 5.625 8.13125 5.34518 8.13125 5C8.13125 4.65482 7.85143 4.375 7.50625 4.375H7.5Z"
      fill="#7D8793"
      fillRule="evenodd"
    />
  </svg>
);
