import styled from "styled-components";

import { GLOBAL } from "@patchworkhealth/web-components";

type StatusLabelProps = {
  status: string;
  style?: any;
};

const StatusLabel = ({ status, style }: StatusLabelProps) => {
  const label =
    status === "SIGNED_OFF"
      ? "SIGNED OFF"
      : status === "TO_SIGN_OFF_ON_HUB_OR_APP" ||
        status === "TO_SIGN_OFF_ON_HUB"
      ? "TO SIGN OFF"
      : status === "SIGN_OFF_REQUESTED"
      ? "Sign off"
      : status === "PAID"
      ? "PAYROLL"
      : status === "PROVISIONALLY_BOOKED"
      ? "BOOKED"
      : status;

  return (
    <StatusLabelContainer status={status} style={{ ...style }}>
      {label}
    </StatusLabelContainer>
  );
};

export default StatusLabel;

const StatusLabelContainer = styled.div<{ status: string }>`
  align-items: center;
  background-color: ${({ status }) =>
    status === "DE" || status === "Non-DE"
      ? "#55606C"
      : status === "BOOKED" ||
        status === "PROVISIONALLY_BOOKED" ||
        status === "success" ||
        status === "sent"
      ? "#c5f2c4"
      : status === "APPROVED"
      ? "#ffedb7"
      : status === "AVAILABLE"
      ? "#ffccb8"
      : status === "PAID"
      ? "#3e4d58"
      : status === "not sent"
      ? "#F9D0D5"
      : status === "TO_SIGN_OFF_ON_HUB_OR_APP" ||
        status === "TO_SIGN_OFF_ON_HUB"
      ? "#1aa1b5"
      : status === "SIGN_OFF_REQUESTED"
      ? "#1aa1b5"
      : status === "SIGNED_OFF"
      ? "#cbd2d9"
      : GLOBAL.fontDark};
  border-radius: 4px;
  color: ${({ status }) =>
    status === "BOOKED" ||
    status === "PROVISIONALLY_BOOKED" ||
    status === "success" ||
    status === "sent"
      ? "#2c752b"
      : status === "APPROVED"
      ? "#a48428"
      : status === "AVAILABLE"
      ? "#c34412"
      : status === "PAID"
      ? "#fff"
      : status === "not sent"
      ? "#BD2D2E"
      : status === "SIGNED_OFF"
      ? "#1e2d36"
      : GLOBAL.white};
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  padding-top: 2px;
  text-align: center;
  text-transform: uppercase;
  width: ${({ status }) => (status === "sent" ? "48px" : "85px")};
`;

export const ProposalsBadge = styled.div`
  align-items: center;
  background: #f9d0d5;
  border-radius: 4px;
  color: #bd2d2e;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 21px;
  justify-content: center;
  padding: 0;
  padding-top: 5px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  width: 85px;
  &:hover {
    background: #f9abb4;
  }
`;
