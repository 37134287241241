/* stylelint-disable-next-line CssSyntaxError */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { Loading, ModalBody } from "@patchworkhealth/web-components";

import {
  ShiftModalContainer,
  ShiftModalContentContainer,
} from "../AgencyShifts/AgencyShiftsModal";
import { useShiftBlockQuery } from "./__generated__/BlocksHelpers.generated";
import BlockModalBooking from "./BlockModalComponents/BlockCancelBooking";
import BlockDetails from "./BlockModalComponents/BlockDetails";
import BlockModalHeader from "./BlockModalComponents/BlockModalHeader";
import BlockModalSidebar from "./BlockModalComponents/BlockModalSidebar";
import BlockProposeWorker from "./BlockModalComponents/BlockProposeWorker";
import BlockViewBooking from "./BlockModalComponents/BlockViewBooking";

const BlocksModal = ({
  handleCloseAction,
  blockId,
  show,
  refetchBlocks,
  setShowWorker,
  setShowShift,
}: BlockModalProps) => {
  // State ***********************************
  const [tab, setTab] = useState("details");

  useEffect(() => {
    if (show) {
      setTab("details");
    }
  }, [show]);

  const {
    data,
    loading,
    refetch: refetchBlock,
  } = useShiftBlockQuery({
    fetchPolicy: "no-cache",
    skip: !blockId,
    variables: {
      shiftBlockIds: [Number(blockId)],
    },
  });

  const block = data?.shiftBlocks?.nodes[0];

  return (
    <Modal
      backdrop="static"
      centered
      data-testid="shifts__modal"
      onHide={handleCloseAction}
      show={show}
      size="xl"
    >
      {loading && <Loading modal />}

      {!loading && (
        <>
          <BlockModalHeader
            handleCloseAction={handleCloseAction}
            shift={block}
          />
          <ShiftModalContainer>
            <BlockModalSidebar setTab={setTab} shift={block} tab={tab} />
            <ShiftModalContentContainer>
              <ModalBody style={{ minHeight: 520, paddingBottom: 100 }}>
                {tab === "details" && (
                  <BlockDetails setShowShift={setShowShift} shift={block} />
                )}

                {tab === "proposeWorker" && (
                  <BlockProposeWorker
                    block={block}
                    refetch={refetchBlock}
                    setShowWorker={setShowWorker}
                  />
                )}

                {tab === "booked" && (
                  <BlockModalBooking
                    block={block}
                    handleClose={handleCloseAction}
                    refetchBlocks={refetchBlocks}
                    setTab={setTab}
                  />
                )}

                {tab === "view" && (
                  <BlockViewBooking
                    block={block}
                    handleCloseAction={handleCloseAction}
                    setShowWorker={setShowWorker}
                  />
                )}
              </ModalBody>
            </ShiftModalContentContainer>
          </ShiftModalContainer>
        </>
      )}
    </Modal>
  );
};

export default BlocksModal;

type BlockModalProps = {
  handleCloseAction: () => void;
  blockId: number;
  show: boolean;
  setShowWorker: (any) => void;
  setShowShift: Function;
  refetchBlocks: Function;
};
