import { useState } from "react";

import {
  AgencyUserFragment,
  useGetAgencyUsersQuery,
} from "components/AgencyUsers/__generated__/AgencyUsersHelpers.generated";
import AgencyUserModal from "components/AgencyUsers/AgencyUsersModal";
import AgencyUsersTable from "components/AgencyUsers/AgencyUsersTable";
import TableHeader from "components/TableHeader/TableHeader";
import { createFormFunctions } from "helpers/functions";

const UsersPage = () => {
  /* STATE ********************************************************/

  const [agencyUser, setAgencyUser] = useState<AgencyUserFragment>(null);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);

  const [inputs, setInputs] = useState({
    searchText: "",
  });
  const { handleFilter } = createFormFunctions(setInputs);

  /* FUNCTIONS & GQL *********************************************/

  const {
    data: agencyUsers,
    loading,
    refetch,
  } = useGetAgencyUsersQuery({
    variables: {
      name: inputs.searchText,
      page,
      items: 25,
    },
  });

  const setShowAgencyUser = (data) => {
    setAgencyUser(data ?? null);
    setShow(true);
  };

  const handleCloseAction = () => {
    setAgencyUser(null);
    setShow(false);
  };

  return (
    <>
      <TableHeader
        buttonText="Create User"
        dataCount={agencyUsers?.vmsAgencyUsers.pagination.count}
        handleShow={setShowAgencyUser}
        setSearchText={(e) => handleFilter(e, "searchText")}
        title="Users"
      />
      {agencyUsers && (
        <AgencyUsersTable
          agencyUsers={agencyUsers}
          loading={loading}
          setPage={setPage}
          setShowAgencyUser={setShowAgencyUser}
        />
      )}
      {show && (
        <AgencyUserModal
          agencyUser={agencyUser}
          handleCloseAction={handleCloseAction}
          refetch={refetch}
          setShowAgencyUser={setShowAgencyUser}
          show={show}
        />
      )}
    </>
  );
};

export default UsersPage;
