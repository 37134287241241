import dayjs from "dayjs";

import { CloseButton, ModalHeader } from "@patchworkhealth/web-components";

import StatusLabel from "components/StatusLabel";

import {
  ShiftModalHeaderDetailsContainer,
  ShiftModalHeaderDivider,
  ShiftModalHeaderTitle,
} from "../../AgencyShifts/ShiftModalComponents/ShiftModalHeader";
import { ShiftBlockQuery } from "../__generated__/BlocksHelpers.generated";

const ShiftModalHeader = ({
  handleCloseAction,
  shift,
}: BlockModalHeaderProps) => (
  <ModalHeader>
    <ShiftModalHeaderDetailsContainer>
      <ShiftModalHeaderTitle>
        {shift?.name} (#{shift?.id})
      </ShiftModalHeaderTitle>
      <ShiftModalHeaderDivider />
      <div>
        <p>{dayjs(shift?.startDate).format("D MMM YYYY")} - </p>
        <p>{dayjs(shift?.endDate).format("D MMM YYYY")}</p>
      </div>
      <ShiftModalHeaderDivider />
      <p>{shift?.organisation?.name}</p>
      <ShiftModalHeaderDivider />

      <span style={{ color: shift?.grade?.colour, fontWeight: 600 }}>
        {shift?.grade?.label}
      </span>
    </ShiftModalHeaderDetailsContainer>
    <ShiftModalHeaderDetailsContainer>
      <StatusLabel status={shift?.status} style={{ marginRight: 20 }} />
      <CloseButton onClick={handleCloseAction} />
    </ShiftModalHeaderDetailsContainer>
  </ModalHeader>
);

export default ShiftModalHeader;

type BlockModalHeaderProps = {
  handleCloseAction: Function;
  shift: ShiftBlockQuery["shiftBlocks"]["nodes"][number];
};
