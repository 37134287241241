import dayjs from "dayjs";
import styled from "styled-components";

import {
  FormSelect,
  Pagination,
  TableContainer,
} from "@patchworkhealth/web-components/";

import StatusLabel, { ProposalsBadge } from "components/StatusLabel";
import { paginationOptions } from "helpers/functions";
import { PaginationType } from "helpers/types";

import { checkProposals } from "./BlocksHelpers";

const BlocksTable = ({
  blocks,
  loading,
  pageInfo,
  pagination,
  setPagination,
  setShowBlock,
  setShowWorker,
  setRowsSearched,
  rowsSearched,
}: BlocksTableProps) => {
  return (
    <>
      <TableContainer loading={loading}>
        <thead>
          <tr>
            <th>Block Id</th>
            <th>Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Grade</th>
            <th>Location</th>
            <th>Status</th>
            <th>Worker</th>
            <th>Date Sent to Agency</th>
          </tr>
        </thead>
        <tbody>
          {blocks?.nodes?.map((block) => {
            const proposals = checkProposals(block);

            return (
              <tr key={block?.id}>
                <td>
                  <button
                    data-cy="block_id"
                    onClick={() => setShowBlock(block?.id)}
                    style={{ textDecoration: "underline" }}
                  >
                    <strong>{block?.uid}</strong>
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => setShowBlock(block?.id)}
                    style={{ textDecoration: "underline" }}
                  >
                    <strong>{block?.name}</strong>
                  </button>
                </td>
                <td>{dayjs(block?.startDate).format("D MMM YYYY")}</td>
                <td>{dayjs(block?.endDate).format("D MMM YYYY")}</td>
                <td
                  style={{
                    fontWeight: 500,
                    color: block?.grade?.colour,
                    maxWidth: "300px !important",
                  }}
                >
                  {block?.grade?.label}
                </td>

                <td>
                  <span style={{ display: "block" }}>
                    {" "}
                    <strong> {block?.department}</strong>
                  </span>
                  <span>{block?.site}</span>
                </td>
                <td data-cy="table_shift_status">
                  {block.status === "AVAILABLE" && proposals > 0 ? (
                    <ProposalsBadge onClick={() => setShowBlock(block?.id)}>
                      {proposals} Proposal
                      {proposals > 1 ? "s" : null}
                    </ProposalsBadge>
                  ) : (
                    <StatusLabel status={block?.status} />
                  )}
                </td>

                <td className="no-wrap" style={{ lineHeight: "1.2" }}>
                  <button
                    onClick={() => setShowWorker(block.bookedBy)}
                    style={{ textDecoration: "underline" }}
                  >
                    <strong>{block.bookedBy?.user?.fullName}</strong>
                  </button>
                </td>
                <td className="no-wrap" style={{ fontSize: 15 }}>
                  {dayjs(block?.sentToAgencyDate).format("D MMM YYYY")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableContainer>

      <div
        style={{
          position: "relative",
          width: "100%",
          margin: "10px 0px 160px",
        }}
      >
        <Pagination
          pageInfo={pageInfo}
          pagination={pagination}
          setPagination={setPagination}
        />
        <PaginationWrapper>
          <span>Blocks per page:</span>
          <FormSelect
            onChange={(e) => setRowsSearched(e.value)}
            options={paginationOptions}
            value={paginationOptions.filter(
              (val) => val.value === rowsSearched
            )}
          />
        </PaginationWrapper>
      </div>
    </>
  );
};

export default BlocksTable;

type BlocksTableProps = {
  blocks: any;
  loading: boolean;
  pageInfo: any;
  pagination: PaginationType;
  setPagination: Function;
  setShowBlock: Function;
  setShowWorker: Function;
  setRowsSearched: Function;
  rowsSearched: any;
};

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;

  .react-select-container {
    margin-left: 8px;
    width: 100px;
  }
`;
