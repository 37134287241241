// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import {
  DropdownMenuButton,
  DropdownMenuContainer,
  DropdownMenuItem,
  DropdownMenuList,
  GLOBAL,
  GREY,
} from "@patchworkhealth/web-components";

import { OrgIconPicker } from "assets/icons/OrgPicker";
import { defaultImgOnError, deleteTokens } from "helpers/functions";
import { RootState } from "models/store";

const Header = () => {
  const store = useSelector((s: RootState) => s);

  const {
    organisation: { storeOrganisation },
  } = useDispatch();



  const userMenuNode = useRef();
  const [userMenuShow, setUserMenuShow] = useState(false);
  const [organisationMenuShow, setOrganisationMenuShow] = useState(false);

  const handleClickOutsideUserMenu = (e) => {
    if (userMenuNode?.current?.contains(e.target)) {
      return;
    }
    setUserMenuShow(false);
  };

  useEffect(() => {
    if (userMenuShow) {
      document.addEventListener("mousedown", handleClickOutsideUserMenu);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideUserMenu);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideUserMenu);
    };
  }, [userMenuShow]);

  // Org Picker ****************

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", () =>
        setOrganisationMenuShow(false)
      );
    };
  }, [organisationMenuShow]);

  // UseEffects *****************************************

  if (!store.organisations || !store.organisation) {
    return <div />;
  }

  return (
    <HeaderWrapper>
      <HeaderLeft
        ref={userMenuNode}
        clickable={store.organisations?.length > 1}
        onClick={() => setOrganisationMenuShow(!organisationMenuShow)}
      >
        <img
          alt="Organisation"
          className="logo"
          onError={defaultImgOnError}
          src={`${process.env.REACT_APP_API}${store.organisation[0]?.organisationStaffGroup?.organisation?.logoUrl}`}
        />

        <h2>
          {store.organisation?.length > 1
            ? "All Organisations"
            : store.organisation[0]?.organisationStaffGroup?.organisation?.name}
        </h2>

        {store.organisations?.length > 1 && <OrgIconPicker />}

        {organisationMenuShow && (
          <HeaderOrganisationMenuContainer right={false}>
            <DropdownMenuList>
              {store.organisations?.map((org) => (
                <HeaderItem onClick={() => storeOrganisation([org])}>
                  <DropdownMenuButton data-cy="logout-btn">
                    {org?.organisationStaffGroup?.organisation?.name}
                  </DropdownMenuButton>
                </HeaderItem>
              ))}

              {store.organisations?.length > 1 && (
                <HeaderItem
                  onClick={() => storeOrganisation(store.organisations)}
                >
                  <DropdownMenuButton data-cy="logout-btn">
                    All Organisations
                  </DropdownMenuButton>
                </HeaderItem>
              )}
            </DropdownMenuList>
          </HeaderOrganisationMenuContainer>
        )}
      </HeaderLeft>

      <div>
        <AgencyTitle>
          {store?.user?.agency?.title} ({store?.user?.agency?.id})
        </AgencyTitle>
        <UserNav>
          <HeaderUserMenu>
            <div ref={userMenuNode} className="dropdown">
              <button onClick={() => setUserMenuShow(!userMenuShow)}>
                <Avatar
                  data-cy="avatar"
                  src={`${process.env.REACT_APP_API}/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg`}
                />
              </button>
              {userMenuShow && (
                <HeaderUserMenuContainer right={true}>
                  <DropdownMenuList>
                    <DropdownMenuItem>
                      <DropdownMenuButton
                        data-cy="logout-btn"
                        onClick={() => {
                          deleteTokens();
                         
                          window.location.reload();
                        }}
                      >
                        Logout
                      </DropdownMenuButton>
                    </DropdownMenuItem>
                  </DropdownMenuList>
                </HeaderUserMenuContainer>
              )}
            </div>
          </HeaderUserMenu>
        </UserNav>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderItem = styled.div`
  padding: 10px 0;
  padding-left: 10px;

  &:hover {
    background: ${GREY.one};
  }
`;

const HeaderWrapper = styled.div`
  align-items: center;
  background: ${GLOBAL.darkBlue};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  height: 54px;
  justify-content: space-between;
  left: 0;
  padding-left: 204px;
  padding-right: 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
`;

const HeaderLeft = styled.div<{ clickable: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  pointer-events: ${({ clickable }) => (clickable ? "initial" : "none")};

  position: relative;
  h2 {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    margin: 0 10px 0 0;
  }

  img {
    border-radius: 50%;
    height: 44px;
    margin-right: 10px;
    width: 50px;
  }
`;

const UserNav = styled.div`
  align-items: center;
  color: ${GLOBAL.white};
  display: flex;
  justify-content: center;

  a {
    color: ${GLOBAL.white};
    font-weight: 400;

    &:hover {
      color: ${GLOBAL.white};
    }
  }
`;

const Avatar = styled.img`
  border-radius: 8px;
  height: 32px;
  width: 32px;
`;

const HeaderUserMenu = styled.div`
  margin-left: 8px;
  position: relative;
`;

const HeaderUserMenuContainer = styled(DropdownMenuContainer)<{
  right?: boolean;
}>`
  left: ${({ right }) => !right && "0"};
  min-width: 120px;
  right: ${({ right }) => right && "0"};
  top: ${({ right }) => !right && "50px"};

  button {
    &:hover {
      background: transparent;
    }
  }
`;

const HeaderOrganisationMenuContainer = styled(DropdownMenuContainer)<{
  right?: boolean;
}>`
  left: 0px;
  min-width: 220px;
  padding: 10px 0px;
  top: 50px;

  button {
    &:hover {
      background: transparent;
    }
  }
`;

const AgencyTitle = styled.p`
  border-right: 1px solid white;
  font-size: 18;
  font-weight: bold;
  padding-right: 12px;
  position: absolute;
  right: 72px;
  top: 18px;
`;
