import { useSelector } from "react-redux";

import { RootState } from "models/store";

import {
  ShiftModalSidebarContainer,
  ShiftModalSidebarMenuDivider,
  ShiftModalSidebarNav,
  ShiftModalSidebarNavOption,
} from "../../AgencyShifts/ShiftModalComponents/ShiftModalSidebar";
import { ShiftBlockQuery } from "../__generated__/BlocksHelpers.generated";

const ShiftModalSidebar = ({ tab, setTab, shift }: ShiftModalSidebarProps) => {
  const { user } = useSelector((s: RootState) => s);

  return (
    <ShiftModalSidebarContainer>
      <ShiftModalSidebarNav>
        {shift?.status === "BOOKED" && (
          <>
            <li>
              <ShiftModalSidebarNavOption
                active={tab === "view"}
                onClick={() => setTab("view")}
              >
                View Booking
              </ShiftModalSidebarNavOption>
            </li>
            <ShiftModalSidebarMenuDivider />
          </>
        )}

        <li>
          <ShiftModalSidebarNavOption
            active={tab === "details"}
            onClick={() => setTab("details")}
          >
            Block Details
          </ShiftModalSidebarNavOption>
        </li>

        {shift?.status !== "BOOKED" && (
          <li>
            <ShiftModalSidebarNavOption
              active={tab === "proposeWorker"}
              onClick={() => setTab("proposeWorker")}
            >
              Propose Worker
            </ShiftModalSidebarNavOption>
          </li>
        )}

        {shift?.status === "BOOKED" && user.email.match(/patchwork/) && (
          <li>
            <ShiftModalSidebarNavOption
              active={tab === "booked"}
              onClick={() => setTab("booked")}
            >
              Cancel Booking
            </ShiftModalSidebarNavOption>
          </li>
        )}
      </ShiftModalSidebarNav>
    </ShiftModalSidebarContainer>
  );
};

export default ShiftModalSidebar;

type ShiftModalSidebarProps = {
  tab: string;
  setTab: Function;
  shift: ShiftBlockQuery["shiftBlocks"]["nodes"][number];
};
