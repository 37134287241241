// @ts-nocheck
import { KeyboardEvent } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import {
  DefaultButton,
  FormBreak,
  FormGroup,
  FormInputWithSymbol,
  FormLabel,
  GREY,
  ORANGE,
} from "@patchworkhealth/web-components";

import {
  AgencyInfoIcon,
  AgencyLockIcon,
  SlideLeftIcon,
  SlideRightIcon,
} from "./BlockIcons";

type BlockRatesProps = {
  handleFilter: Function;
  inputs: any;
};

function BlockRates({ handleFilter, inputs }: BlockRatesProps) {
  let { rates, rateTab, viewWorker, viewProposal } = inputs;
  let paymentType = viewWorker?.paymentType;
  let employmentType = viewWorker?.employmentType;

  if (viewProposal) {
    paymentType = viewProposal?.agencyWorker?.paymentType;
    employmentType = viewProposal?.agencyWorker?.employmentType;
  }

  const rateCardCalculator = (rate) => (
    <RateCardChange>
      {rate > 0 ? `+ £${rate.toFixed(2)}` : `£ ${rate.toFixed(2)}`}
    </RateCardChange>
  );

  const populateRatesToAllPayElements = () => {
    // Check if rates array is not empty
    if (rates.length > 0) {
      const sourceRate = rates[rateTab - 1]; // Get the rate at the current tab index

      const updatedRates = rates.map((rate) => ({
        ...rate,
        comparatorRate: sourceRate.comparatorRate,
        agencyMargin: sourceRate.agencyMargin,
        workerPayeRate: sourceRate.workerPayeRate,
        ltdUmbrellaRate: sourceRate.ltdUmbrellaRate,
      }));

      toast.success("Rates applied to all pay elements");
      handleFilter(false, "skip2");
      handleFilter(updatedRates, "rates");
    }
  };

  const handleNegativeInput = (e: KeyboardEvent<HTMLInputElement>) =>
    e.key === "-" ? e.preventDefault() : null;

  return (
    <ShiftModalRates>
      <FormBreak />

      {rates.length > 0 && (
        <>
          <SliderWrapper>
            <SliderHeader>
              {rates.map((cc, index) => (
                <SliderTab
                  key={cc.name}
                  active={rateTab > index}
                  onClick={() => handleFilter(index + 1, "rateTab")}
                  rates={rates.length}
                  start={index === 0}
                >
                  {cc.name}
                  <SliderArrow active={rateTab === index + 1} />
                </SliderTab>
              ))}
            </SliderHeader>

            <SliderBody>
              <PreviousButton
                disabled={rateTab === 1}
                onClick={() => handleFilter((rateTab -= 1), "rateTab")}
              >
                <SlideLeftIcon />
              </PreviousButton>

              <NextButton
                disabled={rateTab === rates.length}
                onClick={() => handleFilter((rateTab += 1), "rateTab")}
              >
                <SlideRightIcon />
              </NextButton>

              {/* RATES ************************************************* */}

              {rates.map((rate, index) => {
                if (index + 1 === rateTab) {
                  return (
                    <ShiftModalRatesGrid readOnly={viewProposal}>
                      <ShiftCol>
                        {(employmentType === "direct_engagement" &&
                          paymentType !== "ltd") ||
                        rate.agencyMarginLocked ||
                        viewProposal ? (
                          <ShiftModalRateCard data-cy="charge_rate">
                            <span>
                              {employmentType === "direct_engagement" &&
                              paymentType !== "ltd"
                                ? "Comparator Rate / Hr"
                                : "Charge Rate / Hr"}
                            </span>
                            <p>£{rate.comparatorRate}</p>
                            {rate.comparatorRateDiff !== 0 &&
                              rateCardCalculator(rate.comparatorRateDiff)}
                          </ShiftModalRateCard>
                        ) : (
                          <FormGroup
                            data-cy="charge_rate"
                            style={{ marginBottom: 16 }}
                          >
                            <FormLabel>Charge Rate / Hr</FormLabel>
                            <FormInputWithSymbol
                              before
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) =>
                                  cc.id === rate.id
                                    ? {
                                        ...cc,
                                        comparatorRate: Number(e.target.value),
                                      }
                                    : cc
                                );
                                handleFilter(false, "skip2");
                                handleFilter(updatedRates, "rates");
                              }}
                              onKeyDown={handleNegativeInput}
                              placeholder="0.00"
                              symbol=" £ "
                              type="number"
                              value={
                                rate.comparatorRate === 0
                                  ? null
                                  : rate.comparatorRate
                              }
                            />

                            {rate.comparatorRateDiff !== 0 &&
                              rateCardCalculator(rate.comparatorRateDiff)}
                          </FormGroup>
                        )}

                        <ShiftModalRateCard data-cy="worker_base_rate">
                          <div
                            className="agency__icon"
                            onMouseLeave={() =>
                              handleFilter(false, "showPopup")
                            }
                            onMouseOver={() => handleFilter(true, "showPopup")}
                          >
                            <AgencyInfoIcon />

                            <div
                              className={
                                inputs.showPopup
                                  ? "agency_popup show"
                                  : "agency_popup"
                              }
                            >
                              For illustrative purposes only.
                            </div>
                          </div>
                          <span>Worker Base Rate / Hr</span>
                          <p>£{rate.workerBaseRate.toFixed(2)}</p>

                          {rate.workerBaseRateDiff !== 0 &&
                            rateCardCalculator(rate.workerBaseRateDiff)}
                        </ShiftModalRateCard>
                      </ShiftCol>

                      <ShiftCol>
                        {employmentType === "non_direct_engagement" ||
                        (employmentType === "direct_engagement" &&
                          paymentType === "ltd") ||
                        rate?.agencyMarginLocked ||
                        viewProposal ? (
                          <>
                            <ShiftModalRateCard data-cy="agency_margin">
                              <span>
                                Agency Margin / Hr{" "}
                                {employmentType === "direct_engagement" &&
                                  "(Charge)"}
                              </span>
                              <p>£{rate?.agencyMargin}</p>

                              {rate?.agencyMarginLocked && <AgencyLockIcon />}

                              {rate?.agencyMarginDiff !== 0 &&
                                rateCardCalculator(rate?.agencyMarginDiff)}
                            </ShiftModalRateCard>

                            {rate?.errors.map(
                              (err) =>
                                err.attribute === "agency_margin" && (
                                  <ErrorMessage style={{ marginTop: "-15px" }}>
                                    {err.message}
                                  </ErrorMessage>
                                )
                            )}
                          </>
                        ) : (
                          <FormGroup
                            data-cy="agency_margin"
                            style={{ marginBottom: 16 }}
                          >
                            <FormLabel>Agency Margin / Hr (Charge)</FormLabel>

                            <FormInputWithSymbol
                              before
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) =>
                                  cc.id === rate.id
                                    ? {
                                        ...cc,
                                        agencyMargin: Number(e.target.value),
                                      }
                                    : cc
                                );

                                handleFilter(false, "skip2");
                                handleFilter(updatedRates, "rates");
                              }}
                              onKeyDown={handleNegativeInput}
                              symbol=" £ "
                              type="number"
                              value={
                                rate.agencyMargin === 0
                                  ? null
                                  : rate.agencyMargin
                              }
                            />

                            {rate.agencyMarginDiff !== 0 &&
                              rateCardCalculator(rate.agencyMarginDiff)}

                            {rate.errors.map(
                              (err) =>
                                err.attribute === "agency_margin" && (
                                  <ErrorMessage style={{ marginTop: "0px" }}>
                                    {err.message}
                                  </ErrorMessage>
                                )
                            )}
                          </FormGroup>
                        )}

                        {viewProposal && (
                          <ShiftModalRateCard data-cy="worker_rate">
                            <span>
                              Worker{" "}
                              {paymentType === "paye"
                                ? "PAYE "
                                : paymentType === "ltd"
                                ? "LTD "
                                : "Umbrella "}
                              Rate / Hr
                            </span>
                            <p>
                              £
                              {paymentType === "paye"
                                ? rate.workerPayeRate === 0
                                  ? null
                                  : rate.workerPayeRate
                                : rate.ltdUmbrellaRate === 0
                                ? null
                                : rate.ltdUmbrellaRate}
                            </p>

                            {paymentType === "paye"
                              ? rate.workerPayeRateDiff !== 0 &&
                                rateCardCalculator(rate.workerPayeRateDiff)
                              : rate.ltdUmbrellaRateDiff !== 0 &&
                                rateCardCalculator(rate.ltdUmbrellaRateDiff)}
                          </ShiftModalRateCard>
                        )}

                        {viewWorker && (
                          <FormGroup data-cy="worker_rate">
                            <FormLabel>
                              Worker{" "}
                              {paymentType === "paye"
                                ? "PAYE "
                                : paymentType === "ltd"
                                ? "LTD "
                                : "Umbrella "}
                              Rate / Hr
                            </FormLabel>
                            <FormInputWithSymbol
                              before
                              disabled={false}
                              min="0"
                              onChange={(e) => {
                                const updatedRates = rates.map((cc) =>
                                  cc.id === rates[index].id
                                    ? {
                                        ...cc,
                                        workerPayeRate: Number(e.target.value),
                                        ltdUmbrellaRate: Number(e.target.value),
                                      }
                                    : cc
                                );

                                handleFilter(updatedRates, "rates");
                                handleFilter(false, "skip2");
                              }}
                              onKeyDown={handleNegativeInput}
                              symbol=" £ "
                              type="number"
                              value={
                                paymentType === "paye"
                                  ? rate.workerPayeRate === 0
                                    ? null
                                    : rate.workerPayeRate
                                  : rate.ltdUmbrellaRate === 0
                                  ? null
                                  : rate.ltdUmbrellaRate
                              }
                            />

                            {rate.errors.map(
                              (err) =>
                                err.attribute === "worker_rate" && (
                                  <ErrorMessage>{err.message}</ErrorMessage>
                                )
                            )}

                            {paymentType === "paye"
                              ? rate.workerPayeRateDiff !== 0 &&
                                rateCardCalculator(rate.workerPayeRateDiff)
                              : rate.ltdUmbrellaRateDiff !== 0 &&
                                rateCardCalculator(rate.ltdUmbrellaRateDiff)}
                          </FormGroup>
                        )}
                      </ShiftCol>
                    </ShiftModalRatesGrid>
                  );
                }
                return null;
              })}

              <SliderDots>
                {rates.map((_cc, index) => (
                  <SliderDot active={rateTab === index + 1} />
                ))}
              </SliderDots>
            </SliderBody>
          </SliderWrapper>

          {rateTab !== rates.length + 1 ? (
            <>
              <CalculatedRates
                className={inputs.showMath && "show"}
                onClick={() => handleFilter(!inputs.showMath, "showMath")}
              >
                How are these rates calculated?
              </CalculatedRates>

              <RatesInfo className={inputs.showMath && "show"}>
                <>
                  <span>
                    {employmentType === "direct_engagement"
                      ? "Comparator Rate / Hr"
                      : "Charge Rate / Hr"}
                  </span>
                  <p>{rates[rateTab - 1]?.comparatorRateBreakdown} </p>

                  <span>
                    Agency Margin / Hr
                    {employmentType === "direct_engagement" && "(Charge)"}
                  </span>
                  <p>{rates[rateTab - 1]?.agencyMarginBreakdown}</p>

                  {paymentType === "paye" && (
                    <>
                      <span>Worker PAYE Rate</span>
                      <p>{rates[rateTab - 1]?.workerPayeRateBreakdown}</p>
                    </>
                  )}

                  {paymentType !== "paye" && (
                    <>
                      <span>
                        {paymentType === "ltd"
                          ? "Worker LTD Rate"
                          : "Worker Umbrella Rate"}
                      </span>
                      <p>{rates[rateTab - 1]?.ltdUmbrellaRateBreakdown}</p>
                    </>
                  )}
                </>
              </RatesInfo>
              <ButtonWrapper>
                <DefaultButton
                  action={populateRatesToAllPayElements}
                  color="blue"
                  text="Apply rates to all pay elements"
                />
              </ButtonWrapper>
            </>
          ) : (
            <CalculatedRates />
          )}
        </>
      )}

      {rates.length === 0 && (
        <p>
          You cannot propose worker to this block as there is no rate card
          currently attached to this grade
        </p>
      )}
    </ShiftModalRates>
  );
}

export default BlockRates;

const ShiftModalRates = styled.div`
  margin-top: -20px;
  padding-bottom: 60px;
`;

const ShiftModalRatesGrid = styled.div<{ readOnly?: boolean }>`
  display: flex;
  margin: auto;
  opacity: ${({ readOnly }) => (readOnly ? "0.75" : "1")};
  padding-top: 10px;
  width: 600px;
  * {
    pointer-events: ${({ readOnly }) => (readOnly ? "none" : "initial")};
  }

  label {
    color: ${GREY.ten};
    font-size: 13px;
    font-weight: 600;
    line-height: 5px;
  }

  p {
    color: ${GREY.ten};
    font-size: 13px;
    font-weight: normal;
  }
`;

const ShiftCol = styled.div`
  flex: 1;
  margin: 10px 10px 0px;
  position: relative;
`;

const ShiftModalRateCard = styled.div`
  background: ${GREY.one};
  border: 1px solid ${GREY.two};
  border-radius: 8px;
  box-sizing: border-box;
  height: 62px;
  margin-bottom: 22px;
  padding: 11px 16px;
  position: relative;
  width: 100%;

  span {
    font-weight: 600;
  }

  .agency_popup {
    align-items: center;
    background: #002033;
    border-radius: 8px;
    color: white;
    display: none;
    font-size: 13px;
    font-weight: normal;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -80px;
    top: -45px;
    width: 202px;
    z-index: 100;

    &.show {
      display: flex;
    }
  }

  .agency__icon {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 80px;
    width: 20px;

    svg {
      left: 0px;
      position: absolute;
      top: 3px;
    }
  }

  svg {
    bottom: 12px;
    left: 60px;
    position: absolute;
  }
`;

const CalculatedRates = styled.span`
  color: ${GREY.six};
  cursor: pointer;
  margin-left: 90px;
  text-decoration: underline;
  width: 220px;

  &:hover {
    color: ${GREY.ten};
  }
`;

const RatesInfo = styled.div`
  display: none;
  margin-left: 90px;
  margin-top: 10px;
  width: 449px;

  &.show {
    display: block;
  }

  label {
    color: ${GREY.eight};
    font-size: 12px;
    font-weight: bold;
    line-height: 7px;
  }

  p {
    font-size: 12px;
    font-weight: normal;
  }
`;

const ErrorMessage = styled.div`
  color: #dc5a54;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  margin-top: 0px;
`;

const RateCardChange = styled.h6`
  align-items: center;
  background: ${ORANGE.one};
  border-radius: 4px;
  color: #c54714;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  justify-content: center;
  min-width: 41px;
  padding: 2px 9px;
  position: absolute;
  right: 12px;
  top: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 35px;
`;

/* SLIDER *********************************************** */

const SliderWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e4e7eb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px auto 50px;
  max-width: 700px;
  width: 100%;
`;

const SliderHeader = styled.div`
  border-bottom: 1px solid #e4e7eb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  height: 62px;
`;

const SliderTab = styled.div<{
  active: boolean;
  rates: number;
  start?: boolean;
  end?: boolean;
}>`
  align-items: center;
  align-items: center;
  background: ${({ active }) => (active ? "#17BFD6" : "white")};
  border-top-left-radius: ${({ start }) => start && "8px"};
  border-top-right-radius: ${({ end }) => end && "8px"};
  color: ${({ active }) => (active ? "white" : "#002033")};
  cursor: pointer;
  display: flex;
  display: flex;
  flex: 1;
  font-size: ${({ rates }) => (rates < 3 ? "16px" : "12px")};
  font-weight: 500;
  justify-content: center;
  line-height: 1.25;
  padding: 0 10px;
  position: relative;
  text-align: center;
  transition: all 0.25s ease-in-out;

  &:not(:last-child) {
    border-right: 1px solid #e4e7eb;
  }
`;

const SliderArrow = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? "#17BFD6" : "transparent")};
  clip-path: polygon(72% 26%, 100% 50%, 72% 75%, 72% 48%);
  height: 60px;
  position: absolute;
  right: -14px;
  top: 0px;
  transition: all 0.25s ease-in-out;
  width: 60px;
  z-index: 20;
`;

const SliderBody = styled.div`
  height: 188px;
  position: relative;
`;

export const PreviousButton = styled.button<{
  disabled: boolean;
  calendar?: boolean;
}>`
  align-items: center;
  background: ${({ disabled }) => (disabled ? "#CCD2D8" : "white")};
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  display: flex;
  height: 32px;
  justify-content: center;
  left: ${({ calendar }) => (calendar ? "-20px" : "-61px")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  position: ${({ calendar }) => (calendar ? "relative" : "absolute")};
  top: ${({ calendar }) => (calendar ? "initial" : "80px")};
  width: 32px;
  &:hover {
    background: ${GREY.two};
  }
`;

export const NextButton = styled.button<{
  disabled: boolean;
  calendar?: boolean;
}>`
  background: ${({ disabled }) => (disabled ? "#CCD2D8" : "white")};
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  height: 32px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  position: ${({ calendar }) => (calendar ? "relative" : "absolute")};
  right: ${({ calendar }) => (calendar ? "0px" : "-61px")};
  top: ${({ calendar }) => (calendar ? "initial" : "80px")};
  width: 32px;
  &:hover {
    background: ${GREY.two};
  }
`;

const SliderDots = styled.div`
  bottom: -30px;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
`;

const SliderDot = styled.div<{ active: boolean }>`
  background: #7d8793;
  border-radius: 50%;
  height: 10px;
  margin-right: 16px;
  mix-blend-mode: normal;
  opacity: ${({ active }) => (active ? "1" : "0.43")};
  transition: all 0.4s ease-in-out;
  width: 10px;
`;
