import dayjs from "dayjs";
//---------------------------------------------------------------- Local Storage
const TOKEN_KEY = process.env.REACT_APP_TOKENS;

export function saveTokens(tokens, sessionType?) {
  if (sessionType === "session") {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
  } else {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
  }
}

export function getTokens() {
  const doesSessionExist = JSON.parse(sessionStorage.getItem(TOKEN_KEY));
  if (doesSessionExist !== null) {
    return JSON.parse(sessionStorage.getItem(TOKEN_KEY));
  } else {
    return JSON.parse(localStorage.getItem(TOKEN_KEY));
  }
}

export function deleteTokens() {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("bannerDismissed");
}

////--------------------------------------------------------- Format Server Errors

export function FormatError(
  errors: Array<{
    attribute?: string | null;
    message: string | null;
    __typename?: string;
  }>
) {
  const { attribute, message } = errors[0];

  const replaceUnderscoreWithSpace = (string: string) =>
    string.replace(/_/g, " ");

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  if (attribute === null && message === null) return "Error";
  if (attribute === null) return capitalizeFirstLetter(message);

  return (
    replaceUnderscoreWithSpace(capitalizeFirstLetter(attribute)) + " " + message
  );
}

//------------------------------------------------------------- Formatting React Select

export const formatToIds = (arrayOrObject) => {
  if (!arrayOrObject) return null;
  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.map((e) => +e.value || +e.id || +e.idValue);
  } else {
    return arrayOrObject.value || arrayOrObject.id || arrayOrObject.idValue;
  }
};

// re-format for react-select
export const formatForReactSelect = (data, includeOtherProperties?) => {
  if (!data) return;
  const FindValueAndLabel = (object) => {
    //takes an object and formats it for react-select
    //by finding an id value (number) and label (string)
    const cloneObject = { ...object };
    delete cloneObject.__typename;
    const arrayOfPropertyValues = Object.values(cloneObject);
    const filteredArrayOfPropertyValues = arrayOfPropertyValues.filter(
      (e) => typeof e === "number" || typeof e === "string"
    );

    let value;
    let label;

    if (
      typeof filteredArrayOfPropertyValues[0] === "number" ||
      !Number.isNaN(Number(filteredArrayOfPropertyValues[0]))
      // some id's come back as strings, try to coerce into number
    ) {
      value = filteredArrayOfPropertyValues[0];
      label = filteredArrayOfPropertyValues[1];
    } else {
      value = filteredArrayOfPropertyValues[1];
      label = filteredArrayOfPropertyValues[0];
    }

    if (includeOtherProperties === "includeOtherProperties") {
      return { ...object, value, label };
    } else {
      return { value, label };
    }
  };
  if (Array.isArray(data)) {
    return data?.map((e) => FindValueAndLabel(e));
  } else {
    return FindValueAndLabel(data);
  }
};

//-- handling imgs

export const defaultImgOnError = (event) => {
  event.currentTarget.onerror = null; // prevents looping
  event.currentTarget.src =
    "https://api.patchwork.health/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg";
};

export const defaultImgURL =
  "/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg";

///-------------------------------------------------------------- Modal Functions

export const createFormFunctions = (
  setInputsFunc: React.Dispatch<any>,
  setErrorFunc?: React.Dispatch<any>
) => {
  return {
    handleFilter: (event, inputNameToUpdateStateObject: string) => {
      setErrorFunc?.(null);
      setInputsFunc((s) => ({
        ...s,
        [inputNameToUpdateStateObject]: event ?? [],
      }));
    },

    handleInputChange: (event) => {
      setErrorFunc?.(null);
      setInputsFunc((s) => ({
        ...s,
        [event.target.name]: event.target.value,
      }));
    },
    handleToggle: (event) => {
      setInputsFunc((s) => ({
        ...s,
        [event.target.name]: !s[event.target.name],
      }));
    },
    handleFileInput: (event) => {
      setErrorFunc?.(null);
      setInputsFunc((s) => ({
        ...s,
        [event.target.name]: event.target.files[0],
      }));
    },
    handleDateChange: (day, inputName) => {
      if (day === undefined) {
        setInputsFunc((inputs) => ({
          ...inputs,
          [inputName]: undefined,
        }));
      } else {
        setInputsFunc((inputs) => ({
          ...inputs,
          [inputName]: dayjs(day).format("DD/MM/YYYY"),
        }));
      }
    },
  };
};

export const paginationOptions = [
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 100,
    value: 100,
  },
];
