// @ts-nocheck
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import styled from "styled-components";

import { useGetAgencyShiftsLazyQuery } from "components/AgencyShifts/__generated__/AgencyShiftsHelpers.generated";
import AgencyShiftsModal from "components/AgencyShifts/AgencyShiftsModal";
import AgencyShiftsTable from "components/AgencyShifts/AgencyShiftsTable";
import AgencyWorkerModalNew from "components/AgencyWorkers/AgencyWorkersModalNew";
import Filters from "components/Filters";
import TableHeader from "components/TableHeader/TableHeader";
import { createFormFunctions, formatToIds } from "helpers/functions";
import { usePagination } from "helpers/usePagination";
import { RootState } from "models/store";

// import CalendarPage from "./calendar";else

const emptyInputs = {
  workerShow: false,
  agencyWorker: null,
  show: false,
  searchText: "",
  filtersShow: false,
  blocks: false,
  //
  tab: 1,
  shiftId: null,
  //
  shiftIds: null,
  siteIds: [],
  departmentIds: [],
  staffGroupIds: [],
  gradeIds: [],
  agencyWorkerIds: [],
  bookedByAgency: null,
  fromDateValue: dayjs(new Date()).format("YYYY-MM-DD"),
  toDateValue: null,
  status: null,
};

const ShiftsPage = () => {
  // STATE ************************************

  const [inputs, setInputs] = useState(emptyInputs);
  const [organisationIds, setOrganisationIds] = useState([]);
  const [pagination, setPagination, resetPagination] = usePagination();

  const organisation = useSelector((s: RootState) => s.organisation);
  const { handleFilter } = createFormFunctions(setInputs);

  // GRAPHQL **********************************

  useEffect(() => {
    if (organisation) {
      setOrganisationIds(
        organisation.map((cc) => cc.organisationStaffGroup.organisation)
      );
    }
  }, [organisation]);

  const [
    getAgencyShifts,
    { data: agencyShifts, refetch: refetchWorkers, loading },
  ] = useGetAgencyShiftsLazyQuery({
    variables: {
      ...pagination,
      shiftIds:
        inputs.shiftIds === [] || inputs.shiftIds === null
          ? undefined
          : inputs.shiftIds,
      siteIds: formatToIds(inputs.siteIds),
      departmentIds: formatToIds(inputs.departmentIds),
      staffGroupIds: formatToIds(inputs.staffGroupIds),
      gradeIds: formatToIds(inputs.gradeIds),
      agencyWorkerIds: formatToIds(inputs.agencyWorkerIds),
      bookedByAgency: inputs.bookedByAgency?.value === "true" ? true : null,
      fromStartTime: inputs.fromDateValue,
      toStartTime: inputs.toDateValue,
      status: inputs.status ? inputs.status.value : null,
      organisationIds: formatToIds(organisationIds),
      filterByShiftBlocks: false,
    },
    pollInterval: 60000,
    fetchPolicy: "no-cache",
    // nextFetchPolicy: "no-cache",
  });

  useEffect(() => {
    getAgencyShifts();
  }, [getAgencyShifts]);

  useEffect(() => {
    if (inputs.searchText?.length > 0) {
      setInputs({
        ...inputs,
        shiftIds: inputs.searchText.split(",").map(Number),
      });
    } else {
      setInputs({ ...inputs, shiftIds: null });
    }
  }, [inputs.searchText]);

  const setShowShift = (shiftId) => {
    setInputs({ ...inputs, show: true, shiftId: shiftId ?? null });
  };

  const handleCloseAction = () => {
    setInputs({ ...inputs, show: false });
  };

  const setShowWorker = (agencyWorker) => {
    setInputs({ ...inputs, workerShow: true, agencyWorker });
  };

  const handleCloseWorker = () => {
    setInputs({ ...inputs, workerShow: false });
  };

  const pageInfo = agencyShifts?.vmsShifts?.pageInfo ?? "";

  let filteredAgencyShifts = agencyShifts?.vmsShifts?.edges?.map(
    (shift) => shift.node
  );

  return (
    <>
      {inputs.tab === 1 && (
        <>
          <TableHeader
            buttonIcon={IconFilter}
            buttonText="Filters"
            buttonType="white"
            // calendar={true}
            dataCount={agencyShifts?.vmsShifts?.totalCount}
            // handleCalendar={() => handleFilter(2, "tab")}
            handleShow={() => handleFilter(!inputs.filtersShow, "filtersShow")}
            inputPlaceholder="Search Shift IDs"
            resetPagination={resetPagination}
            setSearchText={(e) => handleFilter(e, "searchText")}
            title="Shifts"
          />

          <FiltersContainer show={inputs.filtersShow}>
            <Filters
              filtersHandleFilter={handleFilter}
              filtersInputs={inputs}
              resetPagination={resetPagination}
              setFiltersInputs={() => setInputs(emptyInputs)}
            />
          </FiltersContainer>
        </>
      )}

      {inputs.tab === 1 && (
        <AgencyShiftsTable
          agencyShifts={filteredAgencyShifts ? filteredAgencyShifts : []}
          loading={loading}
          pageInfo={pageInfo}
          pagination={pagination}
          setPagination={setPagination}
          setShowShift={setShowShift}
          setShowWorker={setShowWorker}
        />
      )}

      {/* {inputs.tab === 2 && (
        <CalendarPage
          handleCalendar={() => handleFilter(1, "tab")}
          setShowShift={setShowShift}
        />
      )} */}

      {inputs.show && (
        <AgencyShiftsModal
          handleCloseAction={handleCloseAction}
          refetchWorkers={refetchWorkers}
          setShowWorker={setShowWorker}
          shiftId={inputs.shiftId}
          show={inputs.show}
        />
      )}

      {inputs.workerShow && (
        <AgencyWorkerModalNew
          agencyWorker={inputs.agencyWorker}
          handleCloseAction={handleCloseWorker}
          readonly={true}
          refetchWorkers={() => refetchWorkers()}
          show={inputs.workerShow}
        />
      )}
    </>
  );
};

export default ShiftsPage;

export const FiltersContainer = styled.div<{ show?: boolean }>`
  margin-bottom: ${({ show }) => (show ? "24px" : 0)};
  max-height: ${({ show }) => (show ? "300px" : 0)};
  opacity: ${({ show }) => (show ? "1" : "0")};
  overflow: ${({ show }) => (show ? "visible" : "hidden")};
  transition: 200ms ease-in-out;
`;

export function IconFilter() {
  return (
    <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.333 2.5H1.667l6.666 7.883v5.45l3.334 1.667v-7.117L18.333 2.5Z"
        stroke="#7D8793"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
