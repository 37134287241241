import styled from "styled-components";

import {
  BLUE,
  GLOBAL,
  GREEN,
  GREY,
  YELLOW,
} from "@patchworkhealth/web-components";

/* 1. SORT SHIFTS **********************************************/

export const sortCalendarShifts = (bank, setEvents) => {
  const sortedShifts = bank.vmsShifts.edges
    .map((cc) => cc.node?.startTime.slice(0, 10))
    .filter((startDate, index, array) => {
      return array.indexOf(startDate) === index;
    });

  const newShifts = sortedShifts.map((startDate) => ({
    date: startDate,
    bank: bank.vmsShifts.edges
      .filter((cc) => cc.node.startTime.slice(0, 10) === startDate)
      .map((dd) => ({
        node: {
          ...dd.node,
          status:
            dd.node.status === "TO_SIGN_OFF_ON_HUB_OR_APP"
              ? "TO_SIGN_OFF_ON_HUB"
              : dd.node.status,
        },
      }))
      .flat(),
  }));
  setEvents(newShifts);
};

/* 1. SORT SHIFTS **********************************************/

export const sortCalendarBlocks = (shifts, setEvents) => {
  const sortedShifts = shifts
    .map((cc) => cc.startTime.slice(0, 10))
    .filter((startDate, index, array) => {
      return array.indexOf(startDate) === index;
    });

  const newShifts = sortedShifts.map((startDate) => ({
    date: startDate,
    bank: shifts.filter((cc) => cc.startTime.slice(0, 10) === startDate),
  }));

  setEvents(newShifts, "events");
};

export const MultiDayShift = styled.div`
  align-items: center;
  background: #f5f7fa;
  border: 1px solid #398f9e;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 51px;
  padding: 6px 8px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  width: 100%;
  &:hover {
    background: ${GREY.two};
  }
  h4 {
    align-items: center;
    color: #002033;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0;
  }
  svg {
    bottom: 8px;
    position: absolute;
    right: 7px;
  }
  span {
    align-items: center;
    color: #398f9e;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const MultiShiftIcon = () => (
  <svg
    fill="none"
    height="7"
    viewBox="0 0 7 7"
    width="7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3.5" cy="3.5" fill="#57A886" r="3.5" />
  </svg>
);

export const BankShifts = ({ type, number, onClick, blocks }) => {
  return (
    <ShiftStatus blocks={blocks} onClick={onClick} type={type}>
      {!blocks && (
        <div className="circle__outer">
          <div className="circle__inner"></div>
        </div>
      )}

      <p>
        {number}{" "}
        {blocks
          ? ""
          : type === "TO_SIGN_OFF_ON_HUB_OR_APP"
          ? "To Sign Off"
          : type === "TO_SIGN_OFF_ON_HUB"
          ? "To Sign Off"
          : type === "SIGNED_OFF"
          ? "Signed Off"
          : type === "PROVISIONALLY_BOOKED"
          ? "Prov-Booked"
          : type === "SIGN_OFF_REQUESTED"
          ? "Sign-off Requested"
          : type.toLowerCase()}
      </p>
    </ShiftStatus>
  );
};

export const ShiftStatus = styled.div<{ type: string; blocks: boolean }>`
  align-items: center;
  background: ${(props) =>
    (props.type === "URGENT" && "#fff3f5") ||
    (props.type === "APPLIED" && YELLOW.one) ||
    (props.type === "BOOKED" && "#F2FCF6") ||
    (props.type === "TO_SIGN_OFF_ON_HUB_OR_APP" && BLUE.one) ||
    (props.type === "SIGNED_OFF" && BLUE.one) ||
    (props.type === "TO_SIGN_OFF_ON_HUB" && BLUE.one) ||
    (props.type === "SIGN_OFF_REQUESTED" && GREY.two) ||
    (props.type === "SIGN_OFF_REQUESTED" && GREY.two) ||
    (props.type === "APPROVED" && "#ffedb7") ||
    (props.type === "PAID" && GREY.nine) ||
    (props.type === "AVAILABLE" && "#FEF6F3")};
  border: 1px solid
    ${(props) =>
      (props.type === "URGENT" && "#f9d0d5") ||
      (props.type === "APPLIED" && YELLOW.five) ||
      (props.type === "BOOKED" && "#85D3B0") ||
      (props.type === "TO_SIGN_OFF_ON_HUB_OR_APP" && BLUE.three) ||
      (props.type === "TO_SIGN_OFF_ON_HUB" && BLUE.three) ||
      (props.type === "SIGNED_OFF" && BLUE.three) ||
      (props.type === "SIGN_OFF_REQUESTED" && GREY.three) ||
      (props.type === "APPROVED" && "#f5ca47") ||
      (props.type === "PAID" && GREY.ten) ||
      (props.type === "AVAILABLE" && "#ffc8b2")};
  border-radius: 4px;
  cursor: ${(props) =>
    props.type === "BOOKED" || props.type === "APPLIED"
      ? "pointer"
      : "pointer"};
  display: flex;
  height: 24px;
  line-height: 12px;
  margin-bottom: 3px;

  opacity: ${(props) =>
    props.blocks && props.type === "SIGN_OFF_REQUESTED" && "0.5"};
  padding-left: 10px;
  width: ${(props) => (props.blocks ? "100px" : "123px")};

  .circle__outer {
    background: ${(props) =>
      (props.type === "URGENT" && "#f9d0d5") ||
      (props.type === "APPLIED" && YELLOW.five) ||
      (props.type === "BOOKED" && "#85D3B0") ||
      (props.type === "TO_SIGN_OFF_ON_HUB_OR_APP" && BLUE.four) ||
      (props.type === "TO_SIGN_OFF_ON_HUB" && BLUE.four) ||
      (props.type === "SIGN_OFF_REQUESTED" && GREY.four) ||
      (props.type === "SIGNED_OFF" && BLUE.four) ||
      (props.type === "APPROVED" && "#f5ca47") ||
      (props.type === "PAID" && GREY.four) ||
      (props.type === "AVAILABLE" && "#FFC8B2")};
    border-radius: 50%;
    height: 8px;
    margin-right: 7px !important;
    position: relative;
    width: 8px;
  }
  .circle__inner {
    background: ${(props) =>
      (props.type === "URGENT" && "#c93346") ||
      (props.type === "APPLIED" && YELLOW.six) ||
      (props.type === "BOOKED" && "#316959") ||
      (props.type === "TO_SIGN_OFF_ON_HUB_OR_APP" && GREEN.five) ||
      (props.type === "TO_SIGN_OFF_ON_HUB" && GREEN.five) ||
      (props.type === "SIGNED_OFF" && GREEN.five) ||
      (props.type === "SIGN_OFF_REQUESTED" && GREY.ten) ||
      (props.type === "APPROVED" && "#a48428") ||
      (props.type === "PAID" && GLOBAL.white) ||
      (props.type === "AVAILABLE" && "#FF763F")};
    border-radius: 50% !important;
    height: 4px !important;
    left: 50% !important;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
  }

  p {
    color: ${(props) =>
      (props.type === "URGENT" && "#c93346") ||
      (props.type === "BOOKED" && GREEN.five) ||
      (props.type === "APPLIED" && YELLOW.six) ||
      (props.type === "APPROVED" && "#a48428") ||
      (props.type === "PAID" && GLOBAL.white) ||
      (props.type === "AVAILABLE" && "#FF763F")};
    font-size: 10px;
    font-weight: 600;
    text-transform: capitalize;
  }
`;
