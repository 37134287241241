import { Models } from "@rematch/core";

import { organisation } from "./organisation";
import { organisations } from "./organisations";
import { staffGroups } from "./staffGroups";
import { user } from "./user";

export interface RootModel extends Models<RootModel> {
  organisation: typeof organisation;
  organisations: typeof organisations;
  staffGroups: typeof staffGroups;
  user: typeof user;
}

export const models: RootModel = {
  organisation,
  organisations,
  staffGroups,
  user,
};
