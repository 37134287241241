export const CrossIcon = () => (
  <svg
    fill="none"
    style={{ float: "left", marginRight: "24px", height: "100%" }}
    viewBox="0 0 20 20"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1601_1573)">
      <path
        clipRule="evenodd"
        d="M10.0002 2.50016C5.85803 2.50016 2.50016 5.85803 2.50016 10.0002C2.50016 14.1423 5.85803 17.5002 10.0002 17.5002C14.1423 17.5002 17.5002 14.1423 17.5002 10.0002C17.5002 5.85803 14.1423 2.50016 10.0002 2.50016ZM0.833496 10.0002C0.833496 4.93755 4.93755 0.833496 10.0002 0.833496C15.0628 0.833496 19.1668 4.93755 19.1668 10.0002C19.1668 15.0628 15.0628 19.1668 10.0002 19.1668C4.93755 19.1668 0.833496 15.0628 0.833496 10.0002ZM13.0894 6.91092C13.4149 7.23635 13.4149 7.76399 13.0894 8.08943L11.1787 10.0002L13.0894 11.9109C13.4149 12.2364 13.4149 12.764 13.0894 13.0894C12.764 13.4149 12.2363 13.4149 11.9109 13.0894L10.0002 11.1787L8.08942 13.0894C7.76398 13.4149 7.23635 13.4149 6.91091 13.0894C6.58547 12.764 6.58547 12.2364 6.91091 11.9109L8.82165 10.0002L6.91091 8.08943C6.58547 7.76399 6.58547 7.23635 6.91091 6.91092C7.23635 6.58548 7.76398 6.58548 8.08942 6.91092L10.0002 8.82166L11.9109 6.91092C12.2363 6.58548 12.764 6.58548 13.0894 6.91092Z"
        fill="#B03943"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1601_1573">
        <rect fill="white" height="20" width="20" />
      </clipPath>
    </defs>
  </svg>
);
