import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { client } from "apolloClient";

import { GlobalStyle } from "@patchworkhealth/web-components";

import { store } from "models/store";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features";
import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import App from "./App";

if (window.location.hostname !== "localhost") {
  const isStaging =
    process.env.REACT_APP_API === "https://api.staging.patchwork.health"; // Adjust this check based on your actual staging URL

  datadogRum.init({
    applicationId: "927dd44e-2b2b-43f2-93fe-70fa6b0987be",
    clientToken: "pub58f3ec16c29a84dd778cb8ac1f5466c1",
    site: "datadoghq.eu",
    service: "agency",
    sessionSampleRate: 100,
    sessionReplaySampleRate: isStaging ? 0 : 20, // Set to 0 to disable in staging environments
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    env: isStaging ? "staging" : "production",
  });
}

ReactDOM.render(
  <>
    <GlobalStyle />
    <Toaster
      toastOptions={{
        success: {
          duration: 5000,
        },
      }}
    />
    <Toaster />

    <ApolloProvider client={client}>

        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>

    </ApolloProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
