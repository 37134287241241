import { AgencyShiftFragment } from "../__generated__/AgencyShiftsHelpers.generated";
import { ShiftModalTitle } from "./ShiftModalComponents";

type ShiftModalAssignWorkerProps = {
  shift?: AgencyShiftFragment;
};

const ShiftModalAssignWorker = () => {
  return (
    <div>
      <ShiftModalTitle>Assign Agency Worker for Shift</ShiftModalTitle>
    </div>
  );
};

export default ShiftModalAssignWorker;
