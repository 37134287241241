// @ts-nocheck
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import styled from "styled-components";

import {
  FormBreak,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormSelectMulti,
  FormToggleButtons,
} from "@patchworkhealth/web-components";

import { DownloadIcon, UploadIcon } from "assets/icons/documentIcons";
import { useGradesQuery } from "helpers/__generated__/queries.generated";
import { formatForReactSelect } from "helpers/functions";
import { RootState } from "models/store";

import {
  useSkillsForOrganisationStaffGroupQuery,
  useVmsAgencyRegistrationDeleteUploadedFileMutation,
  useVmsAgencyRegistrationUploadCompliancePackMutation,
  useVmsAgencyRegistrationUploadCvMutation,
} from "./__generated__/AgencyWorkersHelpers.generated";
import { emptyInputs } from "./AgencyWorkersModal";
import { FormGroupDisable } from "./ShiftModalComponents/ShiftModalEmployment";

type AgencyWorkersDetailsPageProps = {
  agencyWorkerPresent: boolean;
  error: any;
  handleFilter: Function;
  handleInputChange: Function;
  handleToggleButtons: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    enumForState: number
  ) => void;
  inputs: typeof emptyInputs;
  agencyWorker: any;
  refetchWorkers: Function;
  invitation?: boolean;
};

type PaymentType = "PAYE" | "Umbrella Company" | "LTD";

// TODO - put into helper file ----------------------------------

const replaceUnderscoreWithSpace = (string) => string.replace(/_/g, " ");

const upperCaseFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export function formatError(errors) {
  const { attribute, message } = errors[0];

  if (!attribute) return upperCaseFirstLetter(message);

  return `${replaceUnderscoreWithSpace(
    upperCaseFirstLetter(attribute)
  )} ${message}`;
}

const AgencyWorkersDetailsPage = ({
  agencyWorker,
  agencyWorkerPresent,
  error,
  handleFilter,
  handleInputChange,
  handleToggleButtons,
  inputs,
  refetchWorkers,
  invitation,
}: AgencyWorkersDetailsPageProps) => {
  const staffGroups = useSelector((s: RootState) => s.staffGroups);
  const org = useSelector((s: RootState) => s.organisation);

  const usesDeSoleTrader =
    org[0].organisationStaffGroup?.organisation.usesDeSoleTrader ?? false;

  const hideDeLtdAgencyWorkers =
    org[0].organisationStaffGroup?.organisation.hideDeLtdAgencyWorkers;

  // GQL *********************************************

  const [uploadDoc] = useVmsAgencyRegistrationUploadCompliancePackMutation();
  const [uploadCv] = useVmsAgencyRegistrationUploadCvMutation();
  const [deleteDoc] = useVmsAgencyRegistrationDeleteUploadedFileMutation();

  const { data: grades } = useGradesQuery({
    skip: inputs.staffGroup.value === null,
    variables: {
      staffGroupId: Number(inputs.staffGroup.value),
      organisationId: Number(org[0].organisationStaffGroup?.organisation?.id),
    },
  });

  // remap staffGroups into an array react-select can read
  const sortedStaffGroups = staffGroups?.map(function (staffGroup) {
    return {
      value: staffGroup.staffGroup.idValue,
      label: staffGroup.staffGroup.title,
      master: staffGroup.id,
    };
  });

  const { data: skillsData } = useSkillsForOrganisationStaffGroupQuery({
    skip: inputs?.staffGroup?.value === null,
    variables: {
      organisationStaffGroupId: Number(
        sortedStaffGroups.find(
          (option) => option.value === inputs?.staffGroup?.value
        )?.master
      ),
    },
  });

  const createPaymentTypeList = (isDe: boolean) => {
    const paymentTypes: PaymentType[] = ["PAYE", "LTD"];

    // If DE And hideDeLtdAgencyWorkers, remove LTD from payment types
    if (isDe && hideDeLtdAgencyWorkers) paymentTypes.pop();

    // If non-direct engagement, add umbrella company to payment types
    if (!isDe) paymentTypes.push("Umbrella Company");
    return paymentTypes;
  };

  // FUNCTION HANDLERS *********************************************

  const uploadComplianceFunction = (e) => {
    e.persist();

    const file = e.target.files[0];
    const toastId6 = toast.loading("Loading...");

    toast.loading("Loading", { id: toastId6 });

    uploadDoc({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        file,
      },
    })
      .then((response) => {
        const errors =
          response.data.vmsAgencyRegistrationUploadCompliancePack.errors;

        if (errors.length > 0) {
          toast.error(formatError(errors), {
            id: toastId6,
          });

          return;
        }

        toast.success("Compliance Pack Uploaded", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  const uploadCvFunction = (e) => {
    e.persist();

    const file = e.target.files[0];
    const toastId6 = toast.loading("Loading...");

    toast.loading("Loading", { id: toastId6 });

    uploadCv({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        file,
      },
    })
      .then((response) => {
        const errors = response.data.vmsAgencyRegistrationUploadCv.errors;

        if (errors.length > 0) {
          toast.error("There was an error with your Document", {
            id: toastId6,
          });

          return;
        }

        toast.success("CV Uploaded", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  const deleteDocumentFunction = (type) => {
    const toastId6 = toast.loading("Loading...");

    deleteDoc({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        uploadType: type,
      },
    })
      .then((response) => {
        const errors =
          response.data.vmsAgencyRegistrationDeleteUploadedFile.errors;

        if (errors.length > 0) {
          toast.error("There was an error with your Document", {
            id: toastId6,
          });

          return;
        }

        toast.success("Document Deleted", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  let sortedSkills = skillsData?.skillsForOrganisationStaffGroup?.map((cc) => ({
    label: cc.name,
    value: cc.id,
  }));

  const disablePaymentTypeClicks =
    agencyWorker?.employmentType === "direct_engagement" ? true : false;

  return (
    <>
      <FormGridContainer>
        {invitation && (
          <InvitationAlert>
            Please double check the information is correct before sending
            activation email.
          </InvitationAlert>
        )}

        <h3
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Personal Info
        </h3>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>
              First Name<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="firstName"
              defaultValue={inputs.firstName}
              name="firstName"
              onChange={handleInputChange}
              placeholder="Enter First Name..."
              type="text"
            />
            {error?.firstName && (
              <FormErrorLabel>{error.firstName}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Last Name<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="lastName"
              defaultValue={inputs.lastName}
              name="lastName"
              onChange={handleInputChange}
              placeholder="Enter Last Name..."
              type="text"
            />
            {error?.lastName && (
              <FormErrorLabel>{error.lastName}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Date of Birth<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="dateOfBirth"
              defaultValue={inputs.dateOfBirth}
              name="dateOfBirth"
              onChange={handleInputChange}
              placeholder="DD/MM/YYYY"
              type="date"
            />
            {error?.dateOfBirth && (
              <FormErrorLabel>{error.dateOfBirth}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormGridContainer>
        <FormGrid columns={2}>
          <FormGroup>
            <FormLabel>
              Gender<span>*</span>
            </FormLabel>
            <FormToggleButtons
              action={handleToggleButtons}
              input={inputs.gender}
              name="gender"
              text={["Male", "Female"]}
            />
            {error?.gender && <FormErrorLabel>{error.gender}</FormErrorLabel>}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormGridContainer>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>
              Email Address<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="email"
              defaultValue={inputs.email}
              name="email"
              onChange={handleInputChange}
              placeholder="Enter Email Address..."
              type="email"
            />
            {error?.email && <FormErrorLabel>{error.email}</FormErrorLabel>}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Phone Number<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="phoneNumber"
              defaultValue={inputs.phoneNumber}
              name="phoneNumber"
              onChange={handleInputChange}
              placeholder="Enter Phone Number..."
              type="text"
            />
            {error?.phoneNumber && (
              <FormErrorLabel>{error.phoneNumber}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Post Code<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="postCode"
              defaultValue={inputs.postcode}
              name="postcode"
              onChange={handleInputChange}
              placeholder="Enter Post Code..."
              type="text"
            />
            {error?.postcode && (
              <FormErrorLabel>{error.postcode}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <h3
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Worker Info
        </h3>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>
              DBS Number<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="dbsNumber"
              defaultValue={inputs.dbsNumber}
              name="dbsNumber"
              onChange={handleInputChange}
              placeholder="eg. 1234567"
              type="dbsNumber"
            />
            {error?.dbsNumber && (
              <FormErrorLabel>{error.dbsNumber}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              National Insurance No.<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="niNumber"
              defaultValue={inputs.niNumber}
              name="niNumber"
              onChange={handleInputChange}
              placeholder="eg. SB123456A"
              type="text"
            />
            {error?.niNumber && (
              <FormErrorLabel>{error.niNumber}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormGridContainer>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>
              Staff Group<span>*</span>
            </FormLabel>
            <FormSelect
              data-cy="staffGroup"
              isDisabled={agencyWorkerPresent}
              onChange={(e) => {
                handleFilter(e, "staffGroup");
                handleFilter([], "skillIds");
              }}
              options={sortedStaffGroups}
              placeholder="Select Staff Group"
              value={
                inputs.staffGroup?.value && {
                  value: inputs.staffGroup.value,
                  label: inputs.staffGroup.label,
                }
              }
            />
            {error?.staffGroup && (
              <FormErrorLabel>{error.staffGroup}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Registration Body No.<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="regBodyNumber"
              defaultValue={inputs.regBodyNumber}
              name="regBodyNumber"
              onChange={handleInputChange}
              placeholder="eg. 123456"
              type="text"
            />
            {error?.regBodyNumber && (
              <FormErrorLabel>{error.regBodyNumber}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>
              Grade<span>*</span>
            </FormLabel>
            <FormSelect
              data-cy="grade"
              onChange={(e) => {
                handleFilter(e, "grade");
              }}
              options={formatForReactSelect(grades?.grades)}
              placeholder="Select Grade"
              value={
                inputs.grade?.value && {
                  value: inputs.grade.value,
                  label: inputs.grade.label,
                }
              }
            />
            {error?.grade && <FormErrorLabel>{error.grade}</FormErrorLabel>}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormGridContainer>
        <FormGrid columns={3}>
          <FormGroup>
            <FormLabel>Specialities</FormLabel>
            <FormSelectMulti
              data-cy="specialities"
              options={formatForReactSelect([])}
              placeholder="Select Specialities"
            />
            {error?.specialityIds && (
              <FormErrorLabel>{error.specialityIds}</FormErrorLabel>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel>Skills</FormLabel>
            <FormSelectMulti
              data-cy="skills"
              onChange={(e) => handleFilter(e, "skillIds")}
              options={sortedSkills ? formatForReactSelect(sortedSkills) : []}
              placeholder="Select Skills"
              value={inputs.skillIds}
            />
            {error?.skillIds && (
              <FormErrorLabel>{error.skillIds}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />
      <FormGridContainer>
        <h3
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Employment Info
        </h3>
        <FormGrid columns={3}>
          <FormGroupDisable
            disable={agencyWorker ? true : false}
            gridColumn="1/3"
          >
            <FormLabel>
              Employment Type<span>*</span>
            </FormLabel>
            <FormToggleButtons
              action={handleToggleButtons}
              disabledIndices={agencyWorkerPresent === true ? [0, 1] : []}
              input={inputs.employmentType}
              name="employmentType"
              text={["Direct Engagement", "Non-Direct Engagement"]}
            />
            {error?.employmentType && (
              <FormErrorLabel>{error.employmentType}</FormErrorLabel>
            )}
          </FormGroupDisable>
        </FormGrid>
      </FormGridContainer>
      <FormGridContainer>
        <FormGrid columns={4}>
          {!inputs.isSoleTrader && (
            <FormGroup
              gridColumn="1/5"
              style={{
                pointerEvents: disablePaymentTypeClicks ? "none" : "initial",
              }}
            >
              <FormLabel>
                Payment Type<span>*</span>
              </FormLabel>
              <FormToggleButtons
                action={handleToggleButtons}
                input={inputs.paymentType}
                name="paymentType"
                text={createPaymentTypeList(inputs.employmentType === 0)}
              />
              {error?.paymentType && (
                <FormErrorLabel>{error.paymentType}</FormErrorLabel>
              )}
            </FormGroup>
          )}

          {/* VieW existing Agency Worker - Sole Trader */}

          {agencyWorker && agencyWorker.isSoleTrader && (
            <IsSoleTraderButton>
              <span style={{ marginRight: 8, fontWeight: 600 }}>
                Is Sole Trader?
              </span>

              <ToggleButton
                aria-checked={true}
                aria-labelledby={`${123} labelId`}
                checked={inputs.isSoleTrader}
                disabled={!!agencyWorker}
                onClick={() => {
                  //toggle isSoleTrader
                  handleFilter(
                    inputs.isSoleTrader ? false : true,
                    "isSoleTrader"
                  );
                }}
                role="switch"
              >
                <ToggleButtonHandle checked={inputs.isSoleTrader} />
              </ToggleButton>
            </IsSoleTraderButton>
          )}

          {/* Create New Agency Worker  - Sole Trader */}
          {!agencyWorker && inputs.employmentType === 0 && usesDeSoleTrader && (
            <IsSoleTraderButton>
              <span style={{ marginRight: 8, fontWeight: 600 }}>
                Is Sole Trader?
              </span>

              <ToggleButton
                aria-checked={true}
                aria-labelledby={`${123} labelId`}
                checked={inputs.isSoleTrader}
                disabled={!!agencyWorker}
                onClick={() => {
                  //toggle isSoleTrader
                  handleFilter(
                    inputs.isSoleTrader ? false : true,
                    "isSoleTrader"
                  );
                }}
                role="switch"
              >
                <ToggleButtonHandle checked={inputs.isSoleTrader} />
              </ToggleButton>
            </IsSoleTraderButton>
          )}
        </FormGrid>
      </FormGridContainer>

      {/* 
        DE:
        - De - PAYE -> nothing
        - DE - ltd -> sees all fields      

        Non-DE
        - NonDE - Paye -> nothing
        - NonDE - Ltd -> company Name
        - NonDE - Umbrella -> company Name           
      */}

      {/*  DE && !PAYE --------------------------------------------------*/}
      {inputs.employmentType === 0 &&
        inputs.paymentType === 1 &&
        !inputs.isSoleTrader && (
          <FormGridContainer>
            <FormBreak />
            <h3
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginBottom: 20,
              }}
            >
              Company Info
            </h3>

            <FormGrid columns={2} style={{ marginBottom: 22 }}>
              <FormGroup>
                <FormLabel>
                  Name<span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyName"
                  defaultValue={inputs.companyName}
                  name="companyName"
                  onChange={handleInputChange}
                  placeholder="Name..."
                  type="text"
                />
                {error?.companyName && (
                  <FormErrorLabel>{error.companyName}</FormErrorLabel>
                )}
              </FormGroup>
              {!inputs.isSoleTrader && (
                <FormGroup>
                  <FormLabel>
                    Company Number <span>*</span>
                  </FormLabel>
                  <FormInput
                    data-cy="companyNumber"
                    defaultValue={inputs.companyNumber}
                    name="companyNumber"
                    onChange={handleInputChange}
                    placeholder="Company Number..."
                    type="text"
                  />
                  {error?.companyNumber && (
                    <FormErrorLabel>{error.companyNumber}</FormErrorLabel>
                  )}
                </FormGroup>
              )}
            </FormGrid>

            <FormGrid columns={2}>
              <FormGroup>
                <FormLabel>
                  Address Line 1 <span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyAddressLine1"
                  defaultValue={inputs.companyAddressLine1}
                  name="companyAddressLine1"
                  onChange={handleInputChange}
                  placeholder="Address Line 1..."
                  type="text"
                />
                {error?.companyAddressLine1 && (
                  <FormErrorLabel>{error.companyAddressLine1}</FormErrorLabel>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>Address Line 2</FormLabel>
                <FormInput
                  data-cy="companyAddressLine2"
                  defaultValue={inputs.companyAddressLine2}
                  name="companyAddressLine2"
                  onChange={handleInputChange}
                  placeholder="Address Line 2..."
                  type="text"
                />
                {error?.companyAddressLine2 && (
                  <FormErrorLabel>{error.companyAddressLine2}</FormErrorLabel>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  Town / City <span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyAddressTown"
                  defaultValue={inputs.companyAddressTown}
                  name="companyAddressTown"
                  onChange={handleInputChange}
                  placeholder="Town / City..."
                  type="text"
                />
                {error?.companyAddressTown && (
                  <FormErrorLabel>{error.companyAddressTown}</FormErrorLabel>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  Post Code <span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyAddressPostcode"
                  defaultValue={inputs.companyAddressPostcode}
                  name="companyAddressPostcode"
                  onChange={handleInputChange}
                  placeholder="Post Code..."
                  type="text"
                />
                {error?.companyAddressPostcode && (
                  <FormErrorLabel>
                    {error.companyAddressPostcode}
                  </FormErrorLabel>
                )}
              </FormGroup>

              {!inputs.isSoleTrader && (
                <FormGroupDisable
                  disable={agencyWorker ? true : false}
                  gridColumn="1/2"
                >
                  <FormLabel>
                    VAT Registered? <span>*</span>
                  </FormLabel>
                  <FormToggleButtons
                    action={handleToggleButtons}
                    defaultValue={inputs.isVatRegistered}
                    input={inputs.isVatRegistered}
                    name="isVatRegistered"
                    text={["Yes", "No"]}
                  />
                  {error?.isVatRegistered && (
                    <FormErrorLabel>{error.isVatRegistered}</FormErrorLabel>
                  )}
                </FormGroupDisable>
              )}

              {inputs.isVatRegistered === 0 && (
                <FormGroup gridColumn="1/2">
                  <FormLabel>
                    VAT Number <span>*</span>
                  </FormLabel>
                  <FormInput
                    data-cy="companyVatNumber"
                    defaultValue={inputs.companyVatNumber}
                    name="companyVatNumber"
                    onChange={handleInputChange}
                    placeholder="VAT Number..."
                    type="text"
                  />
                  {error?.companyVatNumber && (
                    <FormErrorLabel>{error.companyVatNumber}</FormErrorLabel>
                  )}
                </FormGroup>
              )}
            </FormGrid>
          </FormGridContainer>
        )}

      {/*  IS SOLE TRADER && DE --------------------------------------------------*/}
      {inputs.employmentType === 0 && inputs.isSoleTrader && (
        <FormGridContainer>
          <FormBreak />

          <FormGrid columns={2} style={{ marginBottom: 22 }}>
            <FormGroup>
              <FormLabel>
                Sole Trader Name<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyName"
                defaultValue={inputs.companyName}
                name="companyName"
                onChange={handleInputChange}
                placeholder="Name..."
                type="text"
              />
              {error?.companyName && (
                <FormErrorLabel>{error.companyName}</FormErrorLabel>
              )}
            </FormGroup>
          </FormGrid>

          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>
                Address Line 1 <span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressLine1"
                defaultValue={inputs.companyAddressLine1}
                name="companyAddressLine1"
                onChange={handleInputChange}
                placeholder="Address Line 1..."
                type="text"
              />
              {error?.companyAddressLine1 && (
                <FormErrorLabel>{error.companyAddressLine1}</FormErrorLabel>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Address Line 2</FormLabel>
              <FormInput
                data-cy="companyAddressLine2"
                defaultValue={inputs.companyAddressLine2}
                name="companyAddressLine2"
                onChange={handleInputChange}
                placeholder="Address Line 2..."
                type="text"
              />
              {error?.companyAddressLine2 && (
                <FormErrorLabel>{error.companyAddressLine2}</FormErrorLabel>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Town / City <span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressTown"
                defaultValue={inputs.companyAddressTown}
                name="companyAddressTown"
                onChange={handleInputChange}
                placeholder="Town / City..."
                type="text"
              />
              {error?.companyAddressTown && (
                <FormErrorLabel>{error.companyAddressTown}</FormErrorLabel>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Post Code <span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyAddressPostcode"
                defaultValue={inputs.companyAddressPostcode}
                name="companyAddressPostcode"
                onChange={handleInputChange}
                placeholder="Post Code..."
                type="text"
              />
              {error?.companyAddressPostcode && (
                <FormErrorLabel>{error.companyAddressPostcode}</FormErrorLabel>
              )}
            </FormGroup>

            <FormGroupDisable
              disable={agencyWorker ? true : false}
              gridColumn="1/2"
            >
              <FormLabel>
                VAT Registered? <span>*</span>
              </FormLabel>
              <FormToggleButtons
                action={handleToggleButtons}
                defaultValue={inputs.isVatRegistered}
                input={inputs.isVatRegistered}
                name="isVatRegistered"
                text={["Yes", "No"]}
              />
              {error?.isVatRegistered && (
                <FormErrorLabel>{error.isVatRegistered}</FormErrorLabel>
              )}
            </FormGroupDisable>

            {inputs.isVatRegistered === 0 && (
              <FormGroup gridColumn="1/2">
                <FormLabel>
                  VAT Number <span>*</span>
                </FormLabel>
                <FormInput
                  data-cy="companyVatNumber"
                  defaultValue={inputs.companyVatNumber}
                  name="companyVatNumber"
                  onChange={handleInputChange}
                  placeholder="VAT Number..."
                  type="text"
                />
                {error?.companyVatNumber && (
                  <FormErrorLabel>{error.companyVatNumber}</FormErrorLabel>
                )}
              </FormGroup>
            )}
          </FormGrid>
        </FormGridContainer>
      )}

      {/* NonDE && !PAYE ---------------------------------- */}

      {inputs.employmentType === 1 && inputs.paymentType > 0 && (
        <FormGridContainer>
          <FormGrid columns={3}>
            <FormGroup>
              <FormLabel>
                Company Name<span>*</span>
              </FormLabel>
              <FormInput
                data-cy="companyName"
                defaultValue={inputs.companyName}
                name="companyName"
                onChange={handleInputChange}
                placeholder="Company Name..."
                type="text"
              />
              {error?.companyName && (
                <FormErrorLabel>{error.companyName}</FormErrorLabel>
              )}
            </FormGroup>
          </FormGrid>
        </FormGridContainer>
      )}

      {agencyWorker && (
        <>
          <FormBreak />
          <FormGridContainer>
            <h3
              style={{
                fontSize: 20,
                fontWeight: 600,
                marginBottom: 20,
              }}
            >
              Document Upload
            </h3>

            <FormGrid columns={3} style={{ marginBottom: 25 }}>
              {inputs?.compliancePackUrl === null ? (
                <UploadDocument>
                  <UploadCenter>
                    <label htmlFor="form-input">
                      <UploadIcon />
                      <input
                        data-cy="uploadCompliancePack"
                        defaultValue=""
                        id="form-input"
                        onChange={(e) => uploadComplianceFunction(e)}
                        type="file"
                      />
                      <h4>Upload File</h4>
                    </label>
                  </UploadCenter>

                  <p>Compliance Pack</p>
                </UploadDocument>
              ) : (
                <UploadedDocument>
                  Compliance Pack
                  <UploadedIcon />
                  {inputs.complianceExpirationDate !== null && (
                    <span data-cy="complianceExpirationDate">
                      Expires:
                      {dayjs(inputs.complianceExpirationDate).format(
                        "DD MMM YY"
                      )}
                    </span>
                  )}
                  <a
                    data-cy="download_compliance_pack"
                    href={`${process.env.REACT_APP_API}${inputs?.compliancePackUrl}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <DownloadIcon />
                    Download Doc
                  </a>
                  <button
                    data-cy="deleteCompliancePack"
                    onClick={() => deleteDocumentFunction("COMPLIANCE_PACK")}
                  >
                    Delete Document
                  </button>
                </UploadedDocument>
              )}

              {inputs?.cvUrl === null ? (
                <UploadDocument>
                  <UploadCenter>
                    <label htmlFor="form-input-1">
                      <UploadIcon />
                      <input
                        data-cy="uploadCV"
                        defaultValue=""
                        id="form-input-1"
                        onChange={(e) => uploadCvFunction(e)}
                        type="file"
                      />
                      <h4>Upload File</h4>
                    </label>
                  </UploadCenter>

                  <p>CV</p>
                </UploadDocument>
              ) : (
                <UploadedDocument>
                  CV
                  <UploadedIcon />
                  <a
                    data-cy="downloadCV"
                    href={`${process.env.REACT_APP_API}${inputs?.cvUrl}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <DownloadIcon />
                    Download Doc
                  </a>
                  <button
                    data-cy="deleteCV"
                    onClick={() => deleteDocumentFunction("CV")}
                  >
                    Delete Document
                  </button>
                </UploadedDocument>
              )}
            </FormGrid>

            {inputs?.compliancePackUrl !== null && (
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>Compliance Expiry Date</FormLabel>
                  <FormInput
                    data-cy="complianceExpirationDateInput"
                    defaultValue={inputs.complianceExpirationDate}
                    name="complianceExpirationDate"
                    onChange={handleInputChange}
                    placeholder="eg. 1234567"
                    type="date"
                  />
                  {error?.dbsNumber && (
                    <FormErrorLabel>
                      {error.complianceExpirationDate}
                    </FormErrorLabel>
                  )}
                </FormGroup>
              </FormGrid>
            )}
          </FormGridContainer>
        </>
      )}
    </>
  );
};

export default AgencyWorkersDetailsPage;

export const UploadCenter = styled.div`
  height: 100px;
  width: 188px;

  label {
    background: #f5f7fa;
    border: 1px dashed #9ca5b0;
    border-radius: 8px;
    color: #17bfd6;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 100px;
    input[type="file"] {
      display: none;
      height: 100px;
      width: 100%;
    }
    h4 {
      bottom: 70px;
      font-size: 13px;
      left: 72px;
      position: absolute;
    }
    svg {
      left: 95px;
      top: 48px;
    }
  }
`;

export const UploadDocument = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 600;
  height: 172px;
  padding: 19px 14px;
  position: relative;
  width: 216px;

  svg {
    position: absolute;
    right: 13px;
    top: 16px;
  }

  p {
    bottom: 15px;
    position: absolute;
  }

  a {
    align-items: center;
    background: #17bfd6;
    border-radius: 8px;
    bottom: 10px;
    color: #ffffff;
    display: flex;
    font-size: 14px;
    height: 42px;
    left: 16px;
    padding: 11px 0 11px 50px;
    position: absolute;
    width: 184px;
    svg {
      left: 20px;
      margin-right: 8px;
      top: 9px;
    }
  }
`;

export const UploadedDocument = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 600;
  height: 172px;
  padding: 19px 14px;
  position: relative;
  width: 216px;

  svg {
    position: absolute;
    right: 13px;
    top: 16px;
  }

  span {
    color: #dc5a54;
    left: 14px;
    position: absolute;
    top: 50px;
  }

  button {
    bottom: 12px;
    color: #7d8793;
    font-weight: 600;
    left: 0;
    position: absolute;
    text-align: center;
    text-decoration: underline;
    width: 100%;
  }

  a {
    align-items: center;
    background: #17bfd6;
    border-radius: 8px;
    bottom: 43px;
    color: #ffffff;
    display: flex;
    font-size: 14px;
    height: 42px;
    left: 16px;
    padding: 11px 0 11px 50px;
    position: absolute;
    width: 184px;
    svg {
      left: 20px;
      margin-right: 8px;
      top: 9px;
    }
  }
`;

export const UploadedIcon = () => (
  <svg
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.01657 3.21567C10.9096 2.78796 12.8902 2.98364 14.663 3.77354C15.1675 3.99832 15.7587 3.77158 15.9834 3.26711C16.2082 2.76264 15.9815 2.17146 15.477 1.94668C13.3103 0.981252 10.8896 0.742082 8.57581 1.26484C6.26206 1.7876 4.17929 3.04428 2.63811 4.84746C1.09693 6.65064 0.179919 8.9037 0.0238445 11.2706C-0.13223 13.6376 0.480993 15.9915 1.77206 17.9815C3.06312 19.9714 4.96285 21.4907 7.18792 22.3127C9.41299 23.1348 11.8442 23.2155 14.1189 22.543C16.3936 21.8704 18.39 20.4806 19.8103 18.5807C21.2306 16.6808 21.9986 14.3722 22 12.0001V11.0801C22 10.5278 21.5523 10.0801 21 10.0801C20.4477 10.0801 20 10.5278 20 11.0801V11.9995C19.9989 13.9403 19.3705 15.8287 18.2084 17.3832C17.0464 18.9376 15.413 20.0748 13.5518 20.625C11.6907 21.1753 9.70153 21.1092 7.88102 20.4367C6.06051 19.7641 4.50619 18.521 3.44987 16.8929C2.39354 15.2648 1.89181 13.3388 2.01951 11.4022C2.14721 9.46565 2.89749 7.62223 4.15845 6.1469C5.41942 4.67157 7.12351 3.64338 9.01657 3.21567ZM21.7075 4.70686C22.0978 4.31614 22.0975 3.68298 21.7068 3.29265C21.316 2.90232 20.6829 2.90264 20.2925 3.29336L10.9996 12.5955L8.70711 10.303C8.31658 9.91248 7.68342 9.91248 7.29289 10.303C6.90237 10.6935 6.90237 11.3267 7.29289 11.7172L10.2929 14.7172C10.4805 14.9048 10.7349 15.0102 11.0002 15.0101C11.2656 15.01 11.52 14.9046 11.7075 14.7169L21.7075 4.70686Z"
      fill="#57A886"
      fillRule="evenodd"
    />
  </svg>
);

const InvitationAlert = styled.div`
  background: #d1f2f7;
  border-left: 17px solid #17bfd6;
  border-radius: 8px;
  color: #002033;
  font-size: 13px;
  font-weight: 600;
  height: 38px;
  margin-bottom: 18px;
  padding: 9px 8px;
  width: 547px;
`;

// Toggle Buttons

const ToggleButton = styled.button<{
  checked: boolean;
  disabled: boolean;
}>`
  background: ${(props) => (props.checked ? "#05405C" : "#fff")};
  border: 1px solid ${(props) => (props.checked ? "transparent" : "#c7c7c7")};
  border-radius: 24px;
  box-shadow: 0px 1px 2px rgba(215, 215, 215, 0.05);
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 48px;
`;
const ToggleButtonHandle = styled.div<{ checked: boolean }>`
  background: ${(props) => (props.checked ? "#fff" : "#9FA0A2")};
  border-radius: 25px;
  box-shadow: 0px 1px 2px rgba(215, 215, 215, 0.05);
  height: 16px;
  left: ${(props) => (props.checked ? "28px" : "4px")};
  pointer-events: none;
  position: absolute;
  top: 3px;
  transition: all 0.25s ease-in-out;
  width: 16px;
`;

const IsSoleTraderButton = styled.div`
  align-items: center;
  display: flex;
  margin-top: 18px;
  width: 300px;
`;
