import { Switch } from "react-router-dom";

import ForgotPasswordPage from "containers/forgot";
import LoginPage from "containers/login";

import Route from "./RouteWrapper";
// import AcceptInvitePage from "containers/accept-invite";
// import ResetPasswordPage from "containers/reset-password";
// import ConfirmEmailPage from "containers/confirm-email";

export default function PublicRoutes() {
  return (
    <Switch>
      {/* Login / Account Stuff --------------------------------------------------*/}
      <Route component={LoginPage} exact path="/" />
      <Route component={ForgotPasswordPage} exact path="/forgot" />
      {/* <Route path="/accept-invite" exact component={AcceptInvitePage} />
      <Route path="/reset-password" exact component={ResetPasswordPage} />
      <Route path="/confirm-email" exact component={ConfirmEmailPage} /> */}

      <Route component={LoginPage} path="*" />
      {/* If URL does not match anything ^ */}
    </Switch>
  );
}
