import { createModel } from "@rematch/core";

import { RootModel } from "./models";
// import { AgencyUserOrganisationFragment } from "helpers/__generated__/mutations.generated";

export const organisation = createModel<RootModel>()({
  state: null as any,
  reducers: {
    deleteOrganisation: () => null,
    storeOrganisation: (state, payload: any) => {
      return payload;
    },
  },
});
