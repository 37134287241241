// @ts-nocheck
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { OrganisationAgency } from "baseCODEGEN";
import styled from "styled-components";

import {
  FormGrid,
  FormGroup,
  FormSelect,
  GLOBAL,
} from "@patchworkhealth/web-components";

import {
  useAgencyUserPermissionCreateMutation,
  useAgencyUserPermissionDestroyMutation,
  useAgencyUserPermissionUpdateMutation,
} from "./__generated__/AgencyUsersHelpers.generated";

const AgencyUserPermissions = ({
  agencyUserId,
  agencyUserPermission,
  agencyUserPermissions,
  index,
  organisationAgencies,
  refetchAgencyUsers,
  setShowNewAgencyUserPermissions,
}: AgencyUserPermissionsProps) => {
  /* STATE ********************************************************/

  const [filteredOrgAgencies, setFilteredOrgAgencies] = useState(null);
  const [inputs, setInputs] = useState(emptyInputs);
  const [, setOrgStaffGroup] = useState<OrganisationAgency>(null);
  const [update, setUpdate] = useState(false);

  /* USE EFFECTS **************************************************/

  useEffect(() => {
    if (update) {
      if (!agencyUserPermission) {
        createAgencyUserPermissionAction();
        return;
      }
      updateAgencyUserPermissionAction();
      setUpdate(false);
    }
  }, [agencyUserPermission, update]);

  useEffect(() => {
    if (agencyUserPermission) {
      setInputs({
        id: Number(agencyUserPermission.id),
        organisationStaffGroupId: Number(
          agencyUserPermission.organisationStaffGroup.id
        ),
        canAssignWorker: agencyUserPermission.canAssignWorker ?? false,
        canProposeWorker: agencyUserPermission.canProposeWorker ?? false,
      });
    }
  }, [agencyUserPermission, agencyUserPermissions]);

  useEffect(() => {
    if (agencyUserPermission && organisationAgencies) {
      let result;
      organisationAgencies?.forEach((item) => {
        if (
          Number(item.organisationStaffGroup.id) ===
          Number(agencyUserPermission.organisationStaffGroup.id)
        ) {
          result = item;
        }
      });
      if (result) {
        setOrgStaffGroup(result);
      }
    }
  }, [agencyUserPermission, organisationAgencies]);

  useEffect(() => {
    if (agencyUserPermissions && organisationAgencies) {
      let a = [];
      agencyUserPermissions?.map((item) =>
        a.push(item.organisationStaffGroup.id)
      );
      setFilteredOrgAgencies(
        organisationAgencies?.filter(
          (i) => !a.includes(i.organisationStaffGroup.id)
        )
      );
    }
  }, [agencyUserPermissions, organisationAgencies]);

  /* GQL ******************************************************* */

  const [createUserPermission] = useAgencyUserPermissionCreateMutation();
  const [updateUserPermission] = useAgencyUserPermissionUpdateMutation();
  const [deleteUserPermission] = useAgencyUserPermissionDestroyMutation();

  /* MUTATIONS ************************************************* */

  const createAgencyUserPermissionAction = async () => {
    const toastId = toast.loading("Creating User Permission...");
    try {
      const { data: result } = await createUserPermission({
        variables: {
          agencyUserId: agencyUserId,
          ...inputs,
        },
      });

      if (result?.vmsAgencyUserPermissionCreate !== true) {
        toast.error("An error occurred.", { id: toastId });
        return;
      }
      toast.success("User Permission Created.", { id: toastId });
      refetchAgencyUsers();
      setShowNewAgencyUserPermissions(false);
    } catch (error) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const updateAgencyUserPermissionAction = async () => {
    const toastId = toast.loading("Updating User Permission...");
    try {
      const { data: result } = await updateUserPermission({
        variables: {
          id: Number(agencyUserPermission?.id),
          ...inputs,
        },
      });
      if (result?.vmsAgencyUserPermissionUpdate !== true) {
        toast.error("An error occurred.", { id: toastId });
        return;
      }

      toast.success("User Permission Updated.", { id: toastId });
      refetchAgencyUsers();
    } catch (error) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const deleteAgencyUserPermissionAction = async () => {
    const toastId = toast.loading("Deleting User Permission...");
    try {
      const { data: result } = await deleteUserPermission({
        variables: {
          id: Number(agencyUserPermission?.id),
        },
      });

      if (result?.vmsAgencyUserPermissionDestroy !== true) {
        toast.error("An error occurred.", { id: toastId });
        return;
      }
      toast.success("User Permission Deleted.", { id: toastId });
      refetchAgencyUsers();
    } catch (error) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  /* HANDLERS & FUNCTIONS *******************************************/

  const organisationStaffGroups = filteredOrgAgencies?.map(function (
    organisationAgency
  ) {
    return {
      value: organisationAgency.organisationStaffGroup.id,
      label: organisationAgency.organisationStaffGroup.title,
    };
  });

  const handleStaffGroupChange = (event) => {
    let result;
    organisationAgencies?.forEach((item) => {
      if (Number(item.organisationStaffGroup.id) === Number(event.value)) {
        result = item;
      }
    });
    if (result) {
      setOrgStaffGroup(result);
    }
    setUpdate(true);
    setInputs((inputsParam) => ({
      ...inputsParam,
      organisationStaffGroupId: Number(event.value),
    }));
  };

  return (
    <AgencyUserPermissionContainer>
      <FormGrid columns={3} full style={{ alignItems: "center" }}>
        <FormGroup>
          <FormSelect
            defaultValue={
              agencyUserPermission && {
                label: agencyUserPermission.organisationStaffGroup.title,
                value: agencyUserPermission.organisationStaffGroup.id,
              }
            }
            isClearable
            isDisabled={agencyUserPermission ? true : false}
            onChange={handleStaffGroupChange}
            options={organisationStaffGroups}
            placeholder="Select Staff Group"
          />
        </FormGroup>
        <PermissionsContainer>
          <div>
            <input
              checked={inputs ? inputs.canProposeWorker : false}
              id={`propose-worker-${index}`}
              name="canProposeWorker"
              onChange={() => {
                setInputs((inputsParam) => ({
                  ...inputsParam,
                  canProposeWorker: !inputs.canProposeWorker,
                }));
                setUpdate(true);
              }}
              type="checkbox"
            />
            {/* eslint-disable-next-line */}
            <label
              htmlFor={`propose-worker-${index}`}
              style={{ fontWeight: 600, fontSize: 12 }}
            >
              Can propose agency worker
            </label>
          </div>
        </PermissionsContainer>
        <DeletePermissionsContainer>
          {agencyUserPermission && (
            <button onClick={() => deleteAgencyUserPermissionAction()}>
              Delete Permission
            </button>
          )}
        </DeletePermissionsContainer>
      </FormGrid>
    </AgencyUserPermissionContainer>
  );
};

export default AgencyUserPermissions;

type AgencyUserPermissionsProps = {
  agencyUserId?: number;
  agencyUserPermission?: any;
  agencyUserPermissions: any;
  index?: number;
  organisationAgencies?: any;
  refetchAgencyUsers: Function;
  setShowNewAgencyUserPermissions?: Function;
};

const emptyInputs = {
  id: null,
  organisationStaffGroupId: null,
  canAssignWorker: false,
  canProposeWorker: false,
};

const AgencyUserPermissionContainer = styled.div`
  background: ${GLOBAL.backgroundColor};
  border: 1px solid ${GLOBAL.borderGrey};
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 840px;
  padding: 12px 16px 16px;
  position: relative;
  transition: all ease 0.25s;
`;

const PermissionsContainer = styled.div`
  input {
    margin-right: 8px;
    position: relative;
    top: 1px;
  }

  label {
    font-size: 15px;
    margin: 0;
  }
`;

const DeletePermissionsContainer = styled.div`
  justify-self: end;

  button {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #fd6a6a;
    font-size: 13px;
    font-weight: 600;
    padding: 0;
    text-decoration: underline;
  }
`;
