import { TableContainer } from "@patchworkhealth/web-components";

import { defaultImgOnError } from "../../helpers/functions";

const OrganisationsTable = ({ organisations }: OrganisationsTableProps) => {
  return (
    <TableContainer loading={false}>
      <thead data-cy="shift-table-header">
        <tr>
          <th></th>
          <th>Org Name</th>
          <th />
        </tr>
      </thead>
      <tbody data-testid="shifts">
        {organisations.map((org) => (
          <tr key={org.value}>
            <th style={{ width: 20 }}>
              <img
                alt="Organisation"
                className="logo"
                onError={defaultImgOnError}
                src={`${process.env.REACT_APP_API}${org.organisationStaffGroup.organisation.logoUrl}`}
              />
            </th>
            <th>{org.organisationStaffGroup.organisation.name}</th>

            <th />
          </tr>
        ))}
      </tbody>
    </TableContainer>
  );
};

export default OrganisationsTable;

type OrganisationsTableProps = {
  organisations: any;
};
