import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  FormBreak,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormSelectMulti,
} from "@patchworkhealth/web-components";

import { DownloadIcon, UploadIcon } from "assets/icons/documentIcons";
import { useGradesQuery } from "helpers/__generated__/queries.generated";
import { formatForReactSelect } from "helpers/functions";
import { RootState } from "models/store";

import { ShiftModalTitle } from "../../AgencyShifts/ShiftModalComponents/ShiftModalComponents";
import {
  useSkillsForOrganisationStaffGroupQuery,
  useVmsAgencyRegistrationDeleteUploadedFileMutation,
  useVmsAgencyRegistrationUploadCompliancePackMutation,
  useVmsAgencyRegistrationUploadCvMutation,
} from "../__generated__/AgencyWorkersHelpers.generated";
import {
  UploadCenter,
  UploadDocument,
  UploadedDocument,
  UploadedIcon,
} from "../AgencyWorkersModalDetailsPage";

const ShiftModalExperience = ({
  handleInputChange,
  handleFilter,
  inputs,
  error,
  refetchWorkers,
  agencyWorker,
}) => {
  const staffGroups = useSelector((s: RootState) => s.staffGroups);
  const organisation = useSelector((s: RootState) => s.organisation);

  /* GQL **********************************************/

  // remap staffGroups into an array react-select can read
  const sortedStaffGroups = staffGroups?.map(function (staffGroup) {
    return {
      value: staffGroup.staffGroup.idValue,
      label: staffGroup.staffGroup.title,
      master: staffGroup.id,
    };
  });

  const { data: skillsData } = useSkillsForOrganisationStaffGroupQuery({
    skip: inputs?.staffGroup?.value === null,
    variables: {
      organisationStaffGroupId: Number(
        sortedStaffGroups.find(
          (option) => option.value === inputs?.staffGroup?.value
        )?.master
      ),
    },
  });

  const { data: grades } = useGradesQuery({
    skip: inputs.staffGroup.value === null,
    variables: {
      staffGroupId: Number(inputs.staffGroup.value),
      organisationId: Number(
        organisation[0].organisationStaffGroup.organisation.id
      ),
    },
  });

  const [uploadDoc] = useVmsAgencyRegistrationUploadCompliancePackMutation();
  const [uploadCv] = useVmsAgencyRegistrationUploadCvMutation();
  const [deleteDoc] = useVmsAgencyRegistrationDeleteUploadedFileMutation();

  /* FUNCTION HANDLERS **********************************************/

  const uploadComplianceFunction = (e) => {
    e.persist();

    const file = e.target.files[0];
    const toastId6 = toast.loading("Loading...");

    toast.loading("Loading", { id: toastId6 });

    uploadDoc({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        file,
      },
    })
      .then((response) => {
        const errors =
          response.data.vmsAgencyRegistrationUploadCompliancePack.errors;

        if (errors.length > 0) {
          toast.error("There was an error with your Document", {
            id: toastId6,
          });

          return;
        }

        toast.success("Compliance Pack Uploaded", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  const uploadCvFunction = (e) => {
    e.persist();

    const file = e.target.files[0];
    const toastId6 = toast.loading("Loading...");

    toast.loading("Loading", { id: toastId6 });

    uploadCv({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        file,
      },
    })
      .then((response) => {
        const errors = response.data.vmsAgencyRegistrationUploadCv.errors;

        if (errors.length > 0) {
          toast.error("There was an error with your Document", {
            id: toastId6,
          });

          return;
        }

        toast.success("CV Uploaded", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  const deleteDocumentFunction = (type) => {
    const toastId6 = toast.loading("Loading...");

    deleteDoc({
      variables: {
        agencyRegistrationId: parseInt(agencyWorker.id),
        uploadType: type,
      },
    })
      .then((response) => {
        const errors =
          response.data.vmsAgencyRegistrationDeleteUploadedFile.errors;

        if (errors.length > 0) {
          toast.error("There was an error with your Document", {
            id: toastId6,
          });

          return;
        }

        toast.success("Document Deleted", { id: toastId6 });
        refetchWorkers(agencyWorker);
      })
      .catch(() => {
        toast.error("There was an error with your Document", {
          id: toastId6,
        });
      });
  };

  let sortedSkills = skillsData?.skillsForOrganisationStaffGroup?.map((cc) => ({
    label: cc.name,
    value: cc.id,
  }));

  return (
    <div>
      <FormGridContainer>
        <ShiftModalTitle>Professional Info</ShiftModalTitle>
        <FormGrid columns={2} style={{ marginBottom: 22 }}>
          <FormGroup>
            <FormLabel>
              DBS Number<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="dbsNumber"
              defaultValue={inputs.dbsNumber}
              name="dbsNumber"
              onChange={handleInputChange}
              placeholder="eg. 1234567"
              type="dbsNumber"
            />
            {error?.dbsNumber && (
              <FormErrorLabel>{error.dbsNumber}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Registration Body No.<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="regBodyNumber"
              defaultValue={inputs.regBodyNumber}
              name="regBodyNumber"
              onChange={handleInputChange}
              placeholder="eg. 123456"
              type="text"
            />
            {error?.regBodyNumber && (
              <FormErrorLabel>{error.regBodyNumber}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Staff Group<span>*</span>
            </FormLabel>
            <FormSelect
              isDisabled={false}
              onChange={(e) => {
                handleFilter(e, "staffGroup");
                handleFilter([], "skillIds");
              }}
              options={sortedStaffGroups}
              placeholder="Select Staff Group"
              value={
                inputs.staffGroup?.value && {
                  value: inputs.staffGroup.value,
                  label: inputs.staffGroup.label,
                }
              }
            />
            {error?.staffGroup && (
              <FormErrorLabel>{error.staffGroup}</FormErrorLabel>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              Grade<span>*</span>
            </FormLabel>
            <FormSelect
              onChange={(e) => {
                handleFilter(e, "grade");
              }}
              options={formatForReactSelect(grades?.grades)}
              placeholder="Select Grade"
              value={
                inputs.grade?.value && {
                  value: inputs.grade.value,
                  label: inputs.grade.label,
                }
              }
            />
            {error?.grade && <FormErrorLabel>{error.grade}</FormErrorLabel>}
          </FormGroup>

          <FormGroup>
            <FormLabel>
              National Insurance No.<span>*</span>
            </FormLabel>
            <FormInput
              data-cy="niNumber"
              defaultValue={inputs.niNumber}
              name="niNumber"
              onChange={handleInputChange}
              placeholder="eg. SB123456A"
              type="text"
            />
            {error?.niNumber && (
              <FormErrorLabel>{error.niNumber}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />

      <FormGridContainer>
        <FormGrid columns={2}>
          <FormGroup>
            <FormLabel>Skills</FormLabel>
            <FormSelectMulti
              onChange={(e) => handleFilter(e, "skillIds")}
              options={sortedSkills}
              placeholder="Select Skills"
              value={inputs.skillIds}
            />
            {error?.skillIds && (
              <FormErrorLabel>{error.skillIds}</FormErrorLabel>
            )}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>
      <FormBreak />

      <FormGridContainer>
        <h3
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Document Upload
        </h3>

        <FormGrid columns={3} style={{ marginBottom: 25 }}>
          {agencyWorker?.compliancePackUrl === null ? (
            <UploadDocument>
              <UploadCenter>
                <label htmlFor="form-input">
                  <UploadIcon />
                  <input
                    defaultValue=""
                    id="form-input"
                    onChange={(e) => uploadComplianceFunction(e)}
                    type="file"
                  />
                  <h4>Upload File</h4>
                </label>
              </UploadCenter>

              <p>Compliance Pack</p>
            </UploadDocument>
          ) : (
            <UploadedDocument>
              Compliance Pack
              <UploadedIcon />
              {agencyWorker?.complianceExpirationDate !== null && (
                <span>
                  Expires:
                  {dayjs(agencyWorker?.complianceExpirationDate).format(
                    " DD MMM YY"
                  )}
                </span>
              )}
              <a
                href={`${process.env.REACT_APP_API}${agencyWorker?.compliancePackUrl}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <DownloadIcon />
                Download Doc
              </a>
              <button onClick={() => deleteDocumentFunction("COMPLIANCE_PACK")}>
                Delete Document
              </button>
            </UploadedDocument>
          )}

          {agencyWorker?.cvUrl === null ? (
            <UploadDocument>
              <UploadCenter>
                <label htmlFor="form-input-1">
                  <UploadIcon />
                  <input
                    defaultValue=""
                    id="form-input-1"
                    onChange={(e) => uploadCvFunction(e)}
                    type="file"
                  />
                  <h4>Upload File</h4>
                </label>
              </UploadCenter>

              <p>CV</p>
            </UploadDocument>
          ) : (
            <UploadedDocument>
              CV
              <UploadedIcon />
              <a
                href={`${process.env.REACT_APP_API}${agencyWorker?.cvUrl}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <DownloadIcon />
                Download Doc
              </a>
              <button onClick={() => deleteDocumentFunction("CV")}>
                Delete Document
              </button>
            </UploadedDocument>
          )}
        </FormGrid>

        {agencyWorker?.compliancePackUrl !== null && (
          <FormGrid columns={2}>
            <FormGroup>
              <FormLabel>Compliance Expiry Date</FormLabel>
              <FormInput
                defaultValue={inputs.complianceExpirationDate}
                name="complianceExpirationDate"
                onChange={handleInputChange}
                placeholder="eg. 1234567"
                type="date"
              />
              {error?.dbsNumber && (
                <FormErrorLabel>
                  {error.complianceExpirationDate}
                </FormErrorLabel>
              )}
            </FormGroup>
          </FormGrid>
        )}
      </FormGridContainer>

      <FormGrid columns={2} style={{ marginBottom: 22 }}></FormGrid>
    </div>
  );
};

export default ShiftModalExperience;
