import { gql } from "@apollo/client";

export const AGENCY_USER_ORGANISATION_FRAGMENT = gql`
  fragment AgencyUserOrganisation on Organisation {
    id
    name
  }
`;

const LOGIN_AGENCY_USER_FRAGMENT = gql`
  fragment LoginAgencyUser on User {
    email
    firstName
    id
    lastName
  }
`;

export const AGENCY_USER_LOGIN = gql`
  mutation AgencyUserLogin($email: String!, $password: String!) {
    agencyUserLogin(email: $email, password: $password) {
      refreshToken
      token
      user {
        ...LoginAgencyUser
      }
    }
  }
  ${LOGIN_AGENCY_USER_FRAGMENT}
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation AgencyUserRefreshToken($refreshToken: String!) {
    agencyUserRefreshToken(refreshToken: $refreshToken) {
      refreshToken
      token
    }
  }
`;
