import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

//
import { Loading, ModalBody } from "@patchworkhealth/web-components";

import { useGetShiftQuery } from "./__generated__/AgencyShiftsHelpers.generated";
import ShiftModalAssignWorker from "./ShiftModalComponents/ShiftModalAssignWorker";
import ShiftModalCancelBooking from "./ShiftModalComponents/ShiftModalCancelBooking";
import ShiftModalDetails from "./ShiftModalComponents/ShiftModalDetails";
import ShiftModalHeader from "./ShiftModalComponents/ShiftModalHeader";
import ShiftModalProposeWorkers from "./ShiftModalComponents/ShiftModalProposeWorkers/ShiftModalProposeWorkers";
import ShiftModalSidebar from "./ShiftModalComponents/ShiftModalSidebar";

type AgencyShiftsModalProps = {
  handleCloseAction: () => void;
  shiftId: number;
  show: boolean;
  setShowWorker: any;
  refetchWorkers: Function;
  readonly?: boolean;
};

export type Tab =
  | "assignWorker"
  | "cancel"
  | "details"
  | "viewWorker"
  | "proposeWorker";

const AgencyShiftsModal = ({
  handleCloseAction,
  shiftId,
  show,
  setShowWorker,
  refetchWorkers,
  readonly,
}: AgencyShiftsModalProps) => {
  const [tab, setTab] = useState<Tab>("details");

  const { data: shift, loading: shiftLoading } = useGetShiftQuery({
    skip: !shiftId,
    fetchPolicy: "network-only",
    variables: {
      id: shiftId,
    },
  });

  return (
    <Modal
      backdrop="static"
      centered
      data-testid="shifts__modal"
      onHide={handleCloseAction}
      show={show}
      size="xl"
    >
      {shiftLoading ? (
        <Loading modal />
      ) : (
        <>
          <ShiftModalHeader
            handleCloseAction={handleCloseAction}
            shift={shift?.shift}
          />
          <ShiftModalContainer>
            {!readonly && (
              <ShiftModalSidebar
                setTab={setTab}
                shift={shift?.shift}
                tab={tab}
              />
            )}
            <ShiftModalContentContainer>
              <ModalBody style={{ minHeight: 520, paddingBottom: 100 }}>
                {tab === "details" ? (
                  <ShiftModalDetails readonly={readonly} shift={shift?.shift} />
                ) : tab === "assignWorker" ? (
                  <ShiftModalAssignWorker />
                ) : tab === "proposeWorker" ? (
                  <ShiftModalProposeWorkers
                    handleCloseAction={handleCloseAction}
                    readonly={false}
                    refetchWorkers={refetchWorkers}
                    setShowWorker={setShowWorker}
                    shift={shift?.shift}
                  />
                ) : tab === "viewWorker" ? (
                  <ShiftModalProposeWorkers
                    handleCloseAction={handleCloseAction}
                    readonly={true}
                    refetchWorkers={refetchWorkers}
                    setShowWorker={setShowWorker}
                    shift={shift?.shift}
                  />
                ) : (
                  tab === "cancel" && (
                    <ShiftModalCancelBooking
                      handleCloseAction={handleCloseAction}
                      refetchWorkers={refetchWorkers}
                      setShowWorker={setShowWorker}
                      shift={shift?.shift}
                    />
                  )
                )}
              </ModalBody>
            </ShiftModalContentContainer>
          </ShiftModalContainer>
        </>
      )}
    </Modal>
  );
};

export default AgencyShiftsModal;

export const ShiftModalContainer = styled.div`
  display: flex;
`;

export const ShiftModalContentContainer = styled.div`
  width: 100%;
`;
