// @ts-nocheck
import { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import styled from "styled-components";

import {
  BLUE,
  DateInput,
  FormBreak,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  GREY,
} from "@patchworkhealth/web-components";

import {
  BankShifts,
  sortCalendarBlocks,
} from "components/Calendar/CalendarComponents";
import { createFormFunctions } from "helpers/functions";

import { ShiftModalTitle } from "../../AgencyShifts/ShiftModalComponents/ShiftModalComponents";
import { ShiftBlockQuery } from "../__generated__/BlocksHelpers.generated";
import { securityClearance } from "../BlocksHelpers";
import { SlideLeftIcon, SlideRightIcon } from "./BlockIcons";
import { NextButton, PreviousButton } from "./BlockRates";

const BlockDetails = ({ shift, setShowShift }: BlockDetailsprops) => {
  const [inputs, setInputs] = useState(emptyInputs);
  const { handleFilter } = createFormFunctions(setInputs);

  let chosenSecurity = [];

  if (shift.modFinancialApprovalCode !== null) {
    chosenSecurity = securityClearance.filter(
      (dd) => dd.value === shift.modSecurityClearance
    );
  }

  // USE EFFECTS *****************************************

  useEffect(() => {
    const weeks = [];

    let start = moment(shift.startDate)
      .startOf("month")
      .day(1)
      .format("YYYY-MM-DD");

    let end = moment(shift.endDate).add(1, "month").format("YYYY-MM-DD");

    const currentMonth = {
      start,
      end,
    };

    while (start <= end) {
      weeks.push({
        start,
        end: moment(start).add(41, "days").format("YYYY-MM-DD"),
      });
      start = moment(start)
        .add(40, "days")
        .startOf("month")
        .day(1)
        .format("YYYY-MM-DD");
    }

    let days = [];

    for (let i = 0; i <= 41; i++) {
      days.push({
        date: moment(currentMonth?.start).add(i, "days").format("YYYY-MM-DD"),
      });
    }

    setInputs({
      ...inputs,
      days,
      monthArray: weeks,
      index: 0,
      currentMonth: weeks[0],
    });
  }, []);

  useEffect(() => {
    sortCalendarBlocks(shift?.shifts, handleFilter);
  }, [shift]);

  // FUNCTIONS *********************************************

  const changeWeek = (type) => {
    let chosenIndex = inputs.monthArray[inputs.index + type];
    let days = [];

    if (
      inputs.index + type === -1 ||
      inputs.index + type === inputs.monthArray.length
    ) {
      return;
    }

    for (let i = 0; i <= 41; i++) {
      days.push({
        date: moment(chosenIndex.start).add(i, "days").format("YYYY-MM-DD"),
      });
    }

    setInputs({
      ...inputs,
      days,
      index: inputs.index + type,
      currentMonth: chosenIndex,
    });
  };

  // RENDER EVENTS ***************************************

  let actualStartDate = moment(inputs.currentMonth?.start)
    .add("10", "days")
    .startOf("month");

  let actualEndDate = moment(inputs.currentMonth?.start)
    .add("10", "days")
    .endOf("month");

  const renderBankShifts = (event) => {
    const { bank } = event;

    let validShift = moment(bank[0].startTime).isBetween(
      moment(actualStartDate),
      moment(actualEndDate),
      null,
      "[]"
    );

    if (bank.length === 1) {
      return (
        <BankShifts
          blocks={true}
          number={`${moment(bank[0].startTime).format("HH:mm")} - ${moment(
            bank[0].endTime
          ).format("HH:mm")}`}
          onClick={() => setShowShift(bank[0].id)}
          type={validShift ? "TO_SIGN_OFF_ON_HUB_OR_APP" : "SIGN_OFF_REQUESTED"}
        />
      );
    }

    if (bank.length === 2) {
      return (
        <>
          <BankShifts
            blocks={true}
            number={`${moment(bank[0].startTime).format("HH:mm")} - ${moment(
              bank[0].endTime
            ).format("HH:mm")}`}
            onClick={() => setShowShift(bank[0].id)}
            type="TO_SIGN_OFF_ON_HUB_OR_APP"
          />

          <BankShifts
            blocks={true}
            number={`${moment(bank[1].startTime).format("HH:mm")} - ${moment(
              bank[1].endTime
            ).format("HH:mm")}`}
            onClick={() => setShowShift(bank[1].id)}
            type="TO_SIGN_OFF_ON_HUB_OR_APP"
          />
        </>
      );
    }
  };

  return (
    <FormGridContainer>
      <ShiftModalTitle>Block Info</ShiftModalTitle>
      <FormGrid columns={3} style={{ marginBottom: 28 }}>
        <FormGroupDisable>
          <FormLabel>Start Date</FormLabel>
          <FormInputGroup>
            <DateInput value={shift?.startDate} width={20} />
          </FormInputGroup>
        </FormGroupDisable>

        <FormGroupDisable>
          <FormLabel>End Date</FormLabel>
          <FormInputGroup>
            <DateInput value={shift?.endDate} />
          </FormInputGroup>
        </FormGroupDisable>

        <FormGroupDisable>
          <FormLabel>Reason for Booking</FormLabel>
          <FormSelect value={shift?.reasonForBooking} />
        </FormGroupDisable>
      </FormGrid>

      <FormGrid columns={3}>
        <ShiftCard>
          <FormLabel>Total rate for block</FormLabel>
          <p>£{shift?.totalRate?.toFixed(2)}</p>
        </ShiftCard>
        <ShiftCard>
          <FormLabel>Total hours in block</FormLabel>
          <p>{shift?.totalHours.toFixed(2)} hours</p>
        </ShiftCard>
        <ShiftCard color="blue">
          <FormLabel>Avg rate / Hr</FormLabel>
          <p>
            {shift?.totalHours < 1 ? (
              " - "
            ) : (
              <>£{(shift?.totalRate / shift?.totalHours).toFixed(2)}</>
            )}
          </p>
        </ShiftCard>
      </FormGrid>
      <FormBreak />
      <ShiftModalTitle>Clinical Info</ShiftModalTitle>
      <FormGrid columns={3} style={{ marginBottom: 16 }}>
        <FormGroupDisable>
          <FormLabel>Staff Group</FormLabel>
          <FormSelect
            value={{
              label: shift?.staffGroup.title,
              value: shift?.staffGroup.idValue,
            }}
          />
        </FormGroupDisable>

        <FormGroupDisable>
          <FormLabel>Grade</FormLabel>
          <FormSelect value={shift.grade} />
        </FormGroupDisable>
      </FormGrid>
      <FormBreak />

      <ShiftModalTitle
        style={{
          marginRight: 20,
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        Shifts within this Block
        <span
          style={{
            marginLeft: 8,
            fontSize: 13,
            padding: 8,
            fontWeight: "bold",
            color: "#646e7b",
            borderRadius: 8,
            background: "#E4E7EB",
          }}
        >
          {shift?.shiftCount ?? 0}
        </span>
      </ShiftModalTitle>

      <Container>
        <DateSelector>
          <PreviousButton
            calendar
            disabled={inputs.index === 0}
            onClick={() => changeWeek(-1)}
          >
            <SlideLeftIcon />
          </PreviousButton>

          <p>{actualStartDate.format("MMMM YYYY")}</p>

          <NextButton
            calendar
            disabled={inputs.index === inputs.monthArray.length - 1}
            onClick={() => changeWeek(1)}
          >
            <SlideRightIcon />
          </NextButton>
        </DateSelector>
      </Container>

      <CalendarContainer>
        <CalendarRow>
          {inputs?.days.map((day, i) => (
            <CalendarDay>
              <DayHeader today={moment().format("YYYY-MM-DD") === day.date}>
                {i < 7 && <DayTitle>{moment(day.date).format("ddd")}</DayTitle>}
                <DayValue
                  date={moment(day.date).isBetween(
                    actualStartDate,
                    actualEndDate,
                    null,
                    "[]"
                  )}
                  today={moment().format("YYYY-MM-DD") === day.date}
                >
                  {moment(day.date).format("D")}
                </DayValue>
              </DayHeader>

              <DayBody>
                {inputs?.events?.map(
                  (event) =>
                    event?.date === day?.date && (
                      <CalendarBank>{renderBankShifts(event)}</CalendarBank>
                    )
                )}
              </DayBody>
            </CalendarDay>
          ))}
        </CalendarRow>
      </CalendarContainer>
      {/* MOD STUFF ******************************************* */}
      {shift?.modFinancialApprovalCode !== null && (
        <>
          <FormBreak />
          <ShiftModalTitle>MOD Custom Fields</ShiftModalTitle>

          <FormGrid columns={2}>
            {shift.modAccommodationExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Accomodation Expenses Budget</FormLabel>
                <p>£{shift?.modAccommodationExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}
            <ShiftCard>
              <FormLabel>Approved Weekly Hours</FormLabel>
              <p>{shift?.modApprovalBudgetValue?.toFixed(2)}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Out of Hours - Estimated Weekly Hours</FormLabel>
              <p>{shift?.modOutOfHoursEstimatedWeeklyHours}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Out of Hours - Budget</FormLabel>
              <p>{shift?.modOutOfHoursBudget?.toFixed(2)}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Financial Approval Code</FormLabel>
              <p>{shift?.modFinancialApprovalCode}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Position Number</FormLabel>
              <p>{shift?.modPositionNumber}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Security Clearance</FormLabel>
              <p>{chosenSecurity[0]?.label}</p>
            </ShiftCard>

            {shift.modSubsistenceExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Subsistence Expenses Budget</FormLabel>
                <p>£{shift?.modSubsistenceExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}

            {shift.modTravelExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Travel Expenses Budget</FormLabel>
                <p>£{shift?.modTravelExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}
          </FormGrid>
        </>
      )}
      <FormBreak />
      <ShiftModalTitle>Optional Info</ShiftModalTitle>
      <FormGrid columns={2}>
        <FormGroup>
          <FormLabel>Worker Notes (Visible in Worker App)</FormLabel>
          <FormInput as="textarea" rows={6} value={shift.workerNotes} />

          <p style={{ fontSize: 12, marginTop: 6 }}>
            eg. Bleep numbers, who and where to report to, logins for computers
            and access codes for doors.
          </p>
        </FormGroup>
      </FormGrid>
    </FormGridContainer>
  );
};

export default BlockDetails;

type BlockDetailsprops = {
  shift: ShiftBlockQuery["shiftBlocks"]["nodes"][number];
  setShowShift: any;
};

const emptyInputs = {
  events: [],
  monthArray: [],
  index: null,
  currentMonth: null,
  days: [],
};

// STYLES *********************************************************

export const FormGroupDisable = styled.div`
  pointer-events: none;

  .DayPickerInput {
    input {
      width: 236px !important;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-flex: 300px !important;
    margin-bottom: 16px !important;
    margin-right: 30px !important;
    width: "220px" !important;
  }
`;

export const ShiftCard = styled.div`
  background: ${(props) => (props.color === "blue" ? "#d1f2f7" : "#f5f7fa")};
  border: 1px solid
    ${(props) => (props.color === "blue" ? "#17bfd6" : "#e4e7eb")};
  border-radius: 8px;
  height: auto;
  padding: 11px 16px;
  width: 100%;
  p {
    color: #414c58;
    font-size: 13px;
    margin-bottom: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 10px;
  }
`;

export const FormInputGroup = styled(InputGroup)`
  input {
    border: 1px solid #ccd2d8;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-group-text {
    background-color: "#F5F7FA";
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    justify-content: center;
    width: 50px;
  }
`;

// CALEDNAR HEADER STYLING ********************

const DateSelector = styled.div`
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 18px;
  left: 0;
  position: absolute;
  p {
    font-weight: 600;
    margin: 0 20px 0 0;
  }
  @media (max-width: 991px) {
    margin-left: 20px;
    position: relative;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 50px 0px 24px 20px;
  position: relative;
  width: 808px;
  z-index: 30;

  label {
    font-size: 12px !important;
    margin-right: 10px;
  }
  @media (max-width: 1150px) {
    margin: 10px 0 20px;
    width: 800px !important;
  }
`;

// Calendar Styling ****************************

export const CalendarContainer = styled.div`
  background: white;
  border-radius: 8px;
  margin: 40px auto 0px;
  overflow: auto;
  padding: 12px 16px 0px 32px;
  width: 100%;
`;

export const CalendarRow = styled.div`
  display: grid;
  grid-gap: 0px 14px;
  grid-template-columns: repeat(7, 104px);
  height: "50px";
  margin-bottom: 31px;
`;

export const CalendarDay = styled.div`
  height: "50px";
  margin-bottom: 22px;
  position: relative;
  width: 104px;
`;

export const DayTitle = styled.div`
  font-size: 12px;
  left: 6px;
  position: absolute;
  top: 2px;
`;

export const DayValue = styled.div<{ today: boolean; date: boolean }>`
  align-items: center;
  background: ${({ today }) => today && BLUE.five};
  border-radius: 3px;
  color: ${({ today, date }) =>
    today ? "white" : date ? "#002035" : GREY.four};
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 23px;
  justify-content: center;
  line-height: 17px;
  position: absolute;
  right: 0px;
  width: 23px;
`;

export const DayHeader = styled.div<{ today: boolean }>`
  border-bottom: ${({ today }) =>
    today ? `2px solid ${BLUE.five}` : `1px solid ${GREY.three}`};
  height: 31px;
  margin-bottom: 4px;
  width: 100%;
`;

export const DayBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: space-between;
  padding: 2px 0;
`;

export const CalendarBank = styled.div`
  height: 36px;
`;
