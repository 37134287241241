import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  DefaultButton,
  GLOBAL,
  GREEN,
  GREY,
  YELLOW,
} from "@patchworkhealth/web-components";

import { AgencyRegistrationFragment } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";

/// import { AgencyWorkerProposalFragment } from "./__generated__/ShiftModalProposeWorkersHelpers.generated";
import { AgencyShiftFragment } from "../../__generated__/AgencyShiftsHelpers.generated";

type ShiftModalProposeWorkerProps = {
  agencyWorker?: AgencyRegistrationFragment | any;
  proposal?: any;
  proposeWorkerAction?: Function;
  shift: AgencyShiftFragment;
  setShowWorker: any;
  proposeWorkerDeleteAction?: Function;
  readonly?: Boolean;
};

const ShiftModalProposeWorker = ({
  agencyWorker,
  proposal,
  proposeWorkerAction,
  proposeWorkerDeleteAction,
  shift,
  setShowWorker,
  readonly,
}: ShiftModalProposeWorkerProps) => {
  const [skillsAquired, setSkillsAquired] = useState<any>(null);

  useEffect(() => {
    if (shift && agencyWorker?.skills) {
      let newStuff = [];

      for (let x = 0; x < shift.skills.length; x++) {
        for (let y = 0; y < agencyWorker.skills.length; y++) {
          if (shift.skills[x].id === agencyWorker.skills[y].id) {
            newStuff.push(agencyWorker.skills[y].id);
          }
        }
      }

      setSkillsAquired({
        aquired: newStuff.length,
        total: shift.skills.length,
      });
    }

    if (shift && proposal?.agencyWorker?.skills) {
      let newStuff = [];

      for (let x = 0; x < shift.skills.length; x++) {
        for (let y = 0; y < proposal?.agencyWorker.skills.length; y++) {
          if (shift.skills[x].id === proposal?.agencyWorker.skills[y].id) {
            newStuff.push(proposal?.agencyWorker.skills[y].id);
          }
        }
      }

      setSkillsAquired({
        aquired: newStuff.length,
        total: shift.skills.length,
      });
    }
  }, [shift, agencyWorker, proposal]);

  const typeCheck = proposal ? proposal.agencyWorker : agencyWorker;

  return (
    <ProposeWorkerContainer data-cy="propose_worker_container">
      <ProposeWorkerDetailsContainer>
        <ProposeWorkerDetailsAvatar
          src={`${process.env.REACT_APP_API}/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg`}
        />
        <ProposeWorkerDetails>
          <ProposeWorkerDetailsName
            data-cy="propose_workers_name"
            onClick={() => {
              setShowWorker(typeCheck);
            }}
          >{`${
            proposal
              ? proposal.agencyWorker.firstName
              : agencyWorker.user?.firstName
              ? agencyWorker.user?.firstName
              : agencyWorker.firstName
          } ${
            proposal
              ? proposal.agencyWorker.lastName
              : agencyWorker.user?.lastName
              ? agencyWorker.user?.lastName
              : agencyWorker.lastName
          }`}</ProposeWorkerDetailsName>
          <ProposeWorkerDetailsGrade
            color={
              proposal
                ? proposal.agencyWorker.grade?.colour
                : agencyWorker.grade?.colour
            }
          >
            {proposal
              ? proposal.agencyWorker.grade?.title
              : agencyWorker.grade?.title}
          </ProposeWorkerDetailsGrade>
          <ProposeWorkerDetailsEmail>
            {proposal ? proposal.agencyWorker.email : agencyWorker.user?.email}
          </ProposeWorkerDetailsEmail>
        </ProposeWorkerDetails>
      </ProposeWorkerDetailsContainer>
      {!proposal && (
        <div>
          {agencyWorker.user && (
            <ProposeWorkerPaymentDetailsContainer>
              <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_employment_type">
                {agencyWorker.employmentType === "direct_engagement"
                  ? "DE"
                  : "NonDE"}
              </ProposeWorkerPaymentDetailsEmploymentLabel>

              <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_payment_type">
                {agencyWorker.paymentType === "paye" && "Paye"}
                {agencyWorker.paymentType === "umbrella_company" && "Umbrella"}

                {agencyWorker.paymentType === "ltd" &&
                  agencyWorker.isSoleTrader &&
                  "Sole Trader"}
                {agencyWorker.paymentType === "ltd" &&
                  !agencyWorker.isSoleTrader &&
                  "Ltd"}
              </ProposeWorkerPaymentDetailsEmploymentLabel>

              <SkillsAquired proposal={false}>
                {skillsAquired?.total === 0 ? null : skillsAquired?.aquired >=
                  skillsAquired?.total ? (
                  <AllSkills>
                    <AllSkillsIcon /> {skillsAquired?.aquired} of{" "}
                    {skillsAquired?.total}{" "}
                  </AllSkills>
                ) : skillsAquired?.aquired === 0 ? (
                  <NoSkills>
                    <NoSkillsIcon />
                    {skillsAquired?.aquired} of {skillsAquired?.total}
                  </NoSkills>
                ) : (
                  skillsAquired?.aquired < skillsAquired?.total && (
                    <SomeSkills>
                      <SomeSkillsIcon /> {skillsAquired?.aquired} of{" "}
                      {skillsAquired?.total}
                    </SomeSkills>
                  )
                )}
              </SkillsAquired>
            </ProposeWorkerPaymentDetailsContainer>
          )}
        </div>
      )}

      {proposal && (
        <ProposeWorkerPaymentDetailsContainer>
          <ProposeWorkerPaymentDetailsEmploymentLabel>
            {proposal.agencyWorker.employmentType === "direct_engagement"
              ? "DE"
              : "NonDE"}
          </ProposeWorkerPaymentDetailsEmploymentLabel>

          <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_payment_type">
            {proposal.agencyWorker.paymentType === "paye" && "Paye"}
            {proposal.agencyWorker.paymentType === "umbrella_company" &&
              "Umbrella"}

            {proposal.agencyWorker.paymentType === "ltd" &&
              proposal.agencyWorker.isSoleTrader &&
              "Sole Trader"}
            {proposal.agencyWorker.paymentType === "ltd" &&
              !proposal.agencyWorker.isSoleTrader &&
              "Ltd"}
          </ProposeWorkerPaymentDetailsEmploymentLabel>

          <SkillsAquired proposal={true}>
            {skillsAquired?.total === 0 ? null : skillsAquired?.aquired >=
              skillsAquired?.total ? (
              <AllSkills>
                <AllSkillsIcon /> {skillsAquired?.aquired} of{" "}
                {skillsAquired?.total}{" "}
              </AllSkills>
            ) : skillsAquired?.aquired === 0 ? (
              <NoSkills>
                <NoSkillsIcon />
                {skillsAquired?.aquired} of {skillsAquired?.total}
              </NoSkills>
            ) : (
              skillsAquired?.aquired < skillsAquired?.total && (
                <SomeSkills>
                  <SomeSkillsIcon /> {skillsAquired?.aquired} of{" "}
                  {skillsAquired?.total}
                </SomeSkills>
              )
            )}
          </SkillsAquired>
        </ProposeWorkerPaymentDetailsContainer>
      )}

      {proposal && (
        <DefaultButton
          action={() => {
            proposeWorkerAction(
              {
                ...proposal?.agencyWorker,
                readonly: true,
              },
              proposal?.rates
            );
          }}
          color="blue"
          data-cy="button_view_proposal"
          style={{
            maxWidth: 125,
            width: "100%",
            position: "absolute",
            top: "21px",
            right: readonly ? "70px" : "150px",
          }}
          text="View"
        />
      )}

      {proposeWorkerAction && !readonly ? (
        <DefaultButton
          action={() =>
            proposal
              ? proposeWorkerDeleteAction(proposal)
              : proposeWorkerAction(agencyWorker)
          }
          color={proposal ? "red" : "blue"}
          data-cy={`button_${proposal ? "revoke_worker" : "propose_worker"}`}
          style={{ maxWidth: 125, width: "100%" }}
          text={proposal ? "Revoke" : "Propose"}
        />
      ) : (
        <div style={{ maxWidth: 125, width: "100%" }} />
      )}
    </ProposeWorkerContainer>
  );
};

export default ShiftModalProposeWorker;

export const ProposeWorkerContainer = styled.div`
  align-items: center;
  background-color: ${GLOBAL.backgroundColor};
  border-radius: 8px;
  display: flex;
  height: 85px;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ProposeWorkerDetailsContainer = styled.div`
  align-items: center;
  display: flex;

  display: -ms-flexbox;
  -ms-flex-align: center;
`;

export const ProposeWorkerDetailsAvatar = styled.img`
  border-radius: 25px;
  height: 50px;
  margin-right: 16px;
  width: 50px;
`;

export const ProposeWorkerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProposeWorkerDetailsName = styled.span`
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: underline;
`;

export const ProposeWorkerDetailsGrade = styled(ProposeWorkerDetailsName)<{
  color?: string;
}>`
  color: ${({ color }) => (color ? color : GLOBAL.fontDark)};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: none;
`;

export const ProposeWorkerDetailsEmail = styled(ProposeWorkerDetailsGrade)`
  color: ${GREY.six};
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
`;

export const ProposeWorkerPaymentDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 250px;
`;

export const ProposeWorkerPaymentDetailsEmploymentLabel = styled.div`
  align-items: center;
  background-color: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const SkillsAquired = styled.div<{ proposal: boolean }>`
  position: absolute;
  right: ${(props) => (props.proposal ? "60px" : "-70px")};
  top: 0;
  svg {
    height: 16px;
    margin-right: 5px;
    width: 9px;
  }
`;

const NoSkills = styled.div`
  align-items: center;
  background: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  justify-content: center;
  padding-left: 2px;
  width: 55px;
`;

const SomeSkills = styled.div`
  align-items: center;
  background: ${YELLOW.one};
  border-radius: 4px;
  color: #bd9211;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  justify-content: center;
  opacity: 0.64;
  padding-left: 2px;
  width: 55px;
`;

const AllSkills = styled.div`
  align-items: center;
  background: ${GREEN.two};
  border-radius: 4px;
  color: ${GREEN.five};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  justify-content: center;
  padding-left: 2px;
  width: 55px;
`;

const NoSkillsIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 10 15"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.5C7.20914 9.5 9 7.70914 9 5.5C9 3.29086 7.20914 1.5 5 1.5C2.79086 1.5 1 3.29086 1 5.5C1 7.70914 2.79086 9.5 5 9.5Z"
      stroke="#646E7B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
    <path
      d="M2.83428 8.86573L2.14285 14.0714L5 12.3572L7.85714 14.0714L7.16571 8.86002"
      stroke="#646E7B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
  </svg>
);

const SomeSkillsIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 10 15"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.5C7.20914 9.5 9 7.70914 9 5.5C9 3.29086 7.20914 1.5 5 1.5C2.79086 1.5 1 3.29086 1 5.5C1 7.70914 2.79086 9.5 5 9.5Z"
      stroke="#BD9211"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
    <path
      d="M2.83428 8.86573L2.14285 14.0714L5 12.3572L7.85714 14.0714L7.16571 8.86002"
      stroke="#BD9211"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
  </svg>
);

const AllSkillsIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 10 15"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.5C7.20914 9.5 9 7.70914 9 5.5C9 3.29086 7.20914 1.5 5 1.5C2.79086 1.5 1 3.29086 1 5.5C1 7.70914 2.79086 9.5 5 9.5Z"
      stroke="#316959"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
    <path
      d="M2.83428 8.86573L2.14285 14.0714L5 12.3572L7.85714 14.0714L7.16571 8.86002"
      stroke="#316959"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.21429"
    />
  </svg>
);
