import * as Types from '../../baseCODEGEN';

import { gql } from '@apollo/client';
import { LoggedInAgencyUserFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentAgencyUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentAgencyUserQuery = { __typename?: 'Query', currentAgencyUser?: { __typename?: 'AgencyUser', canCreateAgencyWorkers?: boolean | null, canManageUsers?: boolean | null, email?: string | null, firstName?: string | null, id: string, jobTitle?: string | null, lastName?: string | null, suspended?: boolean | null, uuid?: string | null, agency?: { __typename?: 'Agency', id: string, shortTitle?: string | null, title?: string | null, organisations?: Array<{ __typename?: 'Organisation', logoUrl?: string | null, usesDeSoleTrader: boolean, hideDeLtdAgencyWorkers: boolean, label?: string | null, value: number }> | null } | null, agencyUserOrganisations?: Array<{ __typename?: 'AgencyUserOrganisation', id: string, organisation?: { __typename?: 'Organisation', logoUrl?: string | null, label?: string | null, value: number, departments: Array<{ __typename?: 'Department', id: number, name?: string | null }>, grades?: Array<{ __typename?: 'Grade', id: string, title: string }> | null } | null }> | null, agencyUserPermissions?: Array<{ __typename?: 'AgencyUserPermission', organisationStaffGroup: { __typename?: 'OrganisationStaffGroup', organisation?: { __typename?: 'Organisation', id: number, logoUrl?: string | null, name?: string | null, usesDeSoleTrader: boolean, hideDeLtdAgencyWorkers: boolean, departments: Array<{ __typename?: 'Department', id: number, name?: string | null }>, grades?: Array<{ __typename?: 'Grade', id: string, title: string }> | null } | null } }> | null } | null };

export type CurrentOrganisationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentOrganisationQuery = { __typename?: 'Query', currentOrganisation?: { __typename?: 'Organisation', id: number, name?: string | null, description?: string | null, logoUrl?: string | null } | null };

export type StaffGroupsQueryVariables = Types.Exact<{
  organisationId?: Types.InputMaybe<Types.Scalars['Int']>;
  organisationIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type StaffGroupsQuery = { __typename?: 'Query', organisationStaffGroups: Array<{ __typename?: 'OrganisationStaffGroup', id: string, staffGroup?: { __typename?: 'StaffGroup', idValue: number, title: string } | null }> };

export type GradesQueryVariables = Types.Exact<{
  staffGroupId: Types.Scalars['Int'];
  organisationId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GradesQuery = { __typename?: 'Query', grades: Array<{ __typename?: 'Grade', id: string, title: string }> };

export type SpecialitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SpecialitiesQuery = { __typename?: 'Query', specialities: Array<{ __typename?: 'Speciality', id: number, title?: string | null }> };

export type ReasonsForCancellationQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
}>;


export type ReasonsForCancellationQuery = { __typename?: 'Query', reasonsForCancellation: Array<{ __typename?: 'ReasonForCancellation', id: number, reason: string }> };

export type GetCalendarShiftsQueryVariables = Types.Exact<{
  fromStartTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  toStartTime?: Types.InputMaybe<Types.Scalars['DateTime']>;
  status?: Types.InputMaybe<Types.ShiftStatus>;
  filterByShiftBlocks?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetCalendarShiftsQuery = { __typename?: 'Query', vmsShifts?: { __typename?: 'ShiftConnection', totalCount: number, edges?: Array<{ __typename?: 'ShiftEdge', node?: { __typename?: 'Shift', id?: number | null, status: Types.ShiftStatus, startTime: any, endTime: any } | null } | null> | null } | null };

export type ShiftDetailssQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type ShiftDetailssQuery = { __typename?: 'Query', staffGroups: Array<{ __typename?: 'StaffGroup', value: number, label: string }>, organisation: { __typename?: 'Organisation', sites?: Array<{ __typename?: 'Site', value: number, label?: string | null }> | null, grades?: Array<{ __typename?: 'Grade', value: string, label: string }> | null, organisationStaffGroups: Array<{ __typename?: 'OrganisationStaffGroup', value: string, label?: string | null }> } };


export const CurrentAgencyUserDocument = gql`
    query CurrentAgencyUser {
  currentAgencyUser {
    ...LoggedInAgencyUser
  }
}
    ${LoggedInAgencyUserFragmentDoc}`;

/**
 * __useCurrentAgencyUserQuery__
 *
 * To run a query within a React component, call `useCurrentAgencyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAgencyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAgencyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAgencyUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAgencyUserQuery, CurrentAgencyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAgencyUserQuery, CurrentAgencyUserQueryVariables>(CurrentAgencyUserDocument, options);
      }
export function useCurrentAgencyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAgencyUserQuery, CurrentAgencyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAgencyUserQuery, CurrentAgencyUserQueryVariables>(CurrentAgencyUserDocument, options);
        }
export type CurrentAgencyUserQueryHookResult = ReturnType<typeof useCurrentAgencyUserQuery>;
export type CurrentAgencyUserLazyQueryHookResult = ReturnType<typeof useCurrentAgencyUserLazyQuery>;
export type CurrentAgencyUserQueryResult = Apollo.QueryResult<CurrentAgencyUserQuery, CurrentAgencyUserQueryVariables>;
export const CurrentOrganisationDocument = gql`
    query CurrentOrganisation {
  currentOrganisation {
    id
    name
    description
    logoUrl
  }
}
    `;

/**
 * __useCurrentOrganisationQuery__
 *
 * To run a query within a React component, call `useCurrentOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<CurrentOrganisationQuery, CurrentOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentOrganisationQuery, CurrentOrganisationQueryVariables>(CurrentOrganisationDocument, options);
      }
export function useCurrentOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentOrganisationQuery, CurrentOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentOrganisationQuery, CurrentOrganisationQueryVariables>(CurrentOrganisationDocument, options);
        }
export type CurrentOrganisationQueryHookResult = ReturnType<typeof useCurrentOrganisationQuery>;
export type CurrentOrganisationLazyQueryHookResult = ReturnType<typeof useCurrentOrganisationLazyQuery>;
export type CurrentOrganisationQueryResult = Apollo.QueryResult<CurrentOrganisationQuery, CurrentOrganisationQueryVariables>;
export const StaffGroupsDocument = gql`
    query StaffGroups($organisationId: Int, $organisationIds: [Int!]) {
  organisationStaffGroups(
    organisationId: $organisationId
    organisationIds: $organisationIds
  ) {
    id
    staffGroup {
      idValue
      title
    }
  }
}
    `;

/**
 * __useStaffGroupsQuery__
 *
 * To run a query within a React component, call `useStaffGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffGroupsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      organisationIds: // value for 'organisationIds'
 *   },
 * });
 */
export function useStaffGroupsQuery(baseOptions?: Apollo.QueryHookOptions<StaffGroupsQuery, StaffGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffGroupsQuery, StaffGroupsQueryVariables>(StaffGroupsDocument, options);
      }
export function useStaffGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffGroupsQuery, StaffGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffGroupsQuery, StaffGroupsQueryVariables>(StaffGroupsDocument, options);
        }
export type StaffGroupsQueryHookResult = ReturnType<typeof useStaffGroupsQuery>;
export type StaffGroupsLazyQueryHookResult = ReturnType<typeof useStaffGroupsLazyQuery>;
export type StaffGroupsQueryResult = Apollo.QueryResult<StaffGroupsQuery, StaffGroupsQueryVariables>;
export const GradesDocument = gql`
    query Grades($staffGroupId: Int!, $organisationId: Int) {
  grades(staffGroupId: $staffGroupId, organisationId: $organisationId) {
    id
    title
  }
}
    `;

/**
 * __useGradesQuery__
 *
 * To run a query within a React component, call `useGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesQuery({
 *   variables: {
 *      staffGroupId: // value for 'staffGroupId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGradesQuery(baseOptions: Apollo.QueryHookOptions<GradesQuery, GradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GradesQuery, GradesQueryVariables>(GradesDocument, options);
      }
export function useGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GradesQuery, GradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GradesQuery, GradesQueryVariables>(GradesDocument, options);
        }
export type GradesQueryHookResult = ReturnType<typeof useGradesQuery>;
export type GradesLazyQueryHookResult = ReturnType<typeof useGradesLazyQuery>;
export type GradesQueryResult = Apollo.QueryResult<GradesQuery, GradesQueryVariables>;
export const SpecialitiesDocument = gql`
    query Specialities {
  specialities(archived: false) {
    id
    title
  }
}
    `;

/**
 * __useSpecialitiesQuery__
 *
 * To run a query within a React component, call `useSpecialitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecialitiesQuery(baseOptions?: Apollo.QueryHookOptions<SpecialitiesQuery, SpecialitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialitiesQuery, SpecialitiesQueryVariables>(SpecialitiesDocument, options);
      }
export function useSpecialitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialitiesQuery, SpecialitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialitiesQuery, SpecialitiesQueryVariables>(SpecialitiesDocument, options);
        }
export type SpecialitiesQueryHookResult = ReturnType<typeof useSpecialitiesQuery>;
export type SpecialitiesLazyQueryHookResult = ReturnType<typeof useSpecialitiesLazyQuery>;
export type SpecialitiesQueryResult = Apollo.QueryResult<SpecialitiesQuery, SpecialitiesQueryVariables>;
export const ReasonsForCancellationDocument = gql`
    query ReasonsForCancellation($organisationId: Int!) {
  reasonsForCancellation(organisationId: $organisationId) {
    id
    reason
  }
}
    `;

/**
 * __useReasonsForCancellationQuery__
 *
 * To run a query within a React component, call `useReasonsForCancellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonsForCancellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonsForCancellationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useReasonsForCancellationQuery(baseOptions: Apollo.QueryHookOptions<ReasonsForCancellationQuery, ReasonsForCancellationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReasonsForCancellationQuery, ReasonsForCancellationQueryVariables>(ReasonsForCancellationDocument, options);
      }
export function useReasonsForCancellationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReasonsForCancellationQuery, ReasonsForCancellationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReasonsForCancellationQuery, ReasonsForCancellationQueryVariables>(ReasonsForCancellationDocument, options);
        }
export type ReasonsForCancellationQueryHookResult = ReturnType<typeof useReasonsForCancellationQuery>;
export type ReasonsForCancellationLazyQueryHookResult = ReturnType<typeof useReasonsForCancellationLazyQuery>;
export type ReasonsForCancellationQueryResult = Apollo.QueryResult<ReasonsForCancellationQuery, ReasonsForCancellationQueryVariables>;
export const GetCalendarShiftsDocument = gql`
    query GetCalendarShifts($fromStartTime: DateTime, $toStartTime: DateTime, $status: ShiftStatus, $filterByShiftBlocks: Boolean) {
  vmsShifts(
    fromStartTime: $fromStartTime
    toStartTime: $toStartTime
    status: $status
    filterByShiftBlocks: $filterByShiftBlocks
  ) {
    edges {
      node {
        id
        status
        startTime
        endTime
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetCalendarShiftsQuery__
 *
 * To run a query within a React component, call `useGetCalendarShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarShiftsQuery({
 *   variables: {
 *      fromStartTime: // value for 'fromStartTime'
 *      toStartTime: // value for 'toStartTime'
 *      status: // value for 'status'
 *      filterByShiftBlocks: // value for 'filterByShiftBlocks'
 *   },
 * });
 */
export function useGetCalendarShiftsQuery(baseOptions?: Apollo.QueryHookOptions<GetCalendarShiftsQuery, GetCalendarShiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalendarShiftsQuery, GetCalendarShiftsQueryVariables>(GetCalendarShiftsDocument, options);
      }
export function useGetCalendarShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarShiftsQuery, GetCalendarShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalendarShiftsQuery, GetCalendarShiftsQueryVariables>(GetCalendarShiftsDocument, options);
        }
export type GetCalendarShiftsQueryHookResult = ReturnType<typeof useGetCalendarShiftsQuery>;
export type GetCalendarShiftsLazyQueryHookResult = ReturnType<typeof useGetCalendarShiftsLazyQuery>;
export type GetCalendarShiftsQueryResult = Apollo.QueryResult<GetCalendarShiftsQuery, GetCalendarShiftsQueryVariables>;
export const ShiftDetailssDocument = gql`
    query shiftDetailss($id: Int!) {
  staffGroups(extended: true) {
    value: idValue
    label: title
  }
  organisation(id: $id) {
    sites {
      value: id
      label: name
    }
    grades {
      value: id
      label: title
    }
    organisationStaffGroups {
      value: id
      label: title
    }
  }
}
    `;

/**
 * __useShiftDetailssQuery__
 *
 * To run a query within a React component, call `useShiftDetailssQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftDetailssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftDetailssQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShiftDetailssQuery(baseOptions: Apollo.QueryHookOptions<ShiftDetailssQuery, ShiftDetailssQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShiftDetailssQuery, ShiftDetailssQueryVariables>(ShiftDetailssDocument, options);
      }
export function useShiftDetailssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShiftDetailssQuery, ShiftDetailssQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShiftDetailssQuery, ShiftDetailssQueryVariables>(ShiftDetailssDocument, options);
        }
export type ShiftDetailssQueryHookResult = ReturnType<typeof useShiftDetailssQuery>;
export type ShiftDetailssLazyQueryHookResult = ReturnType<typeof useShiftDetailssLazyQuery>;
export type ShiftDetailssQueryResult = Apollo.QueryResult<ShiftDetailssQuery, ShiftDetailssQueryVariables>;