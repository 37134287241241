import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import styled from "styled-components";

//
import {
  CloseButton,
  DefaultButton,
  FormBreak,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormToggle,
  GREY,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@patchworkhealth/web-components";

import { createFormFunctions, FormatError } from "helpers/functions";
//
import { RootState } from "models/store";

import {
  useAgencyUserCreateMutation,
  useAgencyUserUpdateMutation,
  useGetAgencyUserQuery,
} from "./__generated__/AgencyUsersHelpers.generated";
import AgencyUsersPermissions from "./AgencyUsersPermissions";
import AgencyUserSuspendModal from "./AgencyUserSuspendModal";

const AgencyUserModal = ({
  agencyUser,
  handleCloseAction,
  refetch,
  setShowAgencyUser,
  show,
}: AgencyUsersModalProps) => {
  /* STATE ********************************************************/

  const user = useSelector((state: RootState) => state.user);
  const [action, setAction] = useState(null);
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState(emptyInputs);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [showNewUserPermissions, setShowNewUserPermissions] = useState(false);

  const { handleInputChange, handleToggle } = createFormFunctions(
    setInputs,
    setError
  );

  /* USE EFFECTS ****************************************************/

  useEffect(() => {
    if (agencyUser) {
      setInputs({
        firstName: agencyUser.firstName ?? "",
        lastName: agencyUser.lastName ?? "",
        jobTitle: agencyUser.jobTitle ?? "",
        email: agencyUser.email ?? "",
        phoneNumber: agencyUser.phoneNumber ?? "",
        agencyId: Number(agencyUser.agency.id) ?? Number(user.agency.id),
        canManageUsers: agencyUser.canManageUsers ?? false,
        organisations: [],
      });
      setSuspended(agencyUser.suspended ?? false);
    }
  }, [agencyUser, user]);

  /* GQL ****************************************************/

  const { data: aa, refetch: refetchUser } = useGetAgencyUserQuery({
    skip: !agencyUser?.email,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      email: agencyUser?.email,
    },
    onCompleted: () => {
      let node = aa?.vmsAgencyUsers?.list[0];
      const filteredOrgs = [
        ...new Map(
          node?.agency?.organisations?.map((item) => [item.name, item])
        ).values(),
      ];

      let organisations = filteredOrgs.map((cc) => ({
        id: cc?.id,
        name: cc?.name,
        permissions: node?.agencyUserPermissions?.filter(
          (dd) => dd.organisationStaffGroup.organisation.name === cc.name
        ),
        options: node?.agency?.organisationAgencies.filter(
          (o) => o?.organisation?.name === cc.name
        ),
      }));

      setInputs((s) => ({
        ...s,
        organisations,
      }));
    },
  });

  const [agencyUserCreate] = useAgencyUserCreateMutation();
  const [agencyUserUpdate] = useAgencyUserUpdateMutation();

  const agencyUserCreateAction = async () => {
    setError(null);
    if (validateRequiredInputs()) {
      const toastId = toast.loading("Creating Agency User...");
      try {
        setLoading(true);
        const { data: result } = await agencyUserCreate({
          variables: {
            ...inputs,
          },
        });
        setLoading(false);
        if (result?.vmsAgencyUserCreate?.errors?.length) {
          toast.error("An error occurred.", { id: toastId });
        } else {
          toast.success("Agency User created.", { id: toastId });
          await refetch();
          setShowAgencyUser(result.vmsAgencyUserCreate.agencyUser);
        }
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  const agencyUserUpdateAction = async () => {
    setError(null);
    if (validateRequiredInputs()) {
      const toastId = toast.loading("Updating Agency User...");
      try {
        setLoading(true);
        const { data: result } = await agencyUserUpdate({
          variables: {
            id: Number(agencyUser.id),
            ...inputs,
            organisations: inputs.organisations
              .filter((o) => o.permissions.length > 0)
              .map((o) => ({
                id: o.id,
                organisationStaffGroups: o.permissions.map((p) => ({
                  organisationStaffGroupId: Number(p.id),
                  canProposeWorker: p.canProposeWorker,
                })),
              })),
          },
        });
        if (result?.vmsAgencyUserUpdate?.errors?.length) {
          setLoading(false);
          setError({
            message: FormatError(result.vmsAgencyUserUpdate.errors),
          });
          toast.error("An error occurred.", { id: toastId });
        } else {
          toast.success("Agency User updated.", { id: toastId });
          await refetch();
          setLoading(false);
          handleCloseAction();
        }
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    }
  };

  /* HANDLE FUNCTIONS ****************************************************/

  const validateRequiredInputs = () => {
    if (inputs.firstName === "") {
      setError({
        ...error,
        firstName: "Please input a First Name.",
      });
    } else if (inputs.lastName === "") {
      setError({
        ...error,
        lastName: "Please input a Last Name.",
      });
    } else if (inputs.jobTitle === "") {
      setError({
        ...error,
        jobTitle: "Please input a Job Title.",
      });
    } else if (inputs.email === "") {
      setError({
        ...error,
        email: "Please input a Email.",
      });
    } else if (inputs.phoneNumber === "") {
      setError({
        ...error,
        phoneNumber: "Please input a Phone No.",
      });
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        centered
        data-testid="users__modal"
        onHide={() => handleCloseAction}
        show={show}
        size="lg"
      >
        <ModalHeader>
          <ModalTitle>{agencyUser ? `Edit User` : "Create User"}</ModalTitle>
          <CloseButton onClick={handleCloseAction} />
        </ModalHeader>
        {loading ? (
          <Loading modal />
        ) : (
          <>
            <ModalBody>
              <FormGridContainer>
                <FormGrid columns={3}>
                  <FormGroup>
                    <FormLabel>
                      Enter First Name<span>*</span>
                    </FormLabel>
                    <FormInput
                      defaultValue={inputs.firstName}
                      name="firstName"
                      onChange={handleInputChange}
                      placeholder="Enter First Name..."
                      type="text"
                    />
                    {error?.firstName && (
                      <FormErrorLabel>{error.firstName}</FormErrorLabel>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>
                      Enter Last Name<span>*</span>
                    </FormLabel>
                    <FormInput
                      defaultValue={inputs.lastName}
                      name="lastName"
                      onChange={handleInputChange}
                      placeholder="Enter Last Name..."
                      type="text"
                    />
                    {error?.lastName && (
                      <FormErrorLabel>{error.lastName}</FormErrorLabel>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>
                      Job Title<span>*</span>
                    </FormLabel>
                    <FormInput
                      defaultValue={inputs.jobTitle}
                      name="jobTitle"
                      onChange={handleInputChange}
                      placeholder="Enter Job Title..."
                      type="text"
                    />
                    {error?.jobTitle && (
                      <FormErrorLabel>{error.jobTitle}</FormErrorLabel>
                    )}
                  </FormGroup>
                </FormGrid>
              </FormGridContainer>
              <FormGridContainer>
                <FormGrid columns={3}>
                  <FormGroup>
                    <FormLabel>
                      Email<span>*</span>
                    </FormLabel>
                    <FormInput
                      defaultValue={inputs.email}
                      name="email"
                      onChange={handleInputChange}
                      placeholder="Enter Email..."
                      type="text"
                    />
                    {error?.email && (
                      <FormErrorLabel>{error.email}</FormErrorLabel>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>
                      Phone No<span>*</span>
                    </FormLabel>
                    <FormInput
                      defaultValue={inputs.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                      placeholder="Enter Phone No..."
                      type="text"
                    />
                    {error?.phoneNumber && (
                      <FormErrorLabel>{error.phoneNumber}</FormErrorLabel>
                    )}
                  </FormGroup>
                </FormGrid>
              </FormGridContainer>

              <>
                <FormGridContainer>
                  <FormGrid columns={4}>
                    <FormToggle
                      action={handleToggle}
                      label="Can manage users"
                      name="canManageUsers"
                      value={inputs.canManageUsers}
                    />
                  </FormGrid>
                </FormGridContainer>

                <FormGridContainer>
                  {inputs?.organisations?.length > 0 && (
                    <>
                      <FormBreak />
                      <h3
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          marginBottom: 20,
                        }}
                      >
                        Permissions
                      </h3>
                    </>
                  )}

                  {inputs?.organisations?.map((org) => (
                    <OrgPermission>
                      <h2>{org.name}</h2>

                      {org?.permissions?.map((permission, index) => (
                        <AgencyUsersPermissions
                          key={permission.id}
                          agencyUserPermission={permission}
                          agencyUserPermissions={org.permissions}
                          index={index}
                          organisationAgencies={aa?.vmsAgencyUsers?.list[0].agency?.organisationAgencies.filter(
                            (cc) => cc.organisation.name === org.name
                          )}
                          refetchAgencyUsers={refetchUser}
                        />
                      ))}

                      {showNewUserPermissions &&
                        showNewUserPermissions === org.id && (
                          <AgencyUsersPermissions
                            agencyUserId={Number(agencyUser.id)}
                            agencyUserPermissions={org.permissions}
                            organisationAgencies={aa?.vmsAgencyUsers?.list[0].agency?.organisationAgencies.filter(
                              (cc) => cc.organisation.name === org.name
                            )}
                            refetchAgencyUsers={refetchUser}
                            setShowNewAgencyUserPermissions={
                              setShowNewUserPermissions
                            }
                          />
                        )}

                      {org.permissions.length < org.options.length && (
                        <AddStaffGroupButton
                          onClick={() => setShowNewUserPermissions(org.id)}
                        >
                          <AddStaffGroupIcon />
                          Add Another Staff Group
                        </AddStaffGroupButton>
                      )}
                    </OrgPermission>
                  ))}
                </FormGridContainer>
              </>
            </ModalBody>
            <ModalFooter>
              <div>
                {agencyUser && suspended !== true ? (
                  <div />
                ) : (
                  <DefaultButton
                    action={() => {
                      setAction("unsuspend");
                      setShowConfirm(true);
                    }}
                    text="Reactivate User"
                    type="white"
                  />
                )}
              </div>
              <div>
                <DefaultButton
                  action={handleCloseAction}
                  text="Cancel"
                  type="white"
                />
                {agencyUser ? (
                  <DefaultButton
                    action={() => agencyUserUpdateAction()}
                    color="blue"
                    text="Update User"
                  />
                ) : (
                  <DefaultButton
                    action={() => agencyUserCreateAction()}
                    color="blue"
                    text="Create User"
                  />
                )}
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
      {showConfirm && (
        <AgencyUserSuspendModal
          action={action}
          agencyUser={agencyUser}
          handleCloseConfirm={() => setShowConfirm(!showConfirm)}
          refetch={refetch}
          setSuspended={setSuspended}
          showConfirm={showConfirm}
        />
      )}
    </>
  );
};

export default AgencyUserModal;

type AgencyUsersModalProps = {
  agencyUser: any;
  handleCloseAction: Function;
  refetch: Function;
  setShowAgencyUser: Function;
  show: boolean;
};

const emptyInputs = {
  firstName: "",
  lastName: "",
  jobTitle: "",
  email: "",
  phoneNumber: "",
  agencyId: null,
  canManageUsers: false,
  organisations: [],
};

const OrgPermission = styled.div`
  margin-bottom: 22px;
  padding: 12px;

  h2 {
    font-size: 14px;
    font-weight: bold;
  }
`;

const AddStaffGroupButton = styled.button`
  background-color: ${GREY.two};
  border: 0;
  border-radius: 8px;
  color: ${GREY.six};
  font-size: 14px;
  font-weight: 600;
  height: 42px;
  line-height: 2;
  margin: 0 auto;
  margin-top: 12px;
  padding: 0;
  text-align: center;
  width: 270px;

  svg {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
`;

const AddStaffGroupIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 18.3332C14.6023 18.3332 18.3333 14.6022 18.3333 9.99984C18.3333 5.39746 14.6023 1.6665 9.99996 1.6665C5.39759 1.6665 1.66663 5.39746 1.66663 9.99984C1.66663 14.6022 5.39759 18.3332 9.99996 18.3332Z"
      stroke={GREY.six}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M10 6.6665V13.3332"
      stroke={GREY.six}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M6.66663 10H13.3333"
      stroke={GREY.six}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
