import styled from "styled-components";

import { ModalFooter } from "@patchworkhealth/web-components";

export const ModalCustomFooter = styled(ModalFooter)`
  bottom: 0;
  left: 2px;
  position: absolute;
  right: 2px;
`;
