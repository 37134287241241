import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import AgencyShiftsModal from "components/AgencyShifts/AgencyShiftsModal";
import AgencyWorkerModalNew from "components/AgencyWorkers/AgencyWorkersModalNew";
import { useShiftBlocksQuery } from "components/Blocks/__generated__/BlocksHelpers.generated";
import BlocksModal from "components/Blocks/BlocksModal";
import BlocksTable from "components/Blocks/BlocksTable";
import Filters from "components/Filters";
import TableHeader from "components/TableHeader/TableHeader";
import { createFormFunctions, formatToIds } from "helpers/functions";
import { usePagination } from "helpers/usePagination";
import { RootState } from "models/store";

import { FiltersContainer, IconFilter } from "./shifts";

const BlocksPage = () => {
  // STATE ************************************
  const [inputs, setInputs] = useState(emptyInputs);
  const [organisationIds, setOrganisationIds] = useState([]);
  const [rowsSearched, setRowsSearched] = useState(25);
  const [pagination, setPagination, resetPagination] =
    usePagination(rowsSearched);
  const { handleFilter } = createFormFunctions(setInputs);

  const organisation = useSelector((s: RootState) => s.organisation);

  //

  useEffect(() => {
    if (organisation) {
      setOrganisationIds(
        organisation.map((cc) => cc.organisationStaffGroup.organisation)
      );
    }
  }, [organisation]);

  // GRAPHQL ************************************
  const {
    data: blocks,
    loading,
    refetch,
  } = useShiftBlocksQuery({
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: {
      ...pagination,
      shiftBlockIds:
        inputs.searchText === "" ? [] : [Number(inputs.searchText)],
      startDateFrom: inputs.searchText === "" ? inputs.fromDateValue : null,
      startDateTo: inputs.toDateValue,
      statuses: inputs?.status?.label ? [inputs?.status?.value] : null,
      gradeIds: [...formatToIds(inputs.gradeIds)],
      siteIds: [...formatToIds(inputs.siteIds)],
      organisationIds: formatToIds(organisationIds),
    },
  });

  // FUNCTIONS ************************************

  const setShowBlock = (id: any) => {
    setInputs({ ...inputs, show: true, blockId: id ?? null });
  };

  const setShowWorker = (aw) => {
    setInputs({ ...inputs, agencyWorker: aw, workerShow: true });
  };

  const handleCloseAction = () => {
    setInputs({ ...inputs, show: false });
  };

  const handleCloseWorker = () => {
    setInputs({ ...inputs, workerShow: false });
  };

  const pageInfo = blocks?.shiftBlocks?.pageInfo ?? "";

  return (
    <>
      <TableHeader
        buttonIcon={IconFilter}
        buttonText="Filters"
        buttonType="white"
        // calendar={false}
        dataCount={blocks?.shiftBlocks?.totalCount}
        handleShow={() => handleFilter(!inputs.filtersShow, "filtersShow")}
        inputPlaceholder="Search Block ID"
        resetPagination={resetPagination}
        setSearchText={(e) => handleFilter(e, "searchText")}
        title="Blocks"
      />

      <FiltersContainer show={inputs.filtersShow}>
        <Filters
          filtersHandleFilter={handleFilter}
          filtersInputs={inputs}
          resetPagination={resetPagination}
          setFiltersInputs={() => setInputs(emptyInputs)}
        />
      </FiltersContainer>

      <BlocksTable
        blocks={blocks?.shiftBlocks}
        loading={loading}
        pageInfo={pageInfo}
        pagination={pagination}
        rowsSearched={rowsSearched}
        setPagination={setPagination}
        setRowsSearched={(e) => {
          setRowsSearched(e);
          setPagination((s) => {
            window.history.replaceState(null, null, s.url);
            return {
              ...s,
              after: undefined,
              before: undefined,
              first: e,
              items: e,
            };
          });
        }}
        setShowBlock={setShowBlock}
        setShowWorker={setShowWorker}
      />

      {inputs.show && (
        <BlocksModal
          blockId={inputs.blockId}
          handleCloseAction={handleCloseAction}
          refetchBlocks={refetch}
          setShowShift={(e) => {
            handleFilter(e, "shiftId");
            handleFilter(true, "shiftShow");
          }}
          setShowWorker={setShowWorker}
          show={inputs.show}
        />
      )}

      {inputs.shiftShow && (
        <AgencyShiftsModal
          handleCloseAction={() => {
            handleFilter(null, "shiftId");
            handleFilter(false, "shiftShow");
          }}
          readonly={true}
          refetchWorkers={() => null}
          setShowWorker={setShowWorker}
          shiftId={inputs.shiftId}
          show={inputs.shiftShow}
        />
      )}

      {inputs.workerShow && (
        <AgencyWorkerModalNew
          agencyWorker={inputs.agencyWorker}
          handleCloseAction={handleCloseWorker}
          readonly={true}
          refetchWorkers={null}
          show={inputs.workerShow}
        />
      )}
    </>
  );
};

export default BlocksPage;

const emptyInputs = {
  workerShow: false,
  agencyWorker: null,
  show: false,
  searchText: "",
  filtersShow: false,
  blockId: null,

  blocks: true,
  status: null,
  siteIds: [],
  gradeIds: [],
  fromDateValue: dayjs(new Date()).format("YYYY-MM-DD"),
  toDateValue: null,
  //
  shiftId: null,
  shiftShow: false,
};
