import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import styled from "styled-components";

import { DefaultButton, ModalBody } from "@patchworkhealth/web-components";

import { ModalCustomFooter } from "components/styles";
import {
  createFormFunctions,
  FormatError,
  formatToIds,
} from "helpers/functions";

import {
  AgencyRegistrationFragment,
  useVmsAgencyRegistrationUpdateAgencyWorkerMutation,
} from "./__generated__/AgencyWorkersHelpers.generated";
import ShiftModalEmployment from "./ShiftModalComponents/ShiftModalEmployment";
import ShiftModalExperience from "./ShiftModalComponents/ShiftModalExperience";
import ShiftModalHeader from "./ShiftModalComponents/ShiftModalHeader";
import ShiftModalProfile from "./ShiftModalComponents/ShiftModalProfile";
import ShiftModalSidebar from "./ShiftModalComponents/ShiftModalSidebar";

const AgencyWorkerModalNew = ({
  invitation,
  agencyWorker,
  handleCloseAction,
  show,
  refetchWorkers,
  readonly,
}: AgencyUsersModalProps) => {
  const [tab, setTab] = useState("profile");
  const [error, setError] = useState(null);
  const [inputs, setInputs] = useState(emptyInputs);

  /* FUNCTIONS ********************************** */

  const { handleFilter, handleInputChange } = createFormFunctions(
    setInputs,
    setError
  );

  const handleToggleButtons = (event, enumForState) => {
    setError(null);
    setInputs((input) => ({
      ...input,
      [event.target.name]: enumForState,
    }));
  };

  /* CHECK FOR USER DETAILS ******************** */

  useEffect(() => {
    if (agencyWorker) {
      setInputs({
        id: agencyWorker?.id,
        firstName: agencyWorker?.user?.firstName ?? "",
        lastName: agencyWorker?.user?.lastName ?? "",
        dateOfBirth: agencyWorker.user?.dateOfBirth ?? "",
        gender:
          agencyWorker.user?.gender === "MALE"
            ? 0
            : agencyWorker.user?.gender === "FEMALE"
            ? 1
            : 3, // null
        email: agencyWorker.user?.email ?? "",
        phoneNumber: agencyWorker.user?.phoneNumber ?? "",
        postcode: agencyWorker.user?.postcode ?? "",
        // Worker Info
        dbsNumber: agencyWorker.user?.dbsNumber ?? "",
        niNumber: agencyWorker.user?.niNumber ?? "",
        staffGroup: {
          label: agencyWorker.staffGroup?.title ?? "",
          value: agencyWorker.staffGroup?.idValue ?? null,
          masterStaffGroupId: agencyWorker.staffGroup?.idValue ?? null,
        },
        regBodyNumber: agencyWorker.user?.regBodyNumber ?? "",
        grade: {
          label: agencyWorker.grade?.title ?? "",
          value: agencyWorker.grade?.id ?? null,
        },
        specialityIds: [],
        skillIds: agencyWorker?.skills?.map((cc) => ({
          label: cc.name,
          value: cc.id,
        })),

        // Employment Info
        employmentType:
          agencyWorker.employmentType === "direct_engagement"
            ? 0
            : agencyWorker.employmentType === "non_direct_engagement"
            ? 1
            : 5, // null
        paymentType:
          agencyWorker.paymentType === "paye"
            ? 0
            : agencyWorker.paymentType === "umbrella_company"
            ? 1
            : agencyWorker.paymentType === "ltd"
            ? 2
            : 5, // null
        companyName: agencyWorker.companyName ?? "",
        companyNumber: agencyWorker.companyNumber ?? "",
        companyAddressLine1: agencyWorker.companyAddressLine1 ?? "",
        companyAddressLine2: agencyWorker.companyAddressLine2 ?? "",
        companyAddressTown: agencyWorker.companyAddressTown ?? "",
        companyAddressPostcode: agencyWorker.companyAddressPostcode ?? "",
        companyVatNumber: agencyWorker.companyVatNumber ?? "",
        compliancePackUrl: agencyWorker.compliancePackUrl,
        isVatRegistered: agencyWorker.isVatRegistered ?? false,
        payrollId: agencyWorker.paymentId,
        esrNumbers: agencyWorker.esrNumbers,
      });
    }
  }, [agencyWorker]);

  // Validation ************************************

  const validateRequiredInputs = () => {
    if (inputs.firstName === "") {
      setError({
        ...error,
        firstName: "Please input a First Name.",
      });
    } else if (inputs.lastName === "") {
      setError({
        ...error,
        lastName: "Please input a Last Name.",
      });
    } else if (inputs.dateOfBirth === "") {
      setError({
        ...error,
        dateOfBirth: "Please input a Date of Birth.",
      });
    } else if (inputs.gender === 3) {
      setError({
        ...error,
        gender: "Please select a Gender.",
      });
    } else if (inputs.email === "") {
      setError({
        ...error,
        email: "Please input a Email.",
      });
    } else if (inputs.phoneNumber === "") {
      setError({
        ...error,
        phoneNumber: "Please input a Phone No.",
      });
    } else if (/^((\+44)|(0))?\d{10}$/i.test(inputs.phoneNumber) === false) {
      setError({
        ...error,
        phoneNumber: "Incorrect Phone No. format.",
      });
    } else if (inputs.postcode === "") {
      setError({
        ...error,
        postcode: "Please input a Postcode.",
      });
    } else if (inputs.dbsNumber === "") {
      setError({
        ...error,
        dbsNumber: "Please input a DBS Number.",
      });
    } else if (inputs.niNumber === "") {
      setError({
        ...error,
        niNumber: "Please input a National Insurance No.",
      });
    } else if (
      /^[A-Z]{2}\d{6}[A-Z]$/i.test(inputs.niNumber.replaceAll(/\s/g, "")) ===
      false
    ) {
      setError({
        ...error,
        niNumber: "Incorrect National Insurance No. format.",
      });
    } else if (inputs.staffGroup.value === null) {
      setError({
        ...error,
        staffGroup: "Please select a Staff Group.",
      });
    } else if (inputs.regBodyNumber === "") {
      setError({
        ...error,
        regBodyNumber: "Please input a Registration Body No.",
      });
    } else if (inputs.grade.value === null) {
      setError({
        ...error,
        grade: "Please select a Grade.",
      });
    } else if (inputs.employmentType === 5) {
      setError({
        ...error,
        employmentType: "Please select a Employment Type.",
      });
    } else if (inputs.employmentType === 0 && inputs.staffGroup.value === 2) {
      setError({
        ...error,
        employmentType:
          "Employment type can't be Direct Engagement if the Staff Group is Nursing.",
      });
    } else if (inputs.paymentType === 5) {
      setError({
        ...error,
        paymentType: "Please select a Payment Type.",
      });
    } else if (inputs.employmentType === 0 && inputs.paymentType === 1) {
      setError({
        ...error,
        paymentType:
          "Payment type should be PAYE or LTD if employment type is Direct Engagement.",
      });
    } else if (
      (inputs.paymentType === 1 && inputs.companyName === undefined) ||
      (inputs.paymentType === 2 && inputs.companyName === undefined)
    ) {
      setError({
        ...error,
        companyName:
          "Company Name is required if payment type is Umbrella Company or Ltd.",
      });
    } else {
      return true;
    }
  };

  /* UPDATE WORKER *************************** */

  const [agencyWorkerUpdate] =
    useVmsAgencyRegistrationUpdateAgencyWorkerMutation({
      refetchQueries: ["GetAgencyWorkersQuery"],
    });
  const agencyWorkerUpdateAction = async () => {
    setError(null);
    if (validateRequiredInputs()) {
      const toastId = toast.loading("Updating Agency Worker...");
      try {
        const { data: result } = await agencyWorkerUpdate({
          variables: {
            id: agencyWorker.id,
            ...inputs,
            niNumber: inputs.niNumber.toUpperCase().replaceAll(/\s/g, ""),
            gender: Number(inputs.gender + 1),
            gradeId: Number(inputs.grade.value),
            specialityIds: formatToIds(inputs.specialityIds),
            skillIds: formatToIds(inputs.skillIds),
            paymentId: inputs.payrollId,
            employmentType:
              inputs.employmentType === 0
                ? "direct_engagement"
                : "non_direct_engagement",
            paymentType:
              inputs.paymentType === 0
                ? "paye"
                : inputs.paymentType === 1
                ? "umbrella_company"
                : "ltd",
          },
        });

        const errors = result?.vmsAgencyRegistrationUpdateAgencyWorker?.errors;

        if (errors.length) {
          setError({
            message: FormatError(errors),
          });
          toast.error(FormatError(errors), { id: toastId });
          return;
        }

        toast.success("Agency Worker updated.", { id: toastId });
        refetchWorkers();
        handleCloseAction();
      } catch (err) {
        toast.error("An error occurred.", { id: toastId });
      }
    } else {
      toast.error("Please Check all validation to save");
    }
  };

  // CREATE PROFILE **************************

  return (
    <Modal
      backdrop="static"
      centered
      data-testid="shifts__modal"
      onHide={() => handleCloseAction}
      show={show}
      size="lg"
    >
      <>
        <ShiftModalHeader
          agencyWorker={agencyWorker}
          handleCloseAction={handleCloseAction}
          inputs={inputs}
        />
        <ShiftModalContainer>
          <ShiftModalSidebar setTab={setTab} tab={tab} />
          <ShiftModalContentContainer readonly={readonly}>
            <ModalBody
              style={{
                minHeight: 520,
                paddingBottom: 100,
              }}
            >
              {readonly && (
                <InvitationAlert>
                  To edit a workers profile, please search on Active Workers
                  page
                </InvitationAlert>
              )}
              {tab === "profile" ? (
                <ShiftModalProfile
                  error={error}
                  handleInputChange={handleInputChange}
                  handleToggleButtons={handleToggleButtons}
                  inputs={inputs}
                  invitation={invitation}
                />
              ) : tab === "experience" ? (
                <ShiftModalExperience
                  agencyWorker={agencyWorker}
                  error={error}
                  handleFilter={handleFilter}
                  handleInputChange={handleInputChange}
                  inputs={inputs}
                  refetchWorkers={refetchWorkers}
                />
              ) : (
                tab === "employment" && (
                  <ShiftModalEmployment
                    error={error}
                    handleInputChange={handleInputChange}
                    handleToggleButtons={handleToggleButtons}
                    inputs={inputs}
                  />
                )
              )}

              <ModalCustomFooter>
                <div></div>
                <div>
                  {agencyWorker && !readonly && (
                    <>
                      <DefaultButton
                        action={handleCloseAction}
                        text="Cancel"
                        type="white"
                      />

                      <DefaultButton
                        action={agencyWorkerUpdateAction}
                        data-cy="saveChanges"
                        text="Save Changes"
                        type="blue"
                      />
                    </>
                  )}
                </div>
              </ModalCustomFooter>
            </ModalBody>
          </ShiftModalContentContainer>
        </ShiftModalContainer>
      </>
    </Modal>
  );
};

export default AgencyWorkerModalNew;

const ShiftModalContainer = styled.div`
  display: flex;
`;

const ShiftModalContentContainer = styled.span<{ readonly: Boolean }>`
  width: 100%;

  * {
    pointer-events: ${({ readonly }) => (readonly ? "none" : "initial")};
  }
`;

type AgencyUsersModalProps = {
  invitation?: any;
  agencyWorker: AgencyRegistrationFragment;
  handleCloseAction: Function;
  show: boolean;
  refetchWorkers: Function;
  readonly?: Boolean;
};

export const emptyInputs = {
  // Personal Info
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: 3, // null
  email: "",
  phoneNumber: "",
  postcode: "",
  id: null,
  // Worker Info
  dbsNumber: "",
  niNumber: "",
  staffGroup: {
    label: "",
    value: null,
    masterStaffGroupId: null,
  },
  regBodyNumber: "",
  grade: {
    label: "",
    value: null,
  },
  specialityIds: [],
  skillIds: [],
  // Employment Info
  employmentType: 5, // null
  paymentType: 5, // null
  companyName: undefined,
  companyNumber: undefined,
  companyAddressLine1: undefined,
  companyAddressLine2: undefined,
  companyAddressTown: undefined,
  companyAddressPostcode: undefined,
  companyVatNumber: undefined,
  isVatRegistered: false,
  compliancePackUrl: null,
  // WorkerModals
  payrollId: null,
  esrNumbers: [],
};

const InvitationAlert = styled.div`
  background: #d1f2f7;
  border-left: 17px solid #17bfd6;
  border-radius: 8px;
  color: #002033;
  font-size: 13px;
  font-weight: 600;
  height: 38px;
  margin-bottom: 18px;
  padding: 9px 8px;
  width: 547px;
`;
