import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  CloseButton,
  DefaultButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@patchworkhealth/web-components";

import {
  AgencyUserFragment,
  useSuspendAgencyUserMutation,
  useUnsuspendAgencyUserMutation,
} from "./__generated__/AgencyUsersHelpers.generated";

type AgencyUserSuspendModalProps = {
  action: string;
  agencyUser: AgencyUserFragment;
  handleCloseConfirm: Function;
  refetch: Function;
  setSuspended: Function;
  showConfirm: boolean;
};

const AgencyUserSuspendModal = ({
  action,
  agencyUser,
  handleCloseConfirm,
  refetch,
  setSuspended,
  showConfirm,
}: AgencyUserSuspendModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [suspendAgencyUser] = useSuspendAgencyUserMutation();
  const suspendAgencyUserAction = async () => {
    const toastId = toast.loading("Suspending User...");
    try {
      setConfirmLoading(true);
      const { data: result } = await suspendAgencyUser({
        variables: {
          id: Number(agencyUser.id),
        },
      });
      toast.dismiss();
      setConfirmLoading(false);
      if (result?.vmsAgencyUserSuspend !== true) {
        toast.error("An error occurred.", { id: toastId });
      } else {
        toast.success("User Suspended", { id: toastId });
        setSuspended(true);
        await refetch();
        handleCloseConfirm();
      }
    } catch (error) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const [unsuspendAgencyUser] = useUnsuspendAgencyUserMutation();
  const unsuspendAgencyUserAction = async () => {
    const toastId = toast.loading("Reactivating User...");
    try {
      setConfirmLoading(true);
      const { data: result } = await unsuspendAgencyUser({
        variables: {
          id: Number(agencyUser.id),
        },
      });
      toast.dismiss();
      setConfirmLoading(false);
      if (result?.vmsAgencyUserUnsuspend !== true) {
        toast.error("An error occurred.", { id: toastId });
      } else {
        toast.success("User Reactivated", { id: toastId });
        setSuspended(false);
        await refetch();
        handleCloseConfirm();
      }
    } catch (error) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  return (
    <Modal
      backdrop="static"
      centered
      onHide={() => handleCloseConfirm}
      show={showConfirm}
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          <span style={{ fontWeight: 600, textTransform: "capitalize" }}>
            {action}
          </span>{" "}
          User
        </ModalTitle>
        <CloseButton onClick={() => handleCloseConfirm()} />
      </ModalHeader>
      <ModalBody style={{ fontSize: 14, minHeight: 0 }}>
        Are you sure you want to {action} {agencyUser.firstName}{" "}
        {agencyUser.lastName}?
      </ModalBody>
      <ModalFooter>
        <div />
        <div>
          <DefaultButton
            action={handleCloseConfirm}
            text="Cancel"
            type="white"
          />
          {action === "suspend" ? (
            <DefaultButton
              action={() => suspendAgencyUserAction()}
              color="blue"
              disabled={confirmLoading}
              text={confirmLoading ? "Suspending" : "Confirm Suspend"}
            />
          ) : (
            <DefaultButton
              action={() => unsuspendAgencyUserAction()}
              color="blue"
              disabled={confirmLoading}
              text={confirmLoading ? "Reactivating" : "Confirm Reactivate"}
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AgencyUserSuspendModal;
