import { DefaultButton } from "@patchworkhealth/web-components";

import {
  ProposeWorkerContainer,
  ProposeWorkerDetails,
  ProposeWorkerDetailsAvatar,
  ProposeWorkerDetailsContainer,
  ProposeWorkerDetailsEmail,
  ProposeWorkerDetailsGrade,
  ProposeWorkerDetailsName,
  ProposeWorkerPaymentDetailsContainer,
  ProposeWorkerPaymentDetailsEmploymentLabel,
} from "components/AgencyShifts/ShiftModalComponents/ShiftModalProposeWorkers/ShiftModalProposeWorker";
import { defaultImgOnError } from "helpers/functions";

const BlockProposalCard = ({
  setShowWorker,
  canUserEdit,
  viewWorkerAction,
  proposal,
  withdrawProposalAction,
}: BlockProposalCardProps) => (
  <ProposeWorkerContainer>
    <ProposeWorkerDetailsContainer>
      <ProposeWorkerDetailsAvatar
        alt="Profile Picture"
        onError={defaultImgOnError}
        src={`${process.env.REACT_APP_API}${proposal?.agencyWorker?.user?.profilePictureUrl}`}
      />

      <ProposeWorkerDetails>
        <ProposeWorkerDetailsName onClick={() => setShowWorker(proposal)}>
          {proposal?.agencyWorker?.firstName}
          {proposal?.agencyWorker?.lastName}
        </ProposeWorkerDetailsName>
        <ProposeWorkerDetailsGrade
          color={proposal?.agencyWorker?.grade?.colour}
        >
          {proposal?.agencyWorker?.grade?.title}
        </ProposeWorkerDetailsGrade>
        <ProposeWorkerDetailsEmail>
          {proposal?.agencyWorker?.email}
        </ProposeWorkerDetailsEmail>
      </ProposeWorkerDetails>
    </ProposeWorkerDetailsContainer>

    <ProposeWorkerPaymentDetailsContainer>
      <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_employment_type">
        {proposal?.agencyWorker?.employmentType === "direct_engagement"
          ? "DE"
          : "NonDE"}
      </ProposeWorkerPaymentDetailsEmploymentLabel>

      <ProposeWorkerPaymentDetailsEmploymentLabel data-cy="label_payment_type">
        {proposal.agencyWorker.paymentType === "paye" && "Paye"}
        {proposal.agencyWorker.paymentType === "umbrella_company" && "Umbrella"}

        {proposal.agencyWorker.paymentType === "ltd" &&
          proposal.agencyWorker.isSoleTrader &&
          "Sole Trader"}
        {proposal.agencyWorker.paymentType === "ltd" &&
          !proposal.agencyWorker.isSoleTrader &&
          "Ltd"}
      </ProposeWorkerPaymentDetailsEmploymentLabel>
    </ProposeWorkerPaymentDetailsContainer>

    {canUserEdit && (
      <>
        <DefaultButton
          action={() => viewWorkerAction(proposal)}
          color="blue"
          style={{ maxWidth: 125, width: "100%" }}
          text="View"
        />

        <DefaultButton
          action={() => withdrawProposalAction(proposal)}
          color="red"
          style={{ maxWidth: 125, width: "100%" }}
          text="Revoke"
        />
      </>
    )}
  </ProposeWorkerContainer>
);

export default BlockProposalCard;

type BlockProposalCardProps = {
  setShowWorker: Function;
  canUserEdit: boolean;
  viewWorkerAction?: (proposal: any) => void;
  proposal: any;
  withdrawProposalAction?: (proposal: any) => void;
};
