import { createModel } from "@rematch/core";

import { LoggedInAgencyUserFragment } from "helpers/__generated__/fragments.generated";

import { RootModel } from "./models";

//User info
type UserType = LoggedInAgencyUserFragment;

export const user = createModel<RootModel>()({
  state: null as UserType,
  reducers: {
    deleteUser: () => null,
    storeUser: (state, payload: UserType) => payload,
  },
});
