import dayjs from "dayjs";
import styled from "styled-components";

import {
  CloseButton,
  GREY,
  ModalHeader,
} from "@patchworkhealth/web-components";

import StatusLabel from "components/StatusLabel";

import { AgencyShiftFragment } from "../__generated__/AgencyShiftsHelpers.generated";

type ShiftModalHeaderProps = {
  handleCloseAction: Function;
  shift?: AgencyShiftFragment;
};

const ShiftModalHeader = ({
  handleCloseAction,
  shift,
}: ShiftModalHeaderProps) => {
  return (
    <ModalHeader>
      <ShiftModalHeaderDetailsContainer>
        <ShiftModalHeaderTitle>Shift #{shift?.id}</ShiftModalHeaderTitle>
        <ShiftModalHeaderDivider />
        <div>
          <div>
            <strong>{dayjs(shift?.startTime).format("DD MMM YYYY")}</strong>
          </div>
          <div>
            {dayjs(shift?.startTime).format("HH:mm")} -{" "}
            {dayjs(shift?.endTime).format("HH:mm")}
          </div>
        </div>
        <ShiftModalHeaderDivider />
        <div>
          <div>
            <strong>{shift?.department?.name}</strong>
          </div>
          <div>{shift?.site?.name}</div>
        </div>
        <ShiftModalHeaderDivider />

        <span style={{ color: shift?.grade?.colour, fontWeight: 600 }}>
          {shift?.grade?.title.slice(0, 45)}
        </span>
      </ShiftModalHeaderDetailsContainer>
      <ShiftModalHeaderDetailsContainer>
        <StatusLabel status={shift?.status} style={{ marginRight: 20 }} />

        <CloseButton data-cy="button_close_modal" onClick={handleCloseAction} />
      </ShiftModalHeaderDetailsContainer>
    </ModalHeader>
  );
};

export default ShiftModalHeader;

export const ShiftModalHeaderDetailsContainer = styled.div`
  align-items: center;
  display: flex;

  p {
    font-weight: 600;
    margin: 0;
  }
`;

export const ShiftModalHeaderTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const ShiftModalHeaderDivider = styled.div`
  background-color: ${GREY.three};
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  width: 1px;
`;
