import { useEffect } from "react";
import styled from "styled-components";

import Header from "components/Header";
import Navigation from "components/Navigation";

const Layout = ({ children }: Props) => {
  useEffect(() => {
    document.title = "Patchwork Agency Portal";
  }, []);

  return (
    <>
      <Header />
      <LayoutContainer>
        <Navigation />
        <MainContainer data-testid="test__component">{children}</MainContainer>
      </LayoutContainer>
    </>
  );
};

export default Layout;

type Props = {
  children: JSX.Element;
};

const LayoutContainer = styled.div`
  height: calc(100vh - 48px);
  margin-top: 48px;
  overflow-y: auto;
`;

const MainContainer = styled.main`
  margin: 0;
  margin-left: 180px;
  padding: 24px;
  position: relative;
  /* width: calc(100vw - 180px); */
`;
