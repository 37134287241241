import { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

import {
  DefaultButton,
  FormBreak,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  Loading,
} from "@patchworkhealth/web-components";

import { CrossIcon } from "assets/icons/red-cross-icon";
import { ShiftModalTitle } from "components/AgencyShifts/ShiftModalComponents/ShiftModalComponents";
import { useVmsAgencyWorkerProposalRatesQuery } from "components/AgencyShifts/ShiftModalComponents/ShiftModalProposeWorkers/__generated__/ShiftModalProposeWorkersHelpers.generated";
import { useGetAgencyWorkersQuery } from "components/AgencyWorkers/__generated__/AgencyWorkersHelpers.generated";
import { ModalCustomFooter } from "components/styles";
import { createFormFunctions } from "helpers/functions";

import {
  ShiftBlockQuery,
  useVmsShiftBlockProposalCreateMutation,
  useVmsShiftBlockProposalRevokeMutation,
} from "../__generated__/BlocksHelpers.generated";
import BlockProposalCard from "./BlockProposalCard";
import BlockRates from "./BlockRates";
import BlockWorkerCard from "./BlockWorkerCard";

const BlockProposeWorker = ({
  block,
  setShowWorker,
  refetch,
}: BlockProposeWorkerProps) => {
  const [inputs, setInputs] = useState(emptyInputs);
  const { handleFilter } = createFormFunctions(setInputs);

  // GQL ****************************************************

  const [createProposalMutation] = useVmsShiftBlockProposalCreateMutation();
  const [withdrawProposalMutation] = useVmsShiftBlockProposalRevokeMutation();

  const { data: vmsAgencyRegistrations, loading } = useGetAgencyWorkersQuery({
    skip: !block,
    fetchPolicy: "cache-and-network",
    variables: {
      staffGroupId: Number(block?.staffGroup?.idValue),
      searchTerm: inputs.workerName,
      hideSoleTraders: !block.organisation.usesDeSoleTrader,
      hideLtdWorkers: block.organisation.hideDeLtdAgencyWorkers,
    },
  });

  // QUERY FOR RATES ***************************************

  const { data: ratesData, loading: loadingRates } =
    useVmsAgencyWorkerProposalRatesQuery({
      skip: inputs.skip,
      variables: {
        shiftBlockId: Number(block.id),
        agencyRegistrationId:
          Number(inputs.viewWorker?.id) ||
          Number(inputs.viewProposal?.agencyWorker?.id),
      },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",

      onCompleted: () => {
        let rates = ratesData.vmsAgencyWorkerProposalRates.map((cc) => ({
          ...cc,
          agencyMarginDiff: 0,
          workerPayeRateDiff: 0,
          ltdUmbrellaRateDiff: 0,
          comparatorRateDiff: 0,
          workerBaseRateDiff: 0,
        }));

        setInputs({ ...inputs, skip: true, rates, initialRates: rates });
      },
    });

  // QUERY FOR REVISED RATES  ***************************************

  const { data: getAgencyProposalRatesTwo } =
    useVmsAgencyWorkerProposalRatesQuery({
      skip: inputs.skip2,
      variables: {
        shiftBlockId: Number(block.id),
        agencyRegistrationId: Number(inputs.viewWorker?.id),
        revisedRates: inputs.rates.map((rate) => ({
          id: rate.id,
          agencyMargin: Number(rate.agencyMargin),
          comparatorRate:
            inputs.viewWorker?.employmentType !== "direct_engagement" ||
            (inputs.viewWorker?.employmentType === "direct_engagement" &&
              inputs.viewWorker?.paymentType === "ltd")
              ? Number(rate.comparatorRate)
              : null,
          workerRate: Number(
            inputs.viewWorker?.paymentType === "paye"
              ? rate.workerPayeRate
              : rate.ltdUmbrellaRate
          ),
        })),
      },
      fetchPolicy: "no-cache",
      onCompleted: async () => {
        let rates = getAgencyProposalRatesTwo.vmsAgencyWorkerProposalRates.map(
          (cc) => ({
            ...cc,
            agencyMarginDiff: +(
              cc.agencyMargin - cc.initialRates.agencyMargin
            ).toFixed(2),
            workerPayeRateDiff: +(
              cc.workerPayeRate - cc.initialRates.workerPayeRate
            ).toFixed(2),
            ltdUmbrellaRateDiff: +(
              cc.ltdUmbrellaRate - cc.initialRates.ltdUmbrellaRate
            ).toFixed(2),
            comparatorRateDiff: +(
              cc.comparatorRate - cc.initialRates.comparatorRate
            ).toFixed(2),
            workerBaseRateDiff: +(
              cc.workerBaseRate - cc.initialRates.workerBaseRate
            ).toFixed(2),
          })
        );

        setInputs({ ...inputs, skip: true, skip2: true, rates });
      },
    });

  // FUNCTIONS *****************************

  const viewWorkerAction = (worker) => {
    if (worker.agencyWorker) {
      let updatedRates = worker.rates.map((cc) => ({
        ...cc,
        agencyMarginDiff: +(
          cc.agencyMargin - cc.initialRates.agencyMargin
        ).toFixed(2),
        workerPayeRateDiff: +(
          cc.workerPayeRate - cc.initialRates.workerPayeRate
        ).toFixed(2),
        ltdUmbrellaRateDiff: +(
          cc.ltdUmbrellaRate - cc.initialRates.ltdUmbrellaRate
        ).toFixed(2),
        comparatorRateDiff: +(
          cc.comparatorRate - cc.initialRates.comparatorRate
        ).toFixed(2),
        workerBaseRateDiff: +(
          cc.workerBaseRate - cc.initialRates.workerBaseRate
        ).toFixed(2),
      }));

      setInputs({
        ...inputs,
        rates: updatedRates,
        viewProposal: { ...worker, readOnly: true },
        page: 2,
      });
      return;
    }

    setInputs({ ...inputs, skip: false, viewWorker: worker, page: 2 });
  };

  const proposalWorkerAction = async () => {
    const hasErrors = inputs.rates.some((rate) => rate.errors.length > 0);

    if (hasErrors) {
      toast.error("You still have errors in the rate cards.");
      return;
    }

    const toastId = toast.loading("Creating proposal...");
    try {
      const { data: result } = await createProposalMutation({
        variables: {
          agencyWorkerProposalAttributes: {
            agencyWorkerId: Number(inputs.viewWorker.id),
            shiftBlockId: Number(block.id),
            shiftBlockPayElements: inputs.rates.map((cc) => ({
              workerBaseRate: cc.workerBaseRate,
              workerRate:
                inputs.viewWorker?.paymentType === "paye"
                  ? cc.workerPayeRate
                  : cc.ltdUmbrellaRate,
              comparatorRate: cc.comparatorRate,
              agencyMargin: cc.agencyMargin,
              id: cc.id,
            })),
          },
        },
      });

      if (result?.vmsShiftBlockProposalCreate?.errors?.length) {
        toast.error(result.vmsShiftBlockProposalCreate.errors[0].message, {
          id: toastId,
        });
        return;
      }
      toast.success("Proposal Created", { id: toastId });
      refetch();

      setInputs(emptyInputs);
    } catch (err) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  const withdrawProposalAction = async (worker) => {
    const toastId = toast.loading("Withdrawing proposal...");
    try {
      const { data: result } = await withdrawProposalMutation({
        variables: {
          agencyWorkerBlockProposalId: Number(worker.id),
        },
      });

      if (result?.vmsShiftBlockProposalRevoke?.errors?.length) {
        toast.error("An error occurred.", { id: toastId });
        return;
      }
      toast.success("Proposal Withdrawn", { id: toastId });
      refetch();
      setInputs(emptyInputs);
    } catch (err) {
      toast.error("An error occurred.", { id: toastId });
    }
  };

  // PROPOSALS ************************************

  const proposals = block.agencyWorkerBlockProposals.filter(
    (proposal) => proposal.status === "proposed"
  );

  const filteredAgencyWorkers =
    vmsAgencyRegistrations?.vmsAgencyRegistrations?.edges.filter(
      ({ node: registration }) => {
        return !proposals?.find((proposal) => {
          return registration.id === proposal.agencyWorker.id;
        });
      }
    );

  let canUserSubmit =
    inputs.rateTab === inputs.rates.length && inputs.rates.length > 0
      ? true
      : false;

  const canBeProposed = block.agencyRateCardAttached;
  return (
    <>
      {inputs.page === 1 && (
        <FormGridContainer>
          <ShiftModalTitle>Propose Agency Worker for Block</ShiftModalTitle>

          {proposals.map((proposal) => (
            <BlockProposalCard
              key={proposal.id}
              canUserEdit={true}
              proposal={proposal}
              setShowWorker={setShowWorker}
              viewWorkerAction={viewWorkerAction}
              withdrawProposalAction={withdrawProposalAction}
            />
          ))}
          <FormBreak />
          {canBeProposed && (
            <>
              <FormGrid columns={2} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <FormLabel>
                    <strong>
                      To propose a worker, please search or select them below:
                    </strong>
                  </FormLabel>

                  <FormInput
                    defaultValue={inputs.workerName}
                    onChange={(e) => handleFilter(e.target.value, "workerName")}
                    placeholder="Type a name..."
                    type="text"
                  />
                </FormGroup>
              </FormGrid>

              {loading && <Loading />}

              {!loading &&
                filteredAgencyWorkers.map(({ node: worker }) => (
                  <BlockWorkerCard
                    key={worker.id}
                    canUserEdit={true}
                    setShowWorker={setShowWorker}
                    viewWorkerAction={viewWorkerAction}
                    worker={worker}
                  />
                ))}
            </>
          )}
          {!canBeProposed && (
            <div>
              <NoRateCardContainer>
                <CrossIcon />
                <NoRateCardTextContainer>
                  <span
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    No rate card assigned
                  </span>
                  This block cannot accept proposals as there is currently no
                  rate card assigned to the {block.grade.label} grade.
                  <br />
                  <br />
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Please contact an admin at {block.organisation.name} to
                    resolve the issue.
                  </span>
                </NoRateCardTextContainer>
              </NoRateCardContainer>
            </div>
          )}
        </FormGridContainer>
      )}

      {/* PAGE 2 ******************************************************************* */}

      {inputs.page === 2 && (
        <FormGridContainer>
          <ShiftModalTitle>Propose Agency Worker for Block</ShiftModalTitle>

          {inputs.viewProposal && (
            <BlockProposalCard
              canUserEdit={false}
              proposal={inputs.viewProposal}
              setShowWorker={setShowWorker}
              withdrawProposalAction={withdrawProposalAction}
            />
          )}

          {inputs.viewWorker && (
            <BlockWorkerCard
              canUserEdit={false}
              setShowWorker={setShowWorker}
              viewWorkerAction={viewWorkerAction}
              worker={inputs.viewWorker}
            />
          )}

          {loadingRates && <Loading />}

          {!loadingRates && (
            <BlockRates handleFilter={handleFilter} inputs={inputs} />
          )}

          {/* FOOTER ******************************************************************* */}

          <ModalCustomFooter>
            {inputs.viewWorker && (
              <DefaultButton
                action={() =>
                  setInputs({ ...inputs, rates: inputs.initialRates })
                }
                text="Reset Rate Card Values"
                type="grey"
              />
            )}

            {!inputs.viewWorker && <div />}

            <div>
              <DefaultButton
                action={() => setInputs(emptyInputs)}
                text="Back"
                type="white"
              />

              {inputs.viewWorker && inputs.rates.length > 0 && (
                <DefaultButton
                  action={() => proposalWorkerAction()}
                  color="blue"
                  data-testid="confirm-propose-worker"
                  disabled={!canUserSubmit}
                  style={{ opacity: canUserSubmit ? 1 : 0.2 }}
                  text="Confirm Propose Worker"
                />
              )}
            </div>
          </ModalCustomFooter>
        </FormGridContainer>
      )}
    </>
  );
};

export default BlockProposeWorker;

type BlockProposeWorkerProps = {
  block: ShiftBlockQuery["shiftBlocks"]["nodes"][number];
  setShowWorker: Function;
  refetch: Function;
};

const emptyInputs = {
  page: 1,
  workerName: "",
  rates: [],
  initialRates: [],
  skip: true,
  skip2: true,
  rateTab: 1,
  showPopup: false,
  showMath: false,
  viewWorker: null,
  viewProposal: null,
  totalComparitor: 0,
  totalMargin: 0,
};

const NoRateCardContainer = styled.div`
  align-content: start;
  background-color: #f5dde2;
  border: 1px solid #e6515d;
  border-radius: 6px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  height: 100%;
  line-height: 20px;
  padding: 12px 24px 12px 24px;
`;

const NoRateCardTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  fontsize: 14px;
  fontweight: 400;
  lineheight: 20px;
  width: 100%;
`;
